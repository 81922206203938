import {formDataCreator} from 'helpers';
import commonFormData from './formData';

export default {
  ...formDataCreator(commonFormData, {
    contactInfo: {
      rootCollection: [
        {
          id: 'number_vehicles',
          name: 'number-vehicles',
          position: 8,
          value: '',
          validationType: 'number',
          validationPreset: 'vehiclesNumber',
          mask: {
            hasNumberPositiveInteger: true,
          },
        },
      ],
    },
  }),
  ...{
    priceSimulatorFuel: [
      [
        {
          id: 'number_vehicles',
          name: 'number-vehicles',
          value: '',
          mask: {
            hasNumberPositiveInteger: true,
          },
          withAutoSelect: true,
        },
        {
          id: 'average_fuel_consumption',
          name: 'avg-consumption',
          value: '',
          mask: {
            hasCurrency: true,
            fieldCurrencyMaskSettings: {
              decimalScale: 0,
              decimalSeparator: false,
              fixedDecimalScale: false,
            },
          },
          withAutoSelect: true,
          withTooltip: true,
        },
        {
          id: 'multiplication',
          name: 'total-consumption',
          value: '',
          mask: {
            hasCurrency: true,
            fieldCurrencyMaskSettings: {
              decimalScale: 0,
              decimalSeparator: false,
              fixedDecimalScale: false,
            },
          },
          withAutoSelect: true,
        },
      ],
      [
        {
          id: 'multiplication',
          name: 'total-consumption',
          disabled: true,
          value: '',
          mask: {
            hasCurrency: true,
            fieldCurrencyMaskSettings: {
              decimalScale: 0,
              decimalSeparator: false,
              fixedDecimalScale: false,
            },
          },
        },
        {
          id: 'commission',
          name: 'commission',
          disabled: true,
          value: '',
          mask: {
            hasPercentage: true,
          },
        },
        {
          id: 'total_savings',
          name: 'total-savings',
          disabled: true,
          value: '',
          mask: {
            hasCurrency: true,
            fieldCurrencyMaskSettings: {
              decimalScale: 0,
              decimalSeparator: false,
              fixedDecimalScale: false,
            },
          },
        },
      ],
    ],
  },
};
