import React from 'react';
// Mui components
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

export default ({children}) => (
  <Box my={1}>
    <Paper elevation={0}>
      <Box p={2}>{children}</Box>
    </Paper>
  </Box>
);
