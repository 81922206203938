import React from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconRetry from '@material-ui/icons/Replay';
// React Intl
import {FormattedMessage} from 'react-intl';

// Styles
const useStyles = makeStyles(theme => {
  const colorRed = theme.palette.red;

  return {
    button: {
      color: colorRed,
      borderColor: colorRed,
    },
  };
});

export default ({handleRetryPayment, translationIdentifierPrefix}) => (
  <Button
    variant='outlined'
    startIcon={<IconRetry />}
    className={useStyles().button}
    onClick={handleRetryPayment}
  >
    <FormattedMessage
      id={`${translationIdentifierPrefix}_cross-sell_button_retry-payment`}
    />
  </Button>
);
