// Application scenarios
import TenantSwitcher from './TenantSwitcher';
import BannerRedirect from './BannerRedirect';
import TurkeyProducts from './TurkeyProducts';
import TicketRestaurant from './TicketRestaurant';

export default {
  TenantSwitcher,
  BannerRedirect,
  TurkeyProducts,
  TicketRestaurant,
};
