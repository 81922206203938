import React, {useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';

const StaticPage = props => {
  const {
    statusTitleId,
    children,
    hideNavigation,
    hideSteps,
    resetStore,
    shouldResetStore,
  } = props;

  useEffect(() => {
    hideNavigation();
    hideSteps();

    if (shouldResetStore) {
      resetStore();
    }
  });

  return (
    <Box width={1}>
      <Box justifyContent='center'>
        <Typography variant='h1' align='center'>
          <FormattedMessage id={statusTitleId} />
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {flowActions, rootActions} = ownProps;
  return {
    hideNavigation: () => dispatch(flowActions.flow.setNavigationVisibility(false)),
    hideSteps: () => dispatch(flowActions.flow.setNavigationStepsVisibility(false)),
    resetStore: () => dispatch(rootActions.root.resetStore()),
  };
};

export default connect(null, mapDispatchToProps)(StaticPage);
