import {
  SET_PRODUCT_CODE,
  SET_PRODUCT_TYPE,
  SET_VENDOR_NAME,
  SET_VENDOR_LOGO_PATH,
} from '../actions/featureToggle';
import initialStates from './initialStates';

const initialState = initialStates.featureToggle;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_CODE:
      return {
        ...state,
        productCode: action.value,
      };

    case SET_PRODUCT_TYPE:
      return {
        ...state,
        productType: action.value,
      };

    case SET_VENDOR_NAME:
      return {
        ...state,
        vendorName: action.value,
      };

    case SET_VENDOR_LOGO_PATH:
      return {
        ...state,
        vendorLogoPath: action.value,
      };

    default:
      return state;
  }
};
