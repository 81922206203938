import rootSelectors from 'store/selectors';
import common from './common';
import bigClient from './bigClient';
import contact from './contact';
import company from './company';
import priceSimulatorBenefit, {selectInputsRanges} from './priceSimulatorBenefit';
import order from './order';
import employeeList from './employeeList';
import orderFuel from './orderFuel';

// Selectors (getters) shortcut
export default {
  ...rootSelectors,
  ...common,
  ...bigClient,
  ...contact,
  ...company,
  ...priceSimulatorBenefit,
  selectInputsRanges: rangeType => state => selectInputsRanges(rangeType)(state),
  ...order,
  ...employeeList,
  ...orderFuel,
};
