import React, {Component} from 'react';
import {compose} from 'redux';
import {withRouter} from 'react-router-dom';
// Components - local
import StoreProvider from 'components/StoreProvider';
import {capitalizeString} from 'utils';
import {routePathSlice} from 'helpers';
import flows from './flows';
import visitorFlows from './flows/Visitor';

class TurkeyProducts extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    // Prevent rerender and StoreProvider rehydrate if route is changed
    return nextState !== null;
  }

  render() {
    const {props} = this;
    const {persistenceConfig, productPaths} = props;

    // Only Visitor flow for this scenario (TurkeyProducts)
    const Visitor = flows[props.visitorFlowName];
    // const Auth = flows[props.authFlowName];

    const visitorProductPath = routePathSlice(props.location.pathname, 0);
    const productIndex = Object.values(productPaths).findIndex(
      e => e === visitorProductPath,
    );
    const visitorFlowName = capitalizeString(Object.keys(productPaths)[productIndex]);

    const visitorFlow = visitorFlows[visitorFlowName];
    const visitorFlowReducers = visitorFlow.store.reducers;
    const visitorFlowMiddleware = visitorFlow.store.middleware;

    return (
      <StoreProvider
        reducers={visitorFlowReducers}
        middleware={visitorFlowMiddleware}
        persistenceConfig={persistenceConfig}
      >
        <Visitor
          {...props}
          visitorProductPath={visitorProductPath}
          visitorFlowName={visitorFlowName}
        />
      </StoreProvider>
    );
  }
}

export default compose(withRouter)(TurkeyProducts);
