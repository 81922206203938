export default {
  'contact-first-name': {
    MAX: 35,
  },
  'contact-last-name': {
    MAX: 35,
  },
  'company-name': {
    MAX: 250,
  },
  'contact-company-name': {
    MAX: 250,
  },
  'number-vehicles': {
    MAX: 9999,
  },
  'number-employees': {
    MAX: 9999,
  },
  'tax-office': {
    MAX: 40,
  },
  'fiscal-or-vat-number': {
    MIN: 10,
    MAX: 11,
  },
  address: {
    MIN: 5,
    MAX: 150,
  },
  'contact-message': {
    MIN: 5,
    MAX: 500,
  },
  'total-willing-to-pay': {
    // Used for translation MIN/MAX replacements
    MIN: 'settings/fuelCostMin',
    MAX: 'settings/fuelCostMax',
  },
  'order-picker-first-name': {
    MAX: 25,
  },
  'order-picker-last-name': {
    MAX: 25,
  },
  'employee-first-name': {
    MAX: 25,
  },
  'employee-last-name': {
    MAX: 25,
  },
  'employee-id': {
    MAX: 25,
  },
  days: {
    // Used for translation MIN/MAX replacements
    MIN: 'settings/rangeNumberWorkingDays/min',
    MAX: 'settings/rangeNumberWorkingDays/max',
  },
  'daily-value': {
    // Used for translation MIN/MAX replacements
    MIN: 'settings/rangeAllowanceDays/min',
    MAX: 'settings/rangeAllowanceDays/max',
  },
  'monthly-value': {
    // Used for translation MIN/MAX replacements
    MIN: 'settings/rangeAllowanceMonths/min',
    MAX: 'settings/rangeAllowanceMonths/max',
  },
};
