import React from 'react';
// React Intl
import {injectIntl} from 'react-intl';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
// Components - local
import commonToasterProps from './components/commonToasterProps';

const useStyles = makeStyles(theme => ({
  snackbar: {
    zIndex: theme.zIndex.snackbar,
  },
  alert: {
    ...theme.elevatedElement,
  },
  alertTitle: {
    marginBottom: 0,
  },
}));

export default injectIntl(props => {
  const classes = useStyles();
  const {
    intl,
    status,
    onClose,
    onCloseAlert,
    neverHide = false,
    translationIdentifierPrefix,
    translationKey,
    hasSecondaryMessage,
    messageText,
    messageSecondaryText,
    severity = 'error',
    variant = 'filled',
  } = props;

  // Intl
  const intlMessage = msg =>
    intl.formatMessage({
      id: `${translationIdentifierPrefix}_${translationKey}_${msg}`,
    });

  const message = messageText || intlMessage('message');
  const messageSecondary =
    messageSecondaryText || (hasSecondaryMessage && intlMessage('message-secondary'));

  return (
    <Snackbar
      {...commonToasterProps}
      {...(neverHide && {autoHideDuration: null})}
      open={status}
      onClose={onClose}
      className={classes.snackbar}
    >
      <Alert
        className={classes.alert}
        onClose={onCloseAlert}
        severity={severity}
        variant={variant}
      >
        <AlertTitle className={classes.alertTitle}>{message}</AlertTitle>
        {messageSecondary}
      </Alert>
    </Snackbar>
  );
});
