export default settings => {
  const {fuelCostMin, fuelCostMax} = settings;

  const rangesObject = {
    fuelCostMin,
    fuelCostMax,
  };

  const ranges = [
    'rangeNumberWorkingDays',
    'rangeAllowanceDays',
    'rangeAllowanceMonths',
    'rangeBeneficiaries',
  ];

  ranges.forEach(range => {
    const settingsRange = settings[range];

    rangesObject[`${range}Min`] = settingsRange?.min;
    rangesObject[`${range}Max`] = settingsRange?.max;
  });

  return rangesObject;
};
