import merge from 'deepmerge';
import _ from 'lodash';

// Object example to overwrite field position
/*
  const customFieldOrder = {
    contactInfo: {
      rootCollection: [{'contact-first-name': 10}, {'number-employees': 11}],
    },
    companyInfo: {
      rootCollection: [
        {'company-name': 6},
        {'fiscal-or-vat-number': 5},
        {'tax-office': 4},
        {'mobile-phone': 3},
        {'contact-phone': 2},
        {'contact-email': 1},
      ],
    },
  };
*/

export default (commonFormData, additionalFormData, customFieldOrder = {}) => {
  const formData = merge(commonFormData, additionalFormData);
  // Scenes
  const scenes = Object.keys(formData);

  // Scene iteration
  scenes.forEach(scene => {
    const sceneFormData = formData[scene];
    const sceneCollections = Object.keys(sceneFormData);

    // Collection iteration
    sceneCollections.forEach(collection => {
      const sceneCollection = sceneFormData[collection];

      // Field iteration
      sceneCollection.forEach(field => {
        const {name, position} = field;
        const sceneCollectionCustomFieldOrder = customFieldOrder?.scene?.collection;

        // Custom field position
        const customPositionField =
          sceneCollectionCustomFieldOrder &&
          sceneCollectionCustomFieldOrder.find(pos => pos[name]);
        const customPosition = customPositionField && customPositionField[name];

        // Overwrite field position only for custom positions
        field.position = customPositionField ? customPosition : position;
      });

      // Sort collection fields by position key
      const sortKey = 'position';
      const sortedCollection = _.sortBy(sceneCollection, [sortKey]);
      // Omit (delete) position unuseful key after sort
      sceneFormData[collection] = sortedCollection.map(el => _.omit(el, [sortKey]));
    });
  });

  return formData;
};
