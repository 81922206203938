import api from 'api';

export const SET_HEADERS_TENANT = '[ROOT/API] SET_HEADERS_TENANT';
export const SET_HEADERS_SESSION = '[ROOT/API] SET_HEADERS_SESSION';
export const SET_HEADERS_LANGUAGE = '[ROOT/API] SET_HEADERS_LANGUAGE';
export const SET_GW_HOSTS = '[ROOT/API] SET_GW_HOSTS';
export const SET_GW_HEADERS_CLIENT_ID = '[ROOT/API] SET_GW_HEADERS_CLIENT_ID';
export const SET_GW_HEADERS_SECRET_ID = '[ROOT/API] SET_GW_HEADERS_SECRET_ID';

const makeRequest = (requestCollection, requestParameters = []) => {
  return () => {
    const rqC = [...requestCollection.split('/')];
    const apiRequest = rqC.length > 1 ? api[rqC[0]][rqC[1]] : api[rqC];
    const apiParameters =
      typeof requestParameters === 'string' ? [requestParameters] : requestParameters;

    return apiRequest(...apiParameters);
  };
};

const setHeadersTenant = value => ({
  type: SET_HEADERS_TENANT,
  value,
});

const setHeadersSession = value => ({
  type: SET_HEADERS_SESSION,
  value,
});

const setHeadersLanguage = value => ({
  type: SET_HEADERS_LANGUAGE,
  value,
});

const setGwHost = value => ({
  type: SET_GW_HOSTS,
  value,
});

const setGwHeadersSecretId = value => ({
  type: SET_GW_HEADERS_CLIENT_ID,
  value,
});

const setGwHeadersClientId = value => ({
  type: SET_GW_HEADERS_SECRET_ID,
  value,
});

export default {
  makeRequest,
  setHeadersTenant,
  setHeadersSession,
  setHeadersLanguage,
  setGwHost,
  setGwHeadersSecretId,
  setGwHeadersClientId,
};
