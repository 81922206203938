import {
  SET_COMPANY_UUID,
  SET_COMPANY_ID,
  SET_FORM_IS_DIRTY,
  SET_E_INVOICE_PAY_COLLAPSED,
  SET_DELIVERY_ADDRESS_COLLAPSED,
  SET_ORDER_PICKER_COLLAPSED,
  CLEAR_ORDER_PICKER,
  UPDATE_SERVER_FORM_DATA,
} from '../actions/company';
import initialStates from './initialStates';

const initialState = initialStates.company;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_UUID:
      return {
        ...state,
        companyUuid: action.value,
      };

    case SET_COMPANY_ID:
      return {
        ...state,
        companyId: action.value,
      };

    case SET_FORM_IS_DIRTY:
      return {
        ...state,
        formIsDirty: action.value,
      };

    case SET_E_INVOICE_PAY_COLLAPSED:
      return {
        ...state,
        eInvoicePayCollapsed: action.value ? action.value : !state.eInvoicePayCollapsed,
      };

    case SET_DELIVERY_ADDRESS_COLLAPSED:
      return {
        ...state,
        deliveryAddressCollapsed: action.value
          ? action.value
          : !state.deliveryAddressCollapsed,
      };

    case SET_ORDER_PICKER_COLLAPSED:
      return {
        ...state,
        orderPickerCollapsed: action.value ? action.value : !state.orderPickerCollapsed,
      };

    case CLEAR_ORDER_PICKER:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          order_picker: initialState.order_picker,
        },
      };

    case UPDATE_SERVER_FORM_DATA:
      return {
        ...state,
        serverFormData: action.payload,
      };

    default:
      return state;
  }
};
