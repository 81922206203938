import React from 'react';

// React Intl
import {injectIntl} from 'react-intl';

// Mui components
import {ErrorMessage, Field} from 'formik';
import {makeStyles} from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

// Components - global
import LabelFormCheckbox from 'ComponentsForm/LabelFormCheckbox';

// Styles
const useStyles = makeStyles({
  root: {
    marginTop: '1rem',
  },
  error: {
    marginLeft: '2rem',
  },
});

export default injectIntl(props => {
  const {name, label, checked, intl} = props;
  const classes = useStyles();
  const translationIdentifierPrefix = 'error.FE_checkbox-';
  const intlMessage = msg => intl.formatMessage({id: translationIdentifierPrefix + msg});

  const ErrorValidation = () => (
    <>
      <ErrorMessage name={name}>
        {msg => (
          <FormHelperText className={classes.error} name={name} error>
            {intlMessage(msg)}
          </FormHelperText>
        )}
      </ErrorMessage>
    </>
  );

  return (
    <>
      <Field as='div' name={name} className={classes.root}>
        <LabelFormCheckbox
          label={label}
          checked={checked}
          name={name}
          value={false}
          alignToTop
        />
      </Field>

      <ErrorValidation />
    </>
  );
});
