import React from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import IconSuccess from '@material-ui/icons/CheckCircle';
import IconFailed from '@material-ui/icons/Cancel';

// Icons map
const Icons = {
  success: IconSuccess,
  failed: IconFailed,
};

// Styles
const useStyles = theme => {
  const colorRed = theme.palette.red;
  const colorGreen = theme.palette.green;

  return {
    icon: {
      fontSize: '5rem',
      '&.success': {
        color: colorGreen,
      },
      '&.failed': {
        color: colorRed,
      },
    },
  };
};

export default ({type}) => {
  const Icon = Icons[type];
  const classes = makeStyles(useStyles)();

  return <Icon className={`${classes.icon} ${type}`} />;
};
