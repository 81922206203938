import React from 'react';
import PropTypes from 'prop-types';

// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/icons/HelpOutline';

// We can inject some CSS into the DOM.
const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    minWidth: '10rem!important',
    marginRight: '-2rem',
    [theme.breakpoints.down('sm')]: {
      marginRight: '-6.5rem',
    },
    ...theme.elevatedElement,
    zIndex: theme.zIndex.helpButton,
  },
  label: {
    justifyContent: 'flex-start',
  },
}));

const HelpButton = ({handleClick}) => {
  const classes = useStyles();

  return (
    <>
      <Fab
        onClick={handleClick}
        className='vCenter'
        classes={{
          root: classes.root,
          label: classes.label,
        }}
        variant='extended'
        size='medium'
        color='secondary'
      >
        <Icon />
      </Fab>
    </>
  );
};

HelpButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default HelpButton;
