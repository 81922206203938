import React from 'react';
import {connect} from 'react-redux';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// Components - global
import Image from 'components/Image';

const useStyles = makeStyles(theme => ({
  subtitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  image: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
    width: '100%',
    maxWidth: 200,
  },
}));

const mapStateToProps = state => ({
  tenant: state.root.settings.tenant,
});

export default connect(mapStateToProps)(props => {
  const {
    onlySubtitle,
    withSubtitle,
    withImage,
    imagePath,
    translationIdentifierPrefix,
    translationValues,
    translationKeySubtitle,
    subtitleVariant = 'h4',
    tenant,
  } = props;
  const classes = useStyles();

  const Title = () => (
    <Typography variant='h1'>
      <FormattedMessage
        id={`${translationIdentifierPrefix}_title`}
        values={translationValues}
      />
    </Typography>
  );

  const Subtitle = () =>
    withSubtitle ? (
      <Typography variant={subtitleVariant}>
        <FormattedHTMLMessage
          id={`${translationIdentifierPrefix}_subtitle${
            translationKeySubtitle ? `_${translationKeySubtitle}` : ''
          }`}
          values={translationValues}
        />
      </Typography>
    ) : null;

  return onlySubtitle ? (
    <Box mb={1}>
      <Subtitle />
    </Box>
  ) : (
    <Box mb={2} width='100%'>
      <Title />

      {withImage ? (
        <Box mt={1} className={classes.subtitleWrapper}>
          <Image
            additionalClasses={classes.image}
            image={`${process.env.PUBLIC_URL}/images/${tenant}/${imagePath}`}
          />
          <Subtitle />
        </Box>
      ) : (
        <Box mt={1}>
          <Subtitle />
        </Box>
      )}
    </Box>
  );
});
