import {formDataCreator} from 'helpers';
import commonFormData from './formData';

export default {
  ...formDataCreator(commonFormData, {
    contactInfo: {
      rootCollection: [
        {
          id: 'number_employees',
          name: 'number-employees',
          position: 8,
          value: '',
          validationType: 'number',
          validationPreset: 'employeesNumber',
          mask: {
            hasNumberPositiveInteger: true,
          },
        },
        {
          id: 'employee_gdpr',
          name: 'marketing-communications',
          position: 11,
          value: 'false',
          validationType: 'bool',
          validationPreset: 'checkbox',
        },
      ],
    },
    companyInfo: {
      orderPickerCollection: [
        {
          id: 'first_name',
          name: 'order-picker-first-name',
          position: 1,
          value: '',
          validationPreset: 'personName',
        },
        {
          id: 'last_name',
          name: 'order-picker-last-name',
          position: 2,
          value: '',
          validationPreset: 'personName',
        },
        {
          id: 'mobile_phone',
          name: 'order-picker-mobile-phone',
          position: 3,
          value: '',
          validationPreset: 'phoneNumbersGroup',
          validationOptions: {
            noSortEdges: true,
            currentValidationPreset: 'phoneNumberMobile',
            otherField: 'order-picker-landline-phone',
          },
          mask: {
            hasNumberPositiveInteger: true,
          },
        },
        {
          id: 'phone',
          name: 'order-picker-landline-phone',
          position: 4,
          value: '',
          validationPreset: 'phoneNumbersGroup',
          validationOptions: {
            noSortEdges: true,
            currentValidationPreset: 'phoneNumberFlat',
            otherField: 'order-picker-mobile-phone',
          },
          mask: {
            hasNumberPositiveInteger: true,
          },
        },
        {
          id: 'email',
          name: 'order-picker-email',
          position: 5,
          value: '',
          validationPreset: 'email',
        },
      ],
    },
  }),
  ...{
    priceSimulatorBenefit: [
      {
        id: 'calendar_unit',
        name: 'calendar-unit',
        value: '',
        mask: {
          hasNumberPositiveInteger: true,
          maskErrorHighlightForRange: true,
          maskRange: {
            max: 'rangeNumberWorkingDaysMax',
            min: 'rangeNumberWorkingDaysMin',
          },
        },
        withTooltip: true,
        withAutoSelect: true,
      },
      {
        id: 'allowance',
        name: 'allowance',
        value: '',
        mask: {
          hasCurrency: true,
          maskErrorHighlightForRange: true,
          maskRange: {
            monthly: {
              min: 'rangeAllowanceMonthsMin',
              max: 'rangeAllowanceMonthsMax',
            },
            daily: {
              min: 'rangeAllowanceDaysMin',
              max: 'rangeAllowanceDaysMax',
            },
          },
        },
        withTooltip: true,
        withAutoSelect: true,
      },
      {
        id: 'employee_number',
        name: 'employee-number',
        value: '',
        mask: {
          hasNumberPositiveInteger: true,
          maskErrorHighlightForRange: true,
          maskRange: {
            max: 'rangeBeneficiariesMax',
            min: 'rangeBeneficiariesMin',
          },
        },
        withTooltip: true,
        withAutoSelect: true,
      },
    ],
  },
};
