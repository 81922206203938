import React from 'react';
import {NavLink} from 'react-router-dom';
import logoImage from 'assets/generic/logo.png';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
// Components - global
import Logo from 'components/Logo';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
  },
  box: {
    padding: theme.spacing(2),
    display: 'flex',
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Toolbar className={classes.root}>
      <Box className={classes.box}>
        <NavLink to='/'>
          <Logo image={logoImage} width={100} />
        </NavLink>
      </Box>
      <Typography variant='h2' mb={1}>
        Online Sales
      </Typography>
    </Toolbar>
  );
};
