import {
  SET_IS_DIRTY,
  SET_NUMBER_VEHICLES,
  SET_AVERAGE_FUEL_CONSUMPTION,
  SET_MULTIPLICATION,
  SET_TOTAL_SAVINGS,
  CALCULATE_MULTIPLICATION,
  CALCULATE_COMMISSION,
  CALCULATE_AVERAGE_FUEL_CONSUMPTION,
} from '../actions/priceSimulatorFuel';
import initialStates from './initialStates';

const initialState = initialStates.priceSimulatorFuel;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_DIRTY:
      return {
        ...state,
        isDirty: action.value,
      };

    case SET_NUMBER_VEHICLES:
      return {
        ...state,
        number_vehicles: action.value,
      };

    case SET_AVERAGE_FUEL_CONSUMPTION:
      return {
        ...state,
        average_fuel_consumption: action.value,
      };

    case SET_MULTIPLICATION:
      return {
        ...state,
        multiplication: action.value,
      };

    case SET_TOTAL_SAVINGS:
      return {
        ...state,
        total_savings: action.value,
      };

    case CALCULATE_MULTIPLICATION:
      return {
        ...state,
        multiplication: action.value,
      };

    case CALCULATE_COMMISSION: {
      return {
        ...state,
        commission: action.value,
      };
    }

    case CALCULATE_AVERAGE_FUEL_CONSUMPTION:
      return {
        ...state,
        average_fuel_consumption: action.value,
      };

    default:
      return state;
  }
};
