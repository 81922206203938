import {useTenantDomainExtension} from 'config';

/*
  WITH TENANT DOMAIN EXTENSION:
  givenPath:    "/ticket-car/contact-info"
  WITHOUT TENANT DOMAIN EXTENSION:
  givenPath:    "/tr/ticket-car/contact-info"

  index:        0
  returns:      "ticket-car"
  index:        1
  returns:      "contact-info"
   */

export default (givenPath, index) => {
  const pathSplitted = givenPath.split('/').filter(p => p !== '');
  return pathSplitted[index + 1 - Number(useTenantDomainExtension)];
};
