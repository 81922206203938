/* eslint-disable camelcase */
import rootActions from 'store/actions';
import {convertStringToInt, decimalNumber} from 'utils';
import leadsActions from './leads';
import selectors from '../selectors';

export const SET_IS_DIRTY = '[TURKEY_PRODUCTS/PRICE_SIMULATOR_FUEL] SET_IS_DIRTY';
export const SET_NUMBER_VEHICLES =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_FUEL] SET_NUMBER_VEHICLES';
export const SET_AVERAGE_FUEL_CONSUMPTION =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_FUEL] SET_AVERAGE_FUEL_CONSUMPTION';
export const SET_MULTIPLICATION =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_FUEL] SET_MULTIPLICATION';
export const SET_TOTAL_SAVINGS =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_FUEL] SET_TOTAL_SAVINGS';
export const CALCULATE_MULTIPLICATION =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_FUEL] CALCULATE_MULTIPLICATION';
export const CALCULATE_COMMISSION =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_FUEL] CALCULATE_COMMISSION';
export const CALCULATE_AVERAGE_FUEL_CONSUMPTION =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_FUEL] CALCULATE_AVERAGE_FUEL_CONSUMPTION';

const setIsDirty = value => ({
  type: SET_IS_DIRTY,
  value,
});

const setNumberVehicles = value => ({
  type: SET_NUMBER_VEHICLES,
  value: convertStringToInt(value),
});

const setAverageFuelConsumption = value => ({
  type: SET_AVERAGE_FUEL_CONSUMPTION,
  value: convertStringToInt(value),
});

const setMultiplication = value => ({
  type: SET_MULTIPLICATION,
  value: convertStringToInt(value),
});

const setTotalSavings = value => ({
  type: SET_TOTAL_SAVINGS,
  value,
});

const calculateMultiplication = () => (dispatch, getState) => {
  const {number_vehicles, average_fuel_consumption} = getState().priceSimulatorFuel;
  const multiplication = number_vehicles * average_fuel_consumption;

  dispatch({
    type: CALCULATE_MULTIPLICATION,
    value: multiplication,
  });
};

const calculateCommission = () => (dispatch, getState) => {
  const {total_savings, multiplication} = getState().priceSimulatorFuel;
  const result = Math.round((total_savings / multiplication) * 100);
  const commission = Number.isNaN(result) ? 0 : result;

  dispatch({
    type: CALCULATE_COMMISSION,
    value: commission,
  });
};

const calculateAverageFuelConsumption = () => (dispatch, getState) => {
  const {multiplication, number_vehicles} = getState().priceSimulatorFuel;
  const avgFuelCons = decimalNumber(multiplication / number_vehicles, 0);

  dispatch({
    type: CALCULATE_AVERAGE_FUEL_CONSUMPTION,
    value: avgFuelCons,
  });
};

const fetchTotalSavings = () => async (dispatch, getState) => {
  // Selectors
  const {selectProductCode} = selectors;
  const productCode = selectProductCode(getState());

  const currentState = () => getState().priceSimulatorFuel;

  // Server-side calculation
  await dispatch(
    rootActions.api.makeRequest('pricer/computeSavings', [
      {
        total_value: currentState().multiplication,
        product_ref: productCode,
      },
    ]),
  ).then(response => {
    const {requestCancelled} = response;
    const savingsResponse = response.data?.data?.savings;

    if (requestCancelled || !savingsResponse) return false;

    const savingsValue = savingsResponse.find(s => s.savings_type === 'savings_employer')
      .savings_value;
    dispatch(setTotalSavings(savingsValue));
    return dispatch(calculateCommission());
  });
};

const initialize = () => async (dispatch, getState) => {
  const currentState = () => getState().priceSimulatorFuel;
  const {isDirty} = currentState();
  const statesData = {
    numberVehicles: () => getState().contact.serverFormData.number_vehicles,
    getAverageConsumption: () => getState().root.settings.fuelCostAverage,
  };

  // Is form is already dirty, don't restore default values
  if (isDirty) return;

  dispatch(setAverageFuelConsumption(statesData.getAverageConsumption()));
  // Set number of vehicles from contact scene
  dispatch(setNumberVehicles(statesData.numberVehicles()));
  dispatch(calculateMultiplication());

  await dispatch(fetchTotalSavings());
};

// Custom leads submit
const updateLeadsSubmit = () => (dispatch, getState) => {
  const {
    number_vehicles,
    average_fuel_consumption,
    commission,
  } = getState().priceSimulatorFuel;

  const leadsPayload = {
    item_count: number_vehicles,
    value: average_fuel_consumption,
    savings_coeff: Number(commission),
  };

  dispatch(leadsActions.serverLeads(leadsPayload));
};

export default {
  setIsDirty,
  setNumberVehicles,
  setMultiplication,
  setAverageFuelConsumption,
  calculateMultiplication,
  calculateAverageFuelConsumption,
  initialize,
  fetchTotalSavings,
  updateLeadsSubmit,
};
