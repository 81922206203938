import tr from './tenants/tr';
import cz from './tenants/cz';

// eslint-disable-next-line prefer-destructuring
const env = process.env;
const parseEnvBoolean = variable => JSON.parse(String(variable).toLowerCase());

// Environment and mode
const isDev = env.NODE_ENV === 'development';
const isDebugMode = parseEnvBoolean(env.REACT_APP_API_DEBUG_MODE);
const useStoreMock = parseEnvBoolean(env.REACT_APP_USE_MOCK_STORE);
const useOnlyFallbackTranslations = parseEnvBoolean(
  env.REACT_APP_USE_ONLY_FALLBACK_TRANSLATIONS,
);
// ---

// Tenant
const tenants = {tr, cz};
const availableTenants = Object.keys(tenants);
const useTenantDomainExtension = parseEnvBoolean(env.REACT_APP_TENANT_DOMAIN_EXTENSION);

const domainExtension = window.location.hostname.split('.').pop();
const tenantFallback = env.REACT_APP_TENANT_FALLBACK;

const readTenant =
  tenantFallback && tenantFallback !== 'empty' ? tenantFallback : domainExtension;
// ---

// API
const apiHost = env.REACT_APP_API_HOST;
const apiDefaultVersion = env.REACT_APP_API_DEFAULT_VERSION;
const apiVariantsSettings = [
  {
    instanceName: 'gwApi',
    hostVariant: 'api',
  },
  {
    instanceName: 'gwXp',
    hostVariant: 'xp',
  },
];
const apiVariants = apiVariantsSettings.map(item => item.instanceName);
// ---

// Delays
const delay = {
  routerLoad: env.REACT_APP_DELAY_ROUTER_LOAD,
  serverRequest: env.REACT_APP_DELAY_SERVER_REQUEST,
  debounce: 1000,
};
// ---

// App details
const appName = env.REACT_APP_NAME;
const appVersion = env.REACT_APP_VERSION;
// ---

// SessionStorage key
const persistSessionStorageKey = `persist:${appName}`;
// ---

// App global settings map
const defaultAppSettingsMap = {
  Languages: 'availableLanguages',
  EDGClientId: 'edgClientId',
  EDGSecretId: 'edgSecretId',
  EDGRootUrl: 'edgRootUrl',
  EmailRegex: 'formEmailRegex',
  NameRegex: 'formPersonNameRegex',
  ZipRegex: 'formZipRegex',
  PhoneRegex: 'formPhoneRegex',
  InstrumentationKey: 'instrumetationKey',
  Currency: 'currency',
  BlobStorageRootUrl: 'blobStorageRootUrl',
  GoogleTrackingId: 'googleTrackingId',
  CookieBannerToken: 'cookieBannerToken',
};

// Images
const publicImgPath = `${env.PUBLIC_URL}/images`;

export {
  isDev,
  isDebugMode,
  useStoreMock,
  useOnlyFallbackTranslations,
  availableTenants,
  useTenantDomainExtension,
  tenants,
  readTenant,
  apiHost,
  apiDefaultVersion,
  apiVariantsSettings,
  apiVariants,
  delay,
  appName,
  appVersion,
  persistSessionStorageKey,
  defaultAppSettingsMap,
  publicImgPath,
};

export default tenants;
