import React from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Fab from '@material-ui/core/Fab';
import TooltipIcon from '@material-ui/icons/ErrorOutline';

// Styles
const fabSize = '1.5rem';
const useStyles = makeStyles(theme => ({
  fab: {
    '&, &:active': {
      boxShadow: 'none',
    },
    '&, &:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: theme.palette.themeColors.blueLight,
    },
    width: fabSize,
    height: fabSize,
    minWidth: fabSize,
    minHeight: fabSize,
    color: theme.palette.grey[400],
    backgroundColor: 'transparent',
  },
}));

export default ({text, tooltipPlacement = 'left'}) => {
  // Classes
  const classes = useStyles();

  return (
    <Tooltip placement={tooltipPlacement} title={text}>
      <Fab className={classes.fab} disableFocusRipple disableRipple>
        <TooltipIcon />
      </Fab>
    </Tooltip>
  );
};
