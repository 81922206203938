export default (str, currency, currencySettings) => {
  if (!str) return null;

  const {thousandSeparator, decimalSeparator, decimalScale} = currencySettings;
  const thousandSeparatorRegex = new RegExp(thousandSeparator, 'g');

  const value = str
    .toString()
    .replace(thousandSeparatorRegex, '')
    .replace(currency, '')
    .replace(decimalSeparator, '.');

  return parseFloat(parseFloat(value).toFixed(decimalScale));
};
