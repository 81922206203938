import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
// Mui components
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
// Components - local
import scenes from 'scenarios/TurkeyProducts/scenes';
import scenesChildrenComponents from 'scenarios/TurkeyProducts/components/scenesChildrenComponents';
import staticPages from 'scenarios/components/StaticPages';
// Components - global
import LoaderOverlay from 'components/Loader/Overlay';
import ProgressBar from 'components/ProgressBar';
import StepperLegend from 'components/StepperLegend';
import StepperControls from 'components/StepperControls';
import PageNotFound from 'ScenarioComponents/PageNotFound';
import BlockElement from 'ScenarioComponents/flows/Visitor/BlockElement';
import ContainerPage from 'ScenarioComponents/flows/Visitor/ContainerPage';
// HOC
import withErrorHandler from 'hoc/ErrorHandler/.';

import rootActions from 'store/actions';

import core, {flowActions, flowPaths} from './core/coreBenefit';

// Reducer import for this flow
import reducers from './store/reducers';
import middleware from './store/middleware';

// Styles
const useStyles = theme => ({
  container: ({containerMaxWidth, extendedLayout}) =>
    extendedLayout
      ? theme.extendedLayoutContainer
      : {maxWidth: theme.breakpoints.values[containerMaxWidth]},
});

class Flow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
    };
  }

  async componentDidMount() {
    const {props} = this;
    const {
      featureToggle,
      setTenant,
      fetchInitData,
      setVendor,
      loaderShow,
      loaderHide,
      setFlowPaths,
    } = props;

    // Feature toggles flags
    const {hasBannerRedirect} = featureToggle;
    const enabledBannerRedirect = hasBannerRedirect && hasBannerRedirect.isActive;

    loaderShow();
    setTenant();
    setFlowPaths();

    // Feature toggles before fetch init data
    if (enabledBannerRedirect) {
      await this.fromBannerRedirect();
    }

    // Fetch init data
    fetchInitData().then(() => {
      this.setState({isReady: true});

      // Feature toggles after fetch init data
      if (enabledBannerRedirect) {
        setVendor();
      }

      loaderHide();
    });
  }

  fromBannerRedirect() {
    const {featureToggle, bannerRedirectData, errorShow} = this.props;

    const {hasBannerRedirect} = featureToggle;
    const {isActive, storageItemName} = hasBannerRedirect;
    const leads = JSON.parse(sessionStorage.getItem(storageItemName));

    return new Promise(resolve => {
      if (isActive && leads) {
        return resolve(bannerRedirectData(leads));
      }
      return errorShow('APP_INVALID_BANNER_TOKEN');
    });
  }

  render() {
    const {flowRoutes, staticRoutes, steps, coreSettings} = core;
    const {isReady} = this.state;
    const {
      visitorProductPath,
      visitorFlowName,
      isLoading,
      isProgress,
      stepActive,
      nextNavi,
      prevNavi,
      nextDisabled,
      prevDisabled,
      navigationVisibility,
      stepLegendVisibility,
      currentScene,
      classes,
    } = this.props;

    const {translationIdentifierKey, tableType} = coreSettings;
    const translationIdentifierPrefix = `visitor_${translationIdentifierKey}.`;
    const translationIdentifierPrefixWithScene = `${translationIdentifierPrefix}${currentScene ||
      ''}`;

    return (
      <>
        {isLoading && <LoaderOverlay />}
        {isProgress && <ProgressBar />}
        {isReady && (
          <Container
            className={[isLoading && 'display-none', classes.container].join(' ')}
          >
            {stepLegendVisibility && (
              <BlockElement>
                <StepperLegend
                  steps={steps}
                  stepActive={stepActive}
                  translationIdentifierPrefix={translationIdentifierPrefix}
                />
              </BlockElement>
            )}

            <BlockElement>
              <Grid container>
                <ContainerPage
                  flowRoutes={flowRoutes}
                  staticRoutes={staticRoutes}
                  scenes={scenes}
                  scenesChildrenComponents={scenesChildrenComponents.benefit}
                  staticPages={staticPages}
                  rootActions={rootActions}
                  flowActions={flowActions}
                  flowPaths={flowPaths}
                  PageNotFound={PageNotFound}
                  visitorProductPath={visitorProductPath}
                  visitorFlowName={visitorFlowName}
                  translationIdentifierPrefixBase={translationIdentifierPrefix}
                  translationIdentifierPrefix={translationIdentifierPrefixWithScene}
                  tableType={tableType}
                />
              </Grid>
            </BlockElement>

            {navigationVisibility && currentScene && (
              <BlockElement>
                <StepperControls
                  next={nextNavi}
                  prev={prevNavi}
                  nextDisabled={nextDisabled}
                  prevDisabled={prevDisabled}
                  translationIdentifierPrefix={translationIdentifierPrefixWithScene}
                />
              </BlockElement>
            )}
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {root, flow} = state;
  const {featureToggle} = ownProps;
  const {isLoading, isProgress, error, errorNeverHide, errorCloseDisabled} = root.ui;
  const {
    stepActive,
    stepCompleted,
    nextDisabled,
    prevDisabled,
    navigationVisibility,
    stepLegendVisibility,
  } = flow.navigation;
  const {currentScene, flowMaxDepth} = flow;
  const {productCode} = state.featureToggle;

  return {
    isLoading,
    isProgress,
    error,
    errorNeverHide,
    errorCloseDisabled,
    stepActive,
    stepCompleted,
    nextDisabled,
    prevDisabled,
    navigationVisibility,
    stepLegendVisibility,
    featureToggle,
    currentScene,
    flowMaxDepth,
    productCode,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const {tenant} = ownProps;
  const loaderShow = () => dispatch(rootActions.ui.loaderShow());
  const loaderHide = () => dispatch(rootActions.ui.loaderHide());
  const errorShow = message => dispatch(rootActions.ui.errorShow(message, true, true));
  return {
    setTenant: () => dispatch(rootActions.settings.setTenant(tenant)),
    setFlowPaths: () => dispatch(flowActions.flow.setFlowPaths(flowPaths)),
    bannerRedirectData: leads => {
      dispatch(flowActions.featureToggle.setProductCode(leads.productCode));
      dispatch(flowActions.featureToggle.setProductType(leads.productType));
      dispatch(flowActions.leads.setLeads(leads));
    },
    fetchInitData: () => {
      return new Promise(resolve => {
        return Promise.all([
          dispatch(rootActions.settings.fetchSettings()),
          dispatch(rootActions.settings.fetchTranslations()),
        ])
          .then(() => resolve())
          .catch(() => errorShow('APP_INIT_FAILED'));
      });
    },
    setVendor: () => dispatch(flowActions.featureToggle.setVendor()),
    loaderShow,
    loaderHide,
    errorShow,
    dismissServerError: () => dispatch(rootActions.ui.errorHide()),
    nextNavi: () => dispatch(flowActions.flow.navigationNextTrigger()),
    prevNavi: () => dispatch(flowActions.flow.navigationPrevTrigger()),
  };
};

export default {
  store: {
    reducers,
    middleware,
  },
  Container: compose(
    connect(mapStateToProps, mapDispatchToProps),
    withErrorHandler,
    withStyles(useStyles),
  )(Flow),
};
