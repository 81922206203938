import {
  CLEAR_ORDER,
  CLEAR_ORDER_WITHOUT_ID,
  SET_IS_DIRTY,
  SET_ORDER_ID,
  SET_ORDER_DATA,
  SET_ORDER_CALCULATIONS,
} from '../actions/orderBenefit';
import initialStates from './initialStates';

const initialState = initialStates.orderBenefit;

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORDER:
      return {
        ...initialState,
      };

    case CLEAR_ORDER_WITHOUT_ID:
      return {
        ...initialState,
        orderId: state.orderId,
      };

    case SET_IS_DIRTY:
      return {
        ...state,
        isDirty: action.value,
      };

    case SET_ORDER_ID:
      return {
        ...state,
        orderId: action.value,
      };

    case SET_ORDER_DATA:
      return {
        ...state,
        orderData: action.value,
      };

    case SET_ORDER_CALCULATIONS:
      return {
        ...state,
        orderCalculations: action.value,
      };

    default:
      return state;
  }
};
