import MontserratRegular from './Montserrat-regular.woff2';
import MontserratRegularExt from './Montserrat-regular-ext.woff2';
import MontserratBold from './Montserrat-bold.woff2';

export default [
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url(${MontserratRegular}) format('woff2')`,
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url(${MontserratRegularExt}) format('woff2')`,
  },
  {
    fontFamily: 'Montserrat',
    fontStyle: 'bold',
    fontWeight: 700,
    src: `url(${MontserratBold}) format('woff2')`,
  },
];
