import React from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Grow from '@material-ui/core/Grow';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      '&': {
        padding: theme.spacing(0, 2),
      },
    },
    borderRadius: `${theme.borderRadius.big}!important`,
    '&:focus': {
      outlineColor: 'transparent',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.75),
    top: theme.spacing(0.75),
  },
  title: {
    lineHeight: 'inherit',
  },
}));

export default props => {
  const classes = useStyles();
  const {
    status,
    title,
    handleClose,
    centeredText,
    children,
    maxWidth = 'md',
    withCloseButton = true,
    isCloseDisabled,
  } = props;

  return (
    <Dialog
      disableBackdropClick={isCloseDisabled}
      disableEscapeKeyDown={isCloseDisabled}
      scroll='paper'
      fullWidth
      maxWidth={maxWidth}
      classes={{
        container: classes.container,
        paper: classes.paper,
      }}
      open={status}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 750,
      }}
      TransitionComponent={Grow}
    >
      <DialogTitle disableTypography className={`${centeredText ? ' text-center' : ''}`}>
        <Typography variant='h2' className={classes.title}>
          {title}
        </Typography>
        {withCloseButton && (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
