import * as Yup from 'yup';

export default (validationType, validations = []) => {
  const yupValidation = Yup[validationType];
  let validator = yupValidation && yupValidation();

  validations.forEach(
    ({params, type}) => validator[type] && (validator = validator[type](...params)),
  );

  return validator;
};
