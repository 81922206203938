import {
  SET_PAYMENT_METHOD,
  SET_PAYMENT_METHOD_ONLINE_OPTIONS_IS_DIRTY,
  SET_PAYMENT_INITIATED,
} from '../actions/order';
import initialStates from './initialStates';

const initialState = initialStates.order;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.value,
      };

    case SET_PAYMENT_METHOD_ONLINE_OPTIONS_IS_DIRTY:
      return {
        ...state,
        paymentMethodOnlineOptionsIsDirty: action.value,
      };

    case SET_PAYMENT_INITIATED:
      return {
        ...state,
        paymentInitiated: action.value,
      };

    default:
      return state;
  }
};
