import {SET_BIG_CLIENT_CHOICE, SET_MODAL_BUTTONS_ARE_DIRTY} from '../actions/bigClient';
import initialStates from './initialStates';

const initialState = initialStates.bigClient;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BIG_CLIENT_CHOICE:
      return {
        ...state,
        choice: action.value,
      };

    case SET_MODAL_BUTTONS_ARE_DIRTY:
      return {
        ...state,
        modalButtonsAreDirty: action.value,
      };

    default:
      return state;
  }
};
