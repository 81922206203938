import rootActions from 'store/actions';
import flowActions from './flow';
import selectors from '../selectors';
import leadsActions from './leads';

export const SET_SELECTED_MEDIUM_TYPE =
  '[TURKEY_PRODUCTS/MEDIUM_TYPES] SET_SELECTED_MEDIUM_TYPE';
export const SET_AVAILABLE_MEDIUM_TYPES =
  '[TURKEY_PRODUCTS/MEDIUM_TYPES] SET_AVAILABLE_MEDIUM_TYPES';

const setSelectedMediumType = value => ({
  type: SET_SELECTED_MEDIUM_TYPE,
  value,
});

const setAvailableMediumTypes = value => ({
  type: SET_AVAILABLE_MEDIUM_TYPES,
  value,
});

const updateSelectedMediumType = code => (dispatch, getState) => {
  const currentState = () => getState().mediumTypes;
  const {nextDisabled} = getState().flow.navigation;
  const {selectedMediumType} = currentState();

  if ((selectedMediumType || code) && nextDisabled) {
    dispatch(flowActions.setNavigationNextDisabled(false));
  }

  if (code) {
    dispatch(setSelectedMediumType(code));
  }
};

const fetchAvailableMediumTypes = () => (dispatch, getState) => {
  // Selectors
  const {selectProductCode} = selectors;
  const productCode = selectProductCode(getState());

  return dispatch(
    rootActions.api.makeRequest('order/getMediumTypes', [productCode]),
  ).then(response => {
    const {data} = response.data;
    return data.map(({id, code}) => ({id, code}));
  });
};

const initialize = () => dispatch =>
  dispatch(fetchAvailableMediumTypes()).then(mediumTypes => {
    dispatch(updateSelectedMediumType());
    dispatch(setAvailableMediumTypes(mediumTypes));
  });

// Custom leads submit
const updateLeadsSubmit = () => (dispatch, getState) => {
  // Selectors
  const {selectMediumType} = selectors;
  const mediumType = selectMediumType(getState());

  const payload = {
    medium_type: mediumType,
  };

  dispatch(leadsActions.serverLeads(payload));
};

export default {
  updateSelectedMediumType,
  initialize,
  updateLeadsSubmit,
};
