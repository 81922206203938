import api from './api';

export const SET_TENANT = '[ROOT/SETTINGS] SET_TENANT';
export const SET_SESSION_ID = '[ROOT/SETTINGS] SET_SESSION_ID';
export const SET_CURRENT_LANGUAGE = '[ROOT/SETTINGS] SET_CURRENT_LANGUAGE';
export const FETCH_SETTINGS = '[ROOT/SETTINGS] FETCH_SETTINGS';
export const UPDATE_SETTINGS = '[ROOT/SETTINGS] UPDATE_SETTINGS';
export const FETCH_TRANSLATIONS = '[ROOT/SETTINGS] FETCH_TRANSLATIONS';
export const UPDATE_TRANSLATIONS = '[ROOT/SETTINGS] UPDATE_TRANSLATIONS';

const setTenant = value => ({
  type: SET_TENANT,
  value,
});

const setSessionId = value => ({
  type: SET_SESSION_ID,
  value,
});

const setCurrentLanguage = value => ({
  type: SET_CURRENT_LANGUAGE,
  value,
});

// async action creator
const fetchSettings = () => (dispatch, getState) => {
  const {productCode} = getState().featureToggle;
  const settingsPromises = [dispatch(api.makeRequest('settings'))];

  if (productCode) {
    settingsPromises.push(dispatch(api.makeRequest('settings', productCode)));
  }

  return Promise.all(settingsPromises).then(payload => {
    // dispatch middleware action for data transformation
    return dispatch({
      type: FETCH_SETTINGS,
      payload,
    });
  });
};

// sync function manipulate the result from fetch
const updateSettings = payload => ({
  type: UPDATE_SETTINGS,
  payload,
});

const fetchTranslations = lang => (dispatch, getState) => {
  const getSettings = () => getState().root.settings;
  const language = lang || getSettings().currentLanguage || getSettings().tenant;

  return dispatch(api.makeRequest('translations', language))
    .then(response => response)
    .then(payload =>
      dispatch({
        type: FETCH_TRANSLATIONS,
        payload,
      }),
    );
};

const changeLanguage = lang => async dispatch => {
  await dispatch(fetchTranslations(lang));
  return dispatch(setCurrentLanguage(lang));
};

const updateTranslations = payload => ({
  type: UPDATE_TRANSLATIONS,
  payload,
});

export default {
  setTenant,
  setSessionId,
  setCurrentLanguage,
  fetchSettings,
  updateSettings,
  fetchTranslations,
  updateTranslations,
  changeLanguage,
};
