import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
// Components - global
import SimpleTable from 'components/Table/Simple';
import CurrencyValue from 'components/CurrencyValue';
// Mui components
import {withTheme} from '@material-ui/core/styles';
import {lighten} from 'material-ui/styles/colorManipulator';
import Box from '@material-ui/core/Box';
// React Intl
import {FormattedMessage, injectIntl} from 'react-intl';

const mapStateToProps = (state, ownProps) => {
  const {orderBenefit} = state;
  const {orderCalculations} = orderBenefit;
  const {invoiceTotal} = orderCalculations;

  // Selectors
  const {selectors, tableType} = ownProps;
  const {selectProductCode, selectMediumType, selectNumberOfRows} = selectors;

  return {
    productCode: selectProductCode(state),
    mediumType: selectMediumType(state),
    employees: selectNumberOfRows(tableType)(state),
    invoiceTotal,
  };
};

export default compose(
  connect(mapStateToProps),
  withTheme,
  injectIntl,
)(
  ({
    theme,
    translationIdentifierPrefix,
    productCode,
    mediumType,
    employees,
    invoiceTotal,
  }) => {
    // Intl
    const tableTranslate = keyId => (
      <FormattedMessage id={`${translationIdentifierPrefix}_table-summary_${keyId}`} />
    );

    const tableData = [
      {
        label: (
          <Box fontWeight='fontWeightBold' color={theme.palette.themeColors.blueHot}>
            {tableTranslate(`label_productCode_${productCode}`)}{' '}
            {tableTranslate(`label_mediumType_${mediumType}`)}
          </Box>
        ),
        rowBg: theme.palette.grey[200],
      },
      {
        label: tableTranslate('label_number-employees'),
        values: employees,
      },
      {
        label: (
          <Box fontWeight='fontWeightBold' color={theme.palette.themeColors.blueHot}>
            {tableTranslate('label_total-to-pay')}
          </Box>
        ),
        values: (
          <Box fontWeight='fontWeightBold' color={theme.palette.themeColors.blueHot}>
            <CurrencyValue value={invoiceTotal} />
          </Box>
        ),
        rowBg: lighten(theme.palette.themeColors.green, theme.opacity.medium),
      },
    ];

    return <SimpleTable tableRows={tableData} />;
  },
);
