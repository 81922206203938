import {useStoreMock} from 'config';
import merge from 'deepmerge';
import storeMock from '../storeMock';

const initial = {
  flow: {
    navigation: {
      stepActive: null,
      stepLegendVisibility: null,
      nextTrigger: false,
      prevTrigger: false,
      nextDisabled: null,
      prevDisabled: null,
      navigationVisibility: null,
      nextScenePath: null,
      prevScenePath: null,
    },
    currentScene: null,
    flowPaths: null,
    flowMaxDepth: null,
  },
  featureToggle: {
    productCode: null,
    productType: null,
    vendorName: null,
    vendorLogoPath: null,
    analyticsEnabled: false,
  },
  leads: {},
  contact: {
    formIsDirty: false,
    contactId: null,
    serverFormData: {
      contact_first_name: null,
      contact_last_name: null,
      company_name: null,
      mobile_phone: null,
      contact_phone: null,
      contact_email: null,
      contact_email_confirm: null,
      number_employees: null,
    },
  },
  mediumTypes: {
    selectedMediumType: null,
    availableMediumTypes: [],
  },
  priceSimulatorFuel: {
    isDirty: false,
    number_vehicles: null,
    number_employees: null,
    average_fuel_consumption: null,
    multiplication: null,
    commission: null,
    total_savings: null,
  },
  priceSimulatorBenefit: {
    isDirty: false,
    paymentType: null,
    calendar_unit: null,
    allowance: null,
    employee_number: null,
    savingsCalculations: {},
  },
  company: {
    formIsDirty: false,
    companyUuid: null,
    companyId: null,
    serverFormData: {
      company_name: null,
      fiscal_or_vat_number: null,
      tax_office: null,
      mobile_phone_number: null,
      phone_number: null,
      email: null,
      billing_address: {
        address: null,
        zip_code: null,
        city: null,
        district: null,
        neighbourhood: null,
      },
      delivery_address: {
        address: null,
        zip_code: null,
        city: null,
        district: null,
        neighbourhood: null,
      },
      order_picker: {
        first_name: null,
        last_name: null,
        mobile_phone: null,
        landline_phone: null,
        email: null,
      },
      e_invoice_pay: {
        e_archive_email: null,
      },
    },
    deliveryAddressCollapsed: false,
    orderPickerCollapsed: false,
    eInvoicePayCollapsed: false,
  },
  order: {
    paymentMethod: null,
    paymentMethodOnlineOptionsIsDirty: false,
    paymentInitiated: null,
  },
  orderFuel: {
    orderId: null,
    orderData: {
      order_request_lines: [],
    },
    orderWillingToPay: null,
  },
  orderBenefit: {
    orderId: null,
    isDirty: false,
    orderData: {
      order_request_lines: [],
    },
    orderCalculations: {},
  },
  consents: {
    acceptedGdpr: false,
    acceptedCommercials: false,
    acceptedMarketing: false,
    acceptedTerms: false,
    acceptedAutoPaymentWithCardSave: false,
  },
  bigClient: {
    choice: null,
    modalButtonsAreDirty: false,
  },
};

const state = useStoreMock ? merge(initial, storeMock) : initial;

export default state;
