/* eslint-disable camelcase */
import rootActions from 'store/actions';
import selectors from '../selectors';

export const SET_BIG_CLIENT_CHOICE = '[TURKEY_PRODUCTS/BIG_CLIENT] SET_BIG_CLIENT_CHOICE';
export const SET_MODAL_BUTTONS_ARE_DIRTY =
  '[TURKEY_PRODUCTS/BIG_CLIENT] SET_MODAL_BUTTONS_ARE_DIRTY';

const setBigClientChoice = value => ({
  type: SET_BIG_CLIENT_CHOICE,
  value,
});

const setModalButtonsAreDirty = value => ({
  type: SET_MODAL_BUTTONS_ARE_DIRTY,
  value,
});

const email = () => (dispatch, getState) => {
  const statesData = {
    currentScene: () => getState().flow.currentScene,
    contact: () => getState().contact.serverFormData,
    orderFuel: () => getState().orderFuel,
    priceSimulatorFuel: () => getState().priceSimulatorFuel,
    priceSimulatorBenefit: () => getState().priceSimulatorBenefit,
    featureToggle: () => getState().featureToggle,
  };

  // Selectors
  const {
    selectProductCode,
    selectNumberOfRows,
    selectEmployeeListOrderTotalValue,
  } = selectors;
  const productCode = selectProductCode(getState());

  const scene = statesData.currentScene();

  // Contact Info
  const vehiclesNo = statesData.contact().number_vehicles;
  const employeesNo = statesData.contact().number_employees;

  // Price Simulator Fuel
  const psfVehiclesNo = statesData.priceSimulatorFuel().number_vehicles; // used for price-simulator
  const psfAvgFuelCons = statesData.priceSimulatorFuel().average_fuel_consumption; // used for price-simulator & vehicle-list
  const psfCommission = statesData.priceSimulatorFuel().commission / 100; // used for all except contact-info

  // Price Simulator Benefit
  const psbEmployeeNo = statesData.priceSimulatorBenefit().employee_number;
  const psbMonthBudget = statesData.priceSimulatorBenefit().savingsCalculations
    ?.monthBudget;

  // Order
  const numberOfRows = tableType => selectNumberOfRows(tableType)(getState());

  // Order Fuel
  const {orderWillingToPay} = getState().orderFuel; // used for order-summary
  const orderRequestLinesFuel = numberOfRows('vehicles'); // used for vehicle-list & order-summary

  // Order Benefit
  const orderRequestLinesBenefit = numberOfRows('employees'); // used for employee-list
  const employeeListTotalValue = selectEmployeeListOrderTotalValue(getState());

  let requestPayload = {};

  switch (scene) {
    case 'contact-info':
      requestPayload = {
        item_count: vehiclesNo || employeesNo,
      };
      break;

    case 'price-simulator':
      requestPayload =
        productCode !== '1'
          ? {
              item_count: psfVehiclesNo,
              value: psfAvgFuelCons,
              savings_coeff: psfCommission,
            }
          : {
              item_count: psbEmployeeNo,
              month_budget: psbMonthBudget,
            };
      break;

    case 'vehicle-list':
      requestPayload = {
        item_count: orderRequestLinesFuel,
        value: psfAvgFuelCons,
        savings_coeff: psfCommission,
      };
      break;

    case 'employee-list':
      requestPayload = {
        item_count: orderRequestLinesBenefit,
        total_load: employeeListTotalValue,
      };
      break;

    case 'order-summary':
      requestPayload = {
        item_count: orderRequestLinesFuel,
        value: orderWillingToPay,
        savings_coeff: psfCommission,
      };
      break;

    default:
      break;
  }

  return dispatch(
    rootActions.api.makeRequest('notifications/bigClient', [requestPayload]),
  );
};

export default {
  email,
  setBigClientChoice,
  setModalButtonsAreDirty,
};
