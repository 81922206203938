/* eslint-disable class-methods-use-this */
/* eslint-disable no-shadow */
import createYupValidation from './createYupValidation';
import {
  required,
  min,
  max,
  matches,
  when,
  oneOf,
  notOneOf,
  minPrice,
  maxPrice,
  matchingValues,
} from './validations';
import fieldSettings from './fieldSettings';
import rangeSettings from './rangeSettings';

export default class Presets {
  constructor(givenRegexCollection, settings, dynamicValidationOptions = {}) {
    this.givenRegexCollection = givenRegexCollection;
    this.tenant = settings.tenant;
    this.dynamicValidationOptions = dynamicValidationOptions;

    this.validationRanges = rangeSettings(settings);
  }

  personName(name) {
    const {tenant, givenRegexCollection} = this;
    const nameRegexWithUnicodeFlag = new RegExp(
      givenRegexCollection.formPersonNameRegex,
      'u',
    );

    return [
      required('required'),
      max('maxChars', fieldSettings[tenant][name].MAX),
      matches('invalidFormat', nameRegexWithUnicodeFlag),
    ];
  }

  companyName(name) {
    const {tenant} = this;

    return [required('required'), max('maxChars', fieldSettings[tenant][name].MAX)];
  }

  email() {
    const {givenRegexCollection} = this;

    return [
      required('required'),
      matches('invalidFormat', givenRegexCollection.formEmailRegex),
    ];
  }

  phoneNumberMobile(name, options) {
    const {givenRegexCollection} = this;

    const phoneNumberMobileValidation = [
      matches('invalidFormat', givenRegexCollection.regexFormPhoneMobile),
    ];

    if (options?.isRequired) {
      phoneNumberMobileValidation.push(required('required'));
    }

    return phoneNumberMobileValidation;
  }

  phoneNumberFlat(name, options) {
    const {givenRegexCollection} = this;

    const phoneNumberFlatValidation = [
      matches('invalidFormat', givenRegexCollection.formPhoneRegex),
    ];

    if (options?.isRequired) {
      phoneNumberFlatValidation.push(required('required'));
    }

    return phoneNumberFlatValidation;
  }

  vehiclesNumber(name) {
    const {tenant} = this;

    return [required('required'), max('maxChars', fieldSettings[tenant][name].MAX)];
  }

  employeesNumber(name) {
    const {tenant} = this;

    return [required('required'), max('maxChars', fieldSettings[tenant][name].MAX)];
  }

  taxOffice(name) {
    const {tenant} = this;

    return [required('required'), max('maxChars', fieldSettings[tenant][name].MAX)];
  }

  fiscalOrVatNumber(name) {
    const {tenant} = this;

    return [
      required('required'),
      min('minChars', fieldSettings[tenant][name].MIN),
      max('maxChars', fieldSettings[tenant][name].MAX),
    ];
  }

  address(name) {
    const {tenant} = this;

    return [
      required('required'),
      min('minChars', fieldSettings[tenant][name].MIN),
      max('maxChars', fieldSettings[tenant][name].MAX),
    ];
  }

  zipCode() {
    const {givenRegexCollection} = this;

    return [matches('invalidFormat', givenRegexCollection.formZipRegex)];
  }

  contactMessage(name) {
    const {tenant} = this;

    return [
      required('required'),
      min('minChars', fieldSettings[tenant][name].MIN),
      max('maxChars', fieldSettings[tenant][name].MAX),
    ];
  }

  unique(name, options) {
    const {dynamicValidationOptions} = this;
    const {translationPrefix, validationPresetInherited} = options;

    const uniqueValidation = [
      notOneOf(translationPrefix, dynamicValidationOptions[name]),
      required('required'),
    ];

    if (validationPresetInherited) {
      uniqueValidation.push(...this[validationPresetInherited](name));
    }

    return uniqueValidation;
  }

  plateNumber() {
    const {givenRegexCollection} = this;

    return [matches('invalidFormat', givenRegexCollection.regexPlateNumber)];
  }

  employeeId(name) {
    const {tenant} = this;

    return [max('maxChars', fieldSettings[tenant][name].MAX)];
  }

  /*
   Groups validations:
   */
  phoneNumbersGroup(name, options) {
    // At least one phone number required
    const {currentValidationPreset, otherField} = options;

    // Validation preset
    const validationPreset = this[currentValidationPreset]().pop();

    return [
      validationPreset,
      when(otherField, {
        is: undefined,
        then: createYupValidation('string', [required('phoneNumbersRequired')]),
      }),
    ];
  }

  emailConfirm(name, options) {
    const {emailValue, confirmEmailValue} = options;
    const {givenRegexCollection} = this;

    return [
      required('required'),
      matches('invalidFormat', givenRegexCollection.formEmailRegex),
      matchingValues(emailValue, confirmEmailValue, 'emailsNotMatch'),
    ];
  }

  range(name, options) {
    const {validationRanges} = this;
    const {min, max, priceRange, numberRange} = options;

    // Range validation message
    const minValidationMessage =
      (priceRange && 'minPrice') || (numberRange && 'minValue');
    const maxValidationMessage =
      (priceRange && 'maxPrice') || (numberRange && 'maxValue');

    const rangeValidation = [
      minPrice(minValidationMessage, validationRanges[min]),
      maxPrice(maxValidationMessage, validationRanges[max]),
    ];

    if (options?.isRequired) {
      rangeValidation.push(required('required'));
    }

    return rangeValidation;
  }

  justRequired() {
    return [required('required')];
  }

  checkbox(name, options) {
    const translation = options?.translationKey;
    const validationMessage = translation || name;

    if (options?.noValidation) {
      return [];
    }

    return [oneOf(validationMessage)];
  }
}
