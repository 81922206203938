import {
  SET_CONTACT_ID,
  SET_FORM_IS_DIRTY,
  UPDATE_SERVER_FORM_DATA,
} from '../actions/contact';
import initialStates from './initialStates';

const initialState = initialStates.contact;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACT_ID:
      return {
        ...state,
        contactId: action.value,
      };

    case SET_FORM_IS_DIRTY:
      return {
        ...state,
        formIsDirty: action.value,
      };

    case UPDATE_SERVER_FORM_DATA:
      return {
        ...state,
        serverFormData: action.payload,
      };

    default:
      return state;
  }
};
