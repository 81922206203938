/* eslint-disable camelcase */
// Disabling camelcase for backend payload structure mappings

import axios from 'axios';
import axiosCancel from 'axios-cancel';
import {withVersioning, VersioningStrategy} from 'axios-api-versioning';
import * as AxiosLogger from 'axios-logger';
import {isDebugMode, apiHost, delay, apiDefaultVersion, apiVariants} from 'config';
import {uuid} from 'utils';
import merge from 'deepmerge';
import createApiUrl from './createApiUrl';

const serverRequestDelay = delay.serverRequest;

// API versioning
const apiVersioningConfig = (version = apiDefaultVersion) => ({
  apiVersion: version,
  versioningStrategy: VersioningStrategy.UrlPath,
});

// Default request options
const defaultOptions = {
  headers: {
    'x-correlation-id': uuid,
    'Content-Type': 'application/json',
  },
};

// Defining particular options for ClientPortal and Gateway API's instances
// Notice: gateway baseURL will be set later from settings request response using updateHost() method
const instances = {
  cp: {
    baseURL: createApiUrl(apiHost),
  },
};
apiVariants.forEach(item => (instances[item] = {}));

const availableInstances = Object.keys(instances);

// AxiosLogger config
AxiosLogger.setGlobalConfig({
  dateFormat: '@ HH:MM:ss',
});

// Error Handler
const errorHandler = error => {
  const {response} = error;
  const {status, data} = response;
  const {meta} = data;

  return Promise.reject(
    (() => {
      // Not found
      if (status === 404) {
        return 'NOT_FOUND';
      }

      // Unauthorized
      if (status === 401) {
        return 'UNAUTHORIZED_REQUEST';
      }

      // Bad Request
      if (status === 400) {
        return meta || 'BAD_REQUEST';
      }

      // Validation error
      if (status === 422) {
        return meta ? meta.messages : 'VALIDATION_ERROR';
      }

      return 'SERVER_ERROR';
    })(),
  );
};

// Create axios instances from a deep merge
availableInstances.forEach(i => {
  instances[i] = axios.create(merge(instances[i], defaultOptions));
  instances[i] = withVersioning(instances[i], apiVersioningConfig());
  // Axios request cancellation
  axiosCancel(instances[i]);
});

// Update methods for axios instances
const updateHeaders = (instance, header, value) => {
  const update = currentInstance =>
    (instances[currentInstance].defaults.headers.common[header] = value);

  const inst = instance === '*' ? availableInstances : [instance];
  inst.forEach(i => update(i));
};
const updateHost = (instance, host, hostVariant) =>
  (instances[instance].defaults.baseURL = createApiUrl(host, hostVariant, hostVariant));

// Interceptors
availableInstances.forEach(i => {
  const instance = instances[i].interceptors;

  // Add a request interceptor
  instance.request.use(
    request => {
      // Add AxiosLogger for debug purposes
      if (isDebugMode) {
        AxiosLogger.requestLogger(request);
      }

      // Add a fake fetch delay for debug purposes
      if (serverRequestDelay) {
        return new Promise(resolve =>
          setTimeout(() => resolve(request), serverRequestDelay),
        );
      }

      return request;
    },
    error => errorHandler(error),
  );

  // Add a response interceptor
  instance.response.use(
    response => response,
    error => {
      const {message, constructor} = error;
      const errorType = constructor.name;

      switch (errorType) {
        case 'Cancel':
          return {
            requestCancelled: true,
            message,
          };

        case 'Error':
          return errorHandler(error);

        default:
          return error;
      }
    },
  );
});

// Convert methods
// Convert ArrayBuffer to a base64 encoded string
const arrayBufferToBase64Str = arrayBuffer =>
  `'${btoa(
    new Uint8Array(arrayBuffer).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      '',
    ),
  )}'`;

// API Call
const apiCall = i => instances[i];

// API Request Cancel
const requestCancelId = id => ({
  requestId: `[REQUEST CANCEL]${id ? ` ${id}` : ''}`,
});

// ClientPortal Frontend API requests
const settings = (productCode, body) =>
  apiCall('cp')[(() => 'get')()](`settings${productCode ? `/${productCode}` : ''}`, body);
const translations = language => apiCall('cp').get(`/translations/${language}`);

// Gateway API requests
const company = {
  // Get a company uuid
  create: () => apiCall('gwApi').post('/companies'),
  // Create a new contact id
  createContact: (companyUuid, body) =>
    apiCall('gwApi').post(`companies/${companyUuid}/contact-info`, body, {
      ...requestCancelId('company/createContact'),
    }),
  // Update an existing contact
  updateContact: (companyUuid, contactId, body) =>
    apiCall('gwApi').put(`companies/${companyUuid}/contact-info/${contactId}`, body),
  // Create a new company
  createCompany: (companyUuid, body, partialUpdate = false) =>
    apiCall('gwApi')[(() => (partialUpdate ? 'patch' : 'post'))()](
      `companies/${companyUuid}/company-info`,
      body,
    ),
  // Update an existing company
  updateCompany: (companyUuid, companyId, body, partialUpdate = false) =>
    apiCall('gwApi')[(() => (partialUpdate ? 'patch' : 'put'))()](
      `companies/${companyUuid}/company-info/${companyId}`,
      body,
    ),
  // Referentials:
  getCities: () => apiCall('gwApi').get('companies/referentials/cities'),
  getDistricts: cityId =>
    apiCall('gwApi').get(`companies/referentials/districts?city_id=${cityId}`),
  getNeighbourhoods: district_id =>
    apiCall('gwApi').get(
      `companies/referentials/neighbourhoods?district_id=${district_id}`,
    ),
  // Sales Force check
  unicityCheck: (companyUuid, productCode, fiscalNumber, vatNumber) =>
    apiCall('gwXp').get(
      `companies/${companyUuid}/get-company-info-from-internal-provider/`,
      {
        params: {
          fiscal_number: fiscalNumber,
          vat_number: vatNumber,
          product_ref: productCode,
        },
        apiVersion: '2',
      },
    ),
  updateConsents: (companyUuid, body) =>
    apiCall('gwApi').post(`companies/${companyUuid}/consents`, body),
};
const pricer = {
  // Calculate savings for a given amount of money
  computeSavings: body =>
    apiCall('gwApi').post('compute-savings', body, {
      ...requestCancelId('order/createOrder'),
    }),
};
const order = {
  createOrder: body =>
    apiCall('gwXp').post(`order-requests`, body, {
      apiVersion: '2',
      ...requestCancelId('order/createOrder'),
    }),
  updateOrder: (orderId, body) =>
    apiCall('gwXp').patch(`order-requests/${orderId}`, body, {
      apiVersion: '2',
      ...requestCancelId('company/updateOrder'),
    }),
  status: (orderId, statusValue) =>
    apiCall('gwApi').put(`order-requests/${orderId}/status/${statusValue}`, null, {
      apiVersion: '2',
    }),
  updateOrderPaymentStatus: (orderId, body) => {
    return apiCall('gwApi').put(`order-requests/${orderId}/payment-info`, body, {
      apiVersion: '3',
    });
  },

  getVehicleType: () =>
    apiCall('gwApi').get(`order-requests/referentials/vehicle-types`, {apiVersion: '2'}),
  getMediumTypes: productCode =>
    apiCall('gwApi').get(
      `order-requests/referentials/medium-types/?product_ref=${productCode}`,
      {
        apiVersion: 3,
      },
    ),
  getVehiclePurpose: () =>
    apiCall('gwApi').get(`order-requests/referentials/vehicle-purposes`, {
      apiVersion: '2',
    }),
  // File (upload & download)
  fileDownload: (productCode, mediumType, paymentType) =>
    apiCall('gwApi').get(
      `order-file?product_ref=${productCode}&medium_type=${mediumType}${
        paymentType ? `&time_unit=${paymentType}` : ''
      }`,
      {
        apiVersion: '2',
        responseType: 'blob',
      },
    ),
  fileUpload: (arrayBuffer, productCode, mediumType, paymentType) =>
    apiCall('gwApi').post(
      `order-file/${productCode}${mediumType ? `?medium_type=${mediumType}` : ''}${
        paymentType ? `&time_unit=${paymentType}` : ''
      }`,
      arrayBufferToBase64Str(arrayBuffer),
      {
        apiVersion: '2',
      },
    ),
};
const notifications = {
  // Big Client e-mail trigger
  bigClient: body => apiCall('gwXp').post('notifications/emails/big-client', body),
  contactUs: body => apiCall('gwXp').post('notifications/emails/contact-us', body),
  orderCompletion: body =>
    apiCall('gwXp').post('notifications/emails/order-completion', body),
};
const leads = {
  // Create a new leads data entity
  createLeads: (companyUuid, body) =>
    apiCall('gwApi').post(`companies/${companyUuid}/lead-data`, body),
  // Update an existing lead data entity
  updateLeads: (companyUuid, leadUuid, body) =>
    apiCall('gwApi').patch(`companies/${companyUuid}/lead-data/${leadUuid}`, body),
};

const payment = {
  // Create payment request
  createPayment: body => apiCall('gwXp').post('payment', body),
};

export {availableInstances, instances, updateHeaders, updateHost};

export default {
  settings,
  translations,
  company,
  pricer,
  order,
  notifications,
  leads,
  payment,
};
