import React, {useState} from 'react';
import {unflatten} from 'flat';

//  Redux
import {connect} from 'react-redux';
import {compose} from 'redux';

// React Intl
import {injectIntl} from 'react-intl';

//  FAQ Components
import HelpButton from './components/HelpButton';
import HelpModal from './components/HelpModal';

const HelpCustomer = ({currentScene, productCode, intl}) => {
  const [modalStatus, setModalStatus] = useState(false);

  const intlTranslations = unflatten(intl.messages);

  if (intlTranslations && productCode && currentScene) {
    const faqTranslations = intlTranslations.faq[productCode];
    const faqItems = faqTranslations[currentScene] || faqTranslations.default;

    const payload = {
      modalTitle: intl.formatMessage({id: 'global.faq_popup_title'}),
      faqItems,
    };

    const handleStatus = () => setModalStatus(!modalStatus);

    return (
      <>
        <HelpButton handleClick={handleStatus} />
        <HelpModal status={modalStatus} payload={payload} handleStatus={handleStatus} />
      </>
    );
  }
  return <></>;
};

const mapStateToProps = state => {
  const {currentLanguage} = state.root.settings;
  const {productCode} = state.featureToggle;

  return {currentLanguage, productCode};
};

export default compose(connect(mapStateToProps), injectIntl)(HelpCustomer);
