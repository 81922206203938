import actions from '../store/actions';
import selectors from '../store/selectors';
import formData from './formDataBenefit';
import sharedData from './sharedDataBenefit';

const flowActions = actions;
const {steps, modalBigClientVariant, employeeListTableData} = sharedData;

const flowRoutes = [
  {
    path: 'contact-info',
    sceneComponentName: 'ContactInfo',
    props: {
      selectors,
      componentActions: actions.contact,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 1,
        nextDisabled: false,
        prevDisabled: true,
        navigationVisibility: true,
        nextScenePath: 'medium-types',
        prevScenePath: null,
      },
      formData: formData.contactInfo,
      sceneTitleImage: 'contact-info/card-ticket-restaurant.jpg',
      modalBigClientVariant,
      hasMarketingCommunicationsCheckbox: true,
    },
  },
  {
    path: 'medium-types',
    sceneComponentName: 'MediumTypes',
    props: {
      selectors,
      defaultMediumType: 'plastic',
      componentActions: {
        ...actions.mediumTypes,
        ...actions.loseData,
      },
      navigation: {
        stepLegendVisibility: true,
        stepActive: 2,
        nextDisabled: true,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'price-simulator',
        prevScenePath: 'contact-info',
      },
    },
  },
  {
    path: 'price-simulator',
    sceneComponentName: 'PriceSimulatorBenefit',
    props: {
      selectors,
      componentActions: {...actions.priceSimulatorBenefit, ...actions.loseData},
      navigation: {
        stepLegendVisibility: true,
        stepActive: 2,
        nextDisabled: false,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'company-info',
        prevScenePath: 'medium-types',
      },
      defaultPaymentType: 'daily',
      paymentTypes: ['daily', 'monthly'],
      formData: formData.priceSimulatorBenefit,
      modalBigClientVariant,
    },
  },
  {
    path: 'company-info',
    sceneComponentName: 'CompanyInfo',
    props: {
      selectors,
      componentActions: actions.company,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 2,
        nextDisabled: false,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'employee-list',
        prevScenePath: 'price-simulator',
      },
      formData: formData.companyInfo,
      locationSelectorData: {
        billingAddress: [
          {
            name: 'city',
            validationType: 'string',
            validationPreset: 'justRequired',
            value: '',
          },
          {
            name: 'district',
            validationType: 'string',
            validationPreset: 'justRequired',
            value: '',
          },
          {
            name: 'neighbourhood',
            validationType: 'string',
            validationPreset: 'justRequired',
            value: '',
          },
        ],
        deliveryAddress: [
          {
            name: 'city',
            validationType: 'string',
            validationPreset: 'justRequired',
          },
          {
            name: 'district',
            validationType: 'string',
            validationPreset: 'justRequired',
          },
          {
            name: 'neighbourhood',
            validationType: 'string',
            validationPreset: 'justRequired',
          },
        ],
      },
    },
  },
  {
    path: 'employee-list',
    sceneComponentName: 'EmployeeList',
    props: {
      selectors,
      componentActions: actions.orderBenefit,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 2,
        nextDisabled: true,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'order-summary',
        prevScenePath: 'company-info',
      },
      allTableData: {
        ...employeeListTableData,
        downloadedFileName: 'employees.xlsx',
      },
      modalBigClientVariant,
    },
  },
  {
    path: 'order-summary',
    sceneComponentName: 'OrderSummaryBenefit',
    props: {
      selectors,
      componentActions: actions.orderBenefit,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 2,
        nextDisabled: false,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'order-payment',
        prevScenePath: 'employee-list',
      },
      formData: {
        rootCollection: [
          {
            id: 'terms',
            name: 'terms',
            value: 'false',
            validationType: 'bool',
            validationPreset: 'checkbox',
          },
        ],
      },
      modalBigClientVariant,
    },
  },
  {
    path: 'order-payment',
    sceneComponentName: 'OrderPayment',
    childrenComponentsName: {
      PaymentSummary: 'SummaryPaymentTableBenefit',
      PaymentMethodsScene: 'PaymentMethodsBenefit',
    },
    props: {
      paymentMethods: ['online-payment', 'bank-transfer'],
      withSubtitle: true,
      selectors,
      componentActions: {
        ...actions.order,
        ...actions.orderBenefit,
      },
      navigation: {
        stepLegendVisibility: true,
        stepActive: 3,
        nextDisabled: true,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'order-status',
        prevScenePath: 'order-summary',
      },
    },
  },
  {
    path: 'order-status',
    sceneComponentName: 'OrderStatus',
    childrenComponentsName: {
      PaymentStatus: 'PaymentStatusBenefit',
    },
    props: {
      selectors,
      componentActions: {
        ...actions.order,
        ...actions.orderBenefit,
      },
      navigation: {
        stepLegendVisibility: false,
        stepActive: 3.5,
        nextDisabled: true,
        prevDisabled: false,
        navigationVisibility: false,
        nextScenePath: '',
        prevScenePath: 'order-payment',
      },
    },
  },
];

// NOTE: Will be used to store different product specific values
const coreSettings = {
  translationIdentifierKey: 'benefit',
  tableType: 'employees',
};

// NOTE: Will be used for any static routes
const staticRoutes = [
  {
    path: 'big-client',
    sceneComponentName: 'BigClient',
  },
  {
    path: 'payment-initiated',
    sceneComponentName: 'PaymentInitiated',
  },
];

const flowPaths = flowRoutes.map(item => item.path);

export {flowActions, flowPaths};

export default {
  steps,
  flowRoutes,
  staticRoutes,
  coreSettings,
};
