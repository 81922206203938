import {convertCurrencyStringToFloat} from 'utils';
import rootActions from 'store/actions';
import orderActions from './order';
import selectors from '../selectors';

export const SET_ORDER_ID = '[TURKEY_PRODUCTS/ORDER_FUEL] SET_ORDER_ID';
export const SET_ORDER_DATA = '[TURKEY_PRODUCTS/ORDER_FUEL] SET_ORDER_DATA';
export const SET_ORDER_WILLING_TO_PAY =
  '[TURKEY_PRODUCTS/ORDER_FUEL] SET_ORDER_WILLING_TO_PAY';

const setOrderId = value => ({
  type: SET_ORDER_ID,
  value,
});

const setOrderData = value => ({
  type: SET_ORDER_DATA,
  value,
});

const setOrderWillingToPay = payload => (dispatch, getState) => {
  // Selectors
  const {selectTenantCurrency, selectTenantCurrencyMaskSettings} = selectors;
  const currency = selectTenantCurrency(getState());
  const currencySettings = selectTenantCurrencyMaskSettings(getState());

  const value = convertCurrencyStringToFloat(payload, currency, currencySettings);

  dispatch({
    type: SET_ORDER_WILLING_TO_PAY,
    value,
  });
};

const fetchLookups = columnsLookups => (dispatch, getState) => {
  // Selectors
  const {selectProductCode} = selectors;
  const productCode = selectProductCode(getState());

  const parsedLookups = {};

  return Promise.all([
    dispatch(rootActions.api.makeRequest('order/getVehicleType')),
    dispatch(rootActions.api.makeRequest('order/getMediumTypes', [productCode])),
    dispatch(rootActions.api.makeRequest('order/getVehiclePurpose')),
  ]).then(responses => {
    const responsesData = responses.map(r => r.data.data);

    responsesData.map((response, key) => {
      parsedLookups[columnsLookups[key]] = [];

      return response.forEach(r =>
        parsedLookups[columnsLookups[key]].push({
          id: r.code,
          // Enums mapping (to do)
          name: r.code.replaceAll('_', '-'),
        }),
      );
    });

    return parsedLookups;
  });
};

const orderData = payload => async (dispatch, getState) => {
  const {orderFuel, priceSimulatorFuel} = getState();
  const {orderId} = orderFuel;
  const avgFuelConsumption = priceSimulatorFuel.average_fuel_consumption;
  const commissionPercent = priceSimulatorFuel.commission / 100;

  // Financial info calculations: -------------------------
  const numberVehicles = payload.length;
  // This value represents number of vehicles from payload * average fuel consumption for vehicle calculated in previous steps
  const totalAverageFuelConsumption = numberVehicles * avgFuelConsumption;
  // This value represents totalAverageFuelConsumption * (commission reduction from 100%)
  const totalToPay = totalAverageFuelConsumption * (1 - commissionPercent);
  // -----------------------------------------------------

  // Selectors
  const {selectOrderPayload} = selectors;
  const {orderState, orderRequest} = selectOrderPayload(getState(), payload);

  // State Payload ---------------------------------------
  const orderStatePayload = {
    ...orderState,
    ...{
      totalToPay,
      totalAverageFuelConsumption,
    },
  };
  // -----------------------------------------------------

  // Backend Payload -------------------------------------
  const orderRequestPayload = {
    ...orderRequest,
    ...{
      total_to_pay: totalToPay,
      total_loaded_value: totalAverageFuelConsumption,
    },
  };
  // -----------------------------------------------------

  // Update state and backend request --------------------
  dispatch(setOrderData(orderStatePayload));

  return dispatch(orderActions.serverOrderData(orderRequestPayload, orderId)).then(
    response => response?.hasCreatedOrder && dispatch(setOrderId(response.orderId)),
  );
  // -----------------------------------------------------
};

const orderDownload = () => dispatch => dispatch(orderActions.serverFileDownload());

const orderUpload = (payload, lookups) => dispatch => {
  const columnLookups = Object.keys(lookups);

  return dispatch(orderActions.serverFileUpload(payload)).then(fileUploadResponse => {
    const parsedResponse = [];

    fileUploadResponse.forEach(row => {
      const parsedRow = {};

      Object.keys(row).map(objectKey => {
        // Enums mapping (to do)
        const replaceString = str => str.replace('_', '-');
        const parsedKey = replaceString(objectKey);
        // For values, parse only lookup values
        const isLookupValue = columnLookups.indexOf(parsedKey) >= 0;

        return (parsedRow[parsedKey] = isLookupValue
          ? replaceString(row[objectKey])
          : row[objectKey]);
      });

      parsedResponse.push(parsedRow);
    });

    return parsedResponse;
  });
};

const orderSubmit = payload => async (dispatch, getState) => {
  const {orderFuel, company} = getState();
  const {orderId} = orderFuel;
  const {companyUuid} = company;
  // To check
  const totalToPay = orderFuel.orderData.total_to_pay;

  // Selectors
  const {selectProductCode} = selectors;
  const productCode = selectProductCode(getState());

  const requestPayload = {
    // On visitor flow client_id is equal with company uuid
    client_id: companyUuid,
    product_ref: productCode,
    total_to_pay: totalToPay,
    provision_limit: payload,
  };

  // Update order
  await dispatch(
    rootActions.api.makeRequest('order/updateOrder', [orderId, requestPayload]),
  );

  // Update Order willing to pay
  dispatch(setOrderWillingToPay(payload));

  return dispatch(orderActions.serverOrderSubmit(orderId));
};

const createPayment = () => (dispatch, getState) => {
  const {orderFuel, consents} = getState();
  const {orderId} = orderFuel;
  const {acceptedAutoPaymentWithCardSave} = consents;

  return dispatch(
    orderActions.serverCreatePayment(orderId, {
      store_card: acceptedAutoPaymentWithCardSave,
      recurring_payment: acceptedAutoPaymentWithCardSave,
    }),
  );
};

const orderCompletion = payload => (dispatch, getState) => {
  const {status} = payload;

  const {orderFuel, consents} = getState();
  const {orderId} = orderFuel;
  const {acceptedAutoPaymentWithCardSave} = consents;

  return dispatch(
    orderActions.serverOrderCompletion(orderId, status, {
      store_card: acceptedAutoPaymentWithCardSave,
      recurring_payment: acceptedAutoPaymentWithCardSave,
    }),
  );
};

const orderFinished = payload => (dispatch, getState) => {
  const {orderFuel} = getState();
  const {orderId} = orderFuel;

  return Promise.all([
    dispatch(orderCompletion(payload)),
    dispatch(orderActions.serverPaymentStatus(orderId, payload)),
  ]);
};

export default {
  setOrderId,
  orderData,
  orderDownload,
  orderUpload,
  orderSubmit,
  fetchLookups,
  setOrderWillingToPay,
  createPayment,
  orderCompletion,
  orderFinished,
};
