import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';

// Mui components
import {withStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

//  Redux
import {uiActions, settingsActions} from 'store/actions';

// Styles
const useStyles = () => ({
  selectInput: {
    '& > .MuiSelect-select:focus': {
      backgroundColor: 'transparent',
    },
    height: '3.25rem',
  },
});

const LanguageSelector = props => {
  const {language, languages, translations, setCurrentLanguage, classes} = props;
  const propsAvailable = language && languages && translations;
  const handleChange = (event, lang) => {
    const {value} = event.target;

    if (value !== lang) {
      setCurrentLanguage(value);
    }
  };

  return (
    <>
      {propsAvailable && (
        <FormControl>
          <Select
            variant='outlined'
            value={language}
            onChange={event => handleChange(event, language)}
            className={classes.selectInput}
          >
            {languages.map(lang => (
              <MenuItem key={lang} value={lang}>
                {lang.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch => {
  const {changeLanguage} = settingsActions;
  const {loaderShow, loaderHide, errorShow} = uiActions;

  return {
    setCurrentLanguage: lang => {
      dispatch(loaderShow());
      dispatch(changeLanguage(lang))
        .then(() => dispatch(loaderHide()))
        .catch(() => dispatch(errorShow('APP_LANG_UPDATE_FAILED')));
    },
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(useStyles),
)(LanguageSelector);
