/* eslint-disable no-case-declarations */
import {
  LOADER_SHOW,
  LOADER_HIDE,
  PROGRESS_SHOW,
  PROGRESS_HIDE,
  ERROR_HIDE,
  ERROR_SHOW,
} from '../actions/ui';
import initialStates from './initialStates';

const initialState = initialStates.ui;

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADER_SHOW:
      return {
        ...state,
        isLoading: true,
      };

    case LOADER_HIDE:
      return {
        ...state,
        isLoading: initialState.isLoading,
      };

    case PROGRESS_SHOW:
      return {
        ...state,
        isProgress: true,
      };

    case PROGRESS_HIDE:
      return {
        ...state,
        isProgress: initialState.isProgress,
      };

    case ERROR_SHOW:
      const {error, errorNeverHide, errorCloseDisabled} = action;
      return {
        ...state,
        error,
        errorNeverHide: errorNeverHide || initialState.errorNeverHide,
        errorCloseDisabled: errorCloseDisabled || initialState.errorCloseDisabled,
      };

    case ERROR_HIDE:
      return {
        ...state,
        error: initialState.error,
      };

    default:
      return state;
  }
};
