/* eslint-disable max-len */
import flowActions from './flow';
import companyActions from './company';
import orderBenefitActions from './orderBenefit';

const companyInfoScene = 'company-info';
const employeeListScene = 'employee-list';

// Checks
const checkMediumTypes = () => async (dispatch, getState) => {
  const {checkFlowDepth} = flowActions;

  const {company, mediumTypes, orderBenefit} = getState();
  const {deliveryAddressCollapsed, orderPickerCollapsed} = company;
  const {selectedMediumType} = mediumTypes;
  const {isDirty} = orderBenefit;

  // Scene check
  const sceneReachment = {};
  const scenes = [companyInfoScene, employeeListScene];

  // Check scenes reachment
  await scenes.map(scene =>
    dispatch(checkFlowDepth(scene)).then(isReached => {
      sceneReachment[scene] = isReached;
    }),
  );

  // Scene custom lose data logic
  /*
  CompanyInfo: if user's current medium type selection is plastic and tries to change it to another medium type, and have filled data in delivery address or order picker, inform the user that he will lose the data.
   */
  const companyInfo =
    sceneReachment[companyInfoScene] &&
    selectedMediumType === 'plastic' &&
    (deliveryAddressCollapsed || orderPickerCollapsed) &&
    companyInfoScene; // return scene route

  /*
  EmployeeList: if user have reached employee list scene and table is dirty, inform the user that he will lose the data.
   */
  const employeeList = sceneReachment[employeeListScene] && isDirty && employeeListScene;

  // CompanyInfo and EmployeeList (both)
  const companyInfoAndEmployeeList =
    companyInfo && employeeList && `${companyInfoScene}_and_${employeeListScene}`;

  return companyInfoAndEmployeeList || companyInfo || employeeList;
};
const checkPriceSimulatorBenefit = () => (dispatch, getState) => {
  const {checkFlowDepth} = flowActions;
  const {orderBenefit} = getState();
  const {isDirty} = orderBenefit;

  return dispatch(checkFlowDepth(employeeListScene)).then(
    isReached => isDirty && isReached,
  );
};

// Confirmation cleanups
const confirmMediumTypes = loseDataIdentifier => dispatch => {
  function companyInfoClear() {
    dispatch(companyActions.setDeliveryAddressCollapsed(false));
    dispatch(companyActions.setOrderPickerCollapsed(false));
  }

  function employeeListClear() {
    dispatch(orderBenefitActions.clearOrderWithoutId());
  }

  switch (loseDataIdentifier) {
    case 'company-info':
      companyInfoClear();
      break;

    case 'employee-list':
      employeeListClear();
      break;

    case 'company-info_and_employee-list':
      companyInfoClear();
      employeeListClear();
      break;

    default:
      break;
  }
};
const confirmPriceSimulatorBenefit = () => dispatch =>
  dispatch(orderBenefitActions.clearOrderWithoutId());

const loseDataCheck = sceneIdentifier => {
  switch (sceneIdentifier) {
    case 'mediumTypes':
      return checkMediumTypes();

    case 'priceSimulatorBenefit':
      return checkPriceSimulatorBenefit();

    default:
      return false;
  }
};
const loseDataConfirm = (sceneIdentifier, loseDataIdentifier) => {
  switch (sceneIdentifier) {
    case 'mediumTypes':
      return confirmMediumTypes(loseDataIdentifier);

    case 'priceSimulatorBenefit':
      return confirmPriceSimulatorBenefit(loseDataIdentifier);

    default:
      return false;
  }
};

export default {
  loseDataCheck,
  loseDataConfirm,
};
