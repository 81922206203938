/* eslint-disable camelcase */
import {useStoreMock} from 'config';
import {formDataParser} from 'helpers';
import merge from 'deepmerge';
import rootActions from 'store/actions';
import fieldSettings from 'validations/fieldSettings';
import selectors from '../selectors';

export const SET_COMPANY_UUID = '[TURKEY_PRODUCTS/COMPANY] SET_COMPANY_UUID';
export const SET_COMPANY_ID = '[TURKEY_PRODUCTS/COMPANY] SET_COMPANY_ID';
export const SET_FORM_IS_DIRTY = '[TURKEY_PRODUCTS/COMPANY] SET_FORM_IS_DIRTY';
export const SET_E_INVOICE_PAY_COLLAPSED =
  '[TURKEY_PRODUCTS/COMPANY] SET_E_INVOICE_PAY_COLLAPSED';
export const SET_DELIVERY_ADDRESS_COLLAPSED =
  '[TURKEY_PRODUCTS/COMPANY] SET_DELIVERY_ADDRESS_COLLAPSED';
export const SET_ORDER_PICKER_COLLAPSED =
  '[TURKEY_PRODUCTS/COMPANY] SET_ORDER_PICKER_COLLAPSED';
export const CLEAR_ORDER_PICKER = '[TURKEY_PRODUCTS/COMPANY] CLEAR_ORDER_PICKER';
export const UPDATE_SERVER_FORM_DATA =
  '[TURKEY_PRODUCTS/COMPANY] UPDATE_SERVER_FORM_DATA';

const setCompanyUuid = value => ({
  type: SET_COMPANY_UUID,
  value,
});

const setCompanyId = value => ({
  type: SET_COMPANY_ID,
  value,
});

const setFormIsDirty = value => ({
  type: SET_FORM_IS_DIRTY,
  value,
});

const setEInvoicePayCollapsed = value => ({
  type: SET_E_INVOICE_PAY_COLLAPSED,
  value,
});

const setDeliveryAddressCollapsed = value => ({
  type: SET_DELIVERY_ADDRESS_COLLAPSED,
  value,
});

const setOrderPickerCollapsed = value => ({
  type: SET_ORDER_PICKER_COLLAPSED,
  value,
});

const clearOrderPicker = () => ({
  type: CLEAR_ORDER_PICKER,
});

const updateServerData = payload => ({
  type: UPDATE_SERVER_FORM_DATA,
  payload,
});

const fetchCompanyUuid = () => async (dispatch, getState) => {
  const {companyUuid} = getState().company;

  // Create company and retrieve it's uuid
  if (!companyUuid) {
    await dispatch(rootActions.api.makeRequest('company/create')).then(response =>
      dispatch(setCompanyUuid(response.data.data)),
    );
  }
};

const fetchCompanyId = () => async (dispatch, getState) => {
  const statesData = {
    companyUuid: () => getState().company.companyUuid,
    companyId: () => getState().company.companyId,
  };

  if (!statesData.companyId()) {
    await dispatch(
      rootActions.api.makeRequest('company/createCompany', [statesData.companyUuid()]),
    ).then(response => dispatch(setCompanyId(response.data.data)));
  }
};

const fetchCities = () => dispatch =>
  dispatch(rootActions.api.makeRequest('company/getCities')).then(
    response => response.data.data,
  );

const fetchDistricts = cityId => dispatch =>
  dispatch(rootActions.api.makeRequest('company/getDistricts', [cityId])).then(
    response => response.data.data,
  );

const fetchNeighbourhoods = districtId => dispatch =>
  dispatch(rootActions.api.makeRequest('company/getNeighbourhoods', [districtId])).then(
    response => response.data.data,
  );

const serverCompanyPartialData = payload => (dispatch, getState) => {
  const statesData = {
    companyUuid: () => getState().company.companyUuid,
    companyId: () => getState().company.companyId,
  };

  const requestPayload = {
    company_name: payload.company_name,
  };

  return dispatch(
    rootActions.api.makeRequest('company/createCompany', [
      statesData.companyUuid(),
      requestPayload,
      true,
    ]),
  ).then(response => dispatch(setCompanyId(response.data.data)));
};

const serverFormData = ({
  formData,
  locationSelectorData,
  eInvoicePayCollapsed,
  deliveryAddressCollapsed,
  orderPickerCollapsed,
}) => async (dispatch, getState) => {
  const {formIsDirty} = getState().company;

  // Selectors
  const {checkHasDeliveryAddress, checkHasOrderPicker} = selectors;
  const hasDeliveryAddress = checkHasDeliveryAddress(getState());
  const hasOrderPicker = checkHasOrderPicker(getState());

  if (!formIsDirty) {
    dispatch(setFormIsDirty(true));
  }

  // Parse data
  const parsedFormData = {
    ...formDataParser(formData.rootCollection),
    billing_address: formDataParser(formData.billingAddress),
    delivery_address: formDataParser(formData.deliveryAddress),
    order_picker: hasOrderPicker ? formDataParser(formData.orderPickerCollection) : {},
    e_invoice_pay: formDataParser(formData.eInvoicePayCollection),
  };

  const parsedLocationSelectorData = {
    billing_address: formDataParser(locationSelectorData.billingAddress),
    delivery_address: formDataParser(locationSelectorData.deliveryAddress),
  };

  // State data
  const stateData = merge(parsedFormData, parsedLocationSelectorData);

  dispatch(updateServerData(stateData));

  // Parse location selector data
  Object.keys(parsedLocationSelectorData).forEach(x => {
    const locationGroup = parsedLocationSelectorData[x];
    Object.keys(locationGroup).forEach(y => {
      locationGroup[`${y}_id`] = locationGroup[y].id || '';
      delete locationGroup[y];
    });
  });

  const requestPayload = merge(parsedFormData, parsedLocationSelectorData);

  // E-Invoice
  if (eInvoicePayCollapsed) {
    requestPayload.e_archive_email = requestPayload.e_invoice_pay.e_archive_email;
  }

  // Delivery Address
  // If delivery address isn't provided use billing address (backend payload required logic)
  if (!deliveryAddressCollapsed) {
    requestPayload.delivery_address = requestPayload.billing_address;
  }

  // If scene don't have enabled delivery address clean the payload
  if (!hasDeliveryAddress) {
    delete requestPayload.delivery_address;
  }

  // Order Picker
  if (hasOrderPicker && orderPickerCollapsed) {
    const {order_picker} = parsedFormData;

    requestPayload.delivery_address = {
      ...requestPayload.delivery_address,
      contact: {
        ...order_picker,
        contact_type: 'secondary',
      },
    };
  }

  // Cleanup
  delete requestPayload.order_picker;
  delete requestPayload.e_invoice_pay;

  const statesData = {
    companyUuid: () => getState().company.companyUuid,
    companyId: () => getState().company.companyId,
    tenant: () => getState().root.settings.tenant,
  };

  // Break fiscal_or_vat_number into fiscal_number & vat_number
  const fiscalOrVatFieldName = 'fiscal-or-vat-number';
  const {MIN: fiscalOrVatMIN, MAX: fiscalOrVatMAX} = fieldSettings[statesData.tenant()][
    fiscalOrVatFieldName
  ];

  switch (requestPayload.fiscal_or_vat_number.length) {
    case fiscalOrVatMIN:
      requestPayload.vat_number = requestPayload.fiscal_or_vat_number;
      requestPayload.fiscal_number = '';
      break;

    case fiscalOrVatMAX:
      requestPayload.vat_number = '';
      requestPayload.fiscal_number = requestPayload.fiscal_or_vat_number;
      break;

    default:
      break;
  }

  delete requestPayload.fiscal_or_vat_number;

  // Update contact
  await dispatch(
    rootActions.api.makeRequest('company/updateCompany', [
      statesData.companyUuid(),
      statesData.companyId(),
      requestPayload,
    ]),
  );
};

const checkCompanyUnicity = () => (dispatch, getState) => {
  const {root, company} = getState();
  const {settings} = root;
  const {tenant} = settings;
  const {companyUuid, serverFormData: companyData} = company;
  // Selectors
  const {selectProductCode} = selectors;
  const productCode = selectProductCode(getState());

  // Break fiscalOrVatNumber into fiscalNumber & vatNumber
  const fiscalOrVatFieldName = 'fiscal-or-vat-number';
  const {MIN: fiscalOrVatMIN, MAX: fiscalOrVatMAX} = fieldSettings[tenant][
    fiscalOrVatFieldName
  ];

  const fiscalOrVatNumber = companyData.fiscal_or_vat_number;

  const fiscalNumber =
    fiscalOrVatNumber.length === fiscalOrVatMAX ? fiscalOrVatNumber : undefined;
  const vatNumber =
    fiscalOrVatNumber.length === fiscalOrVatMIN ? fiscalOrVatNumber : undefined;

  return dispatch(
    rootActions.api.makeRequest('company/unicityCheck', [
      companyUuid,
      productCode,
      fiscalNumber,
      vatNumber,
    ]),
  ).then(response => {
    const {is_present_in_bko, has_contract_for_current_product} = response.data.data;

    const companyUnicityFlag = !(is_present_in_bko && has_contract_for_current_product);

    // If it's used store mock, the company fiscal code will be to be unique - avoid this for development purposes
    return !useStoreMock ? companyUnicityFlag : true;
  });
};

export default {
  setEInvoicePayCollapsed,
  setDeliveryAddressCollapsed,
  setOrderPickerCollapsed,
  clearOrderPicker,
  fetchCompanyUuid,
  fetchCompanyId,
  fetchCities,
  fetchDistricts,
  fetchNeighbourhoods,
  serverCompanyPartialData,
  serverFormData,
  checkCompanyUnicity,
};
