import config from 'config';
import {decimalNumber, thousandSeparatorParser, currencyFormatParser} from 'utils';

export default (value, tenant, currency, disabledDecimals) => {
  const {currencyFormat, tenantCurrencyMaskSettings} = config[tenant];
  const {thousandSeparator, decimalScale, decimalSeparator} = tenantCurrencyMaskSettings;

  // Decimals
  const decimals = disabledDecimals ? 0 : decimalScale;
  value = decimalNumber(value, decimals);

  // Decimal separator
  if (decimalSeparator) {
    value = value.replace('.', decimalSeparator);
  }

  // Thousand separator
  if (thousandSeparator) {
    value = thousandSeparatorParser(value, thousandSeparator);
  }

  // Currency format
  value = currencyFormatParser(value, currency, currencyFormat);

  return value;
};
