import React from 'react';

// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

// Components - local
import Loader from '.';

// Styles
const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.loaderOverlay,
    backgroundColor: theme.palette.common.white,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} transitionDuration={0} open>
      <Loader large />
    </Backdrop>
  );
};
