import React from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
// Components - global
import Image from 'components/Image';
import SceneTitle from 'components/Scene/Title';
// Components - global
import {FormattedMessage} from 'react-intl';
import ButtonCrossSell from './ButtonCrossSell';
// React Intl

// Styles
const useStyles = makeStyles(() => ({
  image: {
    maxWidth: 260,
    margin: '0 auto',
  },
}));

export default ({translationIdentifierPrefix, tenant}) => (
  <>
    <Box mt={2}>
      <SceneTitle
        onlySubtitle
        withSubtitle
        translationIdentifierPrefix={`${translationIdentifierPrefix}_cross-sell`}
        subtitleVariant='h2'
      />

      <Image
        additionalClasses={useStyles().image}
        image={`${process.env.PUBLIC_URL}/images/${tenant}/order-status/ticket-car-cross-sell.png`}
      />

      <Box my={1}>
        <SceneTitle
          onlySubtitle
          withSubtitle
          translationIdentifierPrefix={`${translationIdentifierPrefix}_cross-sell`}
          translationKeySubtitle='secondary'
          subtitleVariant='h3'
        />

        <FormattedMessage id={`${translationIdentifierPrefix}_cross-sell_text`} />
      </Box>

      <ButtonCrossSell translationIdentifierPrefix={translationIdentifierPrefix} />
    </Box>
  </>
);
