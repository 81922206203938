import rootActions from 'store/actions';

export const SET_ACCEPTED_GDPR = '[TURKEY_PRODUCTS/CONSENTS] SET_ACCEPTED_GDPR';
export const SET_ACCEPTED_COMMERCIALS =
  '[TURKEY_PRODUCTS/CONSENTS] SET_ACCEPTED_COMMERCIALS';
export const SET_ACCEPTED_MARKETING = '[TURKEY_PRODUCTS/CONSENTS] SET_ACCEPTED_MARKETING';
export const SET_ACCEPTED_TERMS = '[TURKEY_PRODUCTS/CONSENTS] SET_ACCEPTED_TERMS';
export const SET_ACCEPTED_AUTO_PAYMENT_WITH_CARD_SAVE =
  '[TURKEY_PRODUCTS/CONSENTS] SET_ACCEPTED_AUTO_PAYMENT_WITH_CARD_SAVE';

const setAcceptedGdpr = payload => ({
  type: SET_ACCEPTED_GDPR,
  payload,
});

const setAcceptedCommercials = payload => ({
  type: SET_ACCEPTED_COMMERCIALS,
  payload,
});

const setAcceptedMarketing = payload => ({
  type: SET_ACCEPTED_MARKETING,
  payload,
});

// Update company consents
const updateConsents = payload => (dispatch, getState) => {
  const statesData = {
    companyUuid: () => getState().company.companyUuid,
    productCode: () => getState().featureToggle.productCode,
    tenant: () => getState().root.settings.tenant,
  };

  // If one of recurrent_payment or terms_conditions is present, BE requires product_code
  payload.forEach(item => {
    if (item.code === 'recurrent_payment' || item.code === 'terms_conditions') {
      Object.assign(item, {product_code: statesData.productCode()});
    }

    item.code += `_${statesData.tenant()}`;
  });

  return dispatch(
    rootActions.api.makeRequest('company/updateConsents', [
      statesData.companyUuid(),
      payload,
    ]),
  ).then(response => response);
};

const setAcceptedTerms = value => ({
  type: SET_ACCEPTED_TERMS,
  value,
});

const setAcceptedAutoPaymentWithCardSave = value => ({
  type: SET_ACCEPTED_AUTO_PAYMENT_WITH_CARD_SAVE,
  value,
});

export default {
  updateConsents,
  setAcceptedGdpr,
  setAcceptedCommercials,
  setAcceptedMarketing,
  setAcceptedTerms,
  setAcceptedAutoPaymentWithCardSave,
};
