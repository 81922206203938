import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {tenants} from 'config';
// Mui components
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import Divider from '@material-ui/core/Divider';

export default props => {
  const {navLinkClass, tenant} = props;
  const {featureToggle, basePath, vendors} = tenants[tenant];

  const url = (id, name) => {
    const {hasBasePath} = featureToggle;
    return `/${tenant}/${
      hasBasePath ? `${basePath}/` : ''
    }banner/?productCode=${id}&product=${encodeURI(name)}&productType=${encodeURI(
      name,
    )}&utmCampaign=campaign&utmMedium=medium&utmSource=source&utmContent=Test%20content&leadSource=lead&subsource=${encodeURI(
      'Online Sales',
    )}&subSourceDetail=subsource&firstName=Denzel&lastName=Washington&email=denzel@wton.com&company=SRL&phone=2123456789&mobile=5123456789`;
  };

  return (
    <>
      <ListItem dense disabled>
        <ListItemText
          primary={
            <>
              Scenario: <strong>TurkeyProducts</strong>
            </>
          }
        />
      </ListItem>

      {vendors.map((v, key) => {
        const {id, commercialName, type} = v;

        const listItemPrimary = (
          <>
            Product type: <strong>{type.toUpperCase()}</strong>
          </>
        );
        const listItemSecondary = (
          <>
            {commercialName && (
              <>
                Commercial name: <strong>{commercialName}</strong>
                <br />
                Product code: <strong>{id}</strong>
              </>
            )}
          </>
        );

        return (
          <Fragment key={key}>
            <NavLink to={url(id, commercialName)} className={navLinkClass}>
              <ListItem button>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={listItemPrimary} secondary={listItemSecondary} />
              </ListItem>
            </NavLink>
          </Fragment>
        );
      })}

      <Divider />
    </>
  );
};
