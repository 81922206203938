import React from 'react';

// Mui components
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {IntlProvider, FormattedMessage} from 'react-intl';
import example from 'translations/example.json';

export default () => {
  const BlockElement = ({title, children}) => (
    <>
      <Box my={1}>
        <Typography variant='h5' color='secondary'>
          {title}:
        </Typography>
        <Typography variant='body2'>
          <u>{children}</u>
        </Typography>
      </Box>
    </>
  );

  return (
    <IntlProvider locale='en' messages={example}>
      <BlockElement title="Translate using FormattedMessage from 'react-intl'">
        <FormattedMessage
          id='app.lib_loaded'
          description='react-int loaded'
          defaultMessage='react-int loaded'
        />
      </BlockElement>

      <BlockElement title='Translate with variables'>
        <FormattedMessage
          id='app.greeting'
          description='Greeting to welcome the user to the app'
          defaultMessage='Hello, {visitor}!'
          values={{
            visitor: 'Visitor',
          }}
        />
      </BlockElement>

      <BlockElement title='Translate with Rich text'>
        <FormattedMessage
          id='app.greeting_rich'
          description='Greeting to welcome the user to the app'
          defaultMessage='Hello, <b>Visitor</b> from <i>Italy</i>'
          values={{
            b: (...chunks) => <b>{chunks}</b>,
            i: (...chunks) => <i>{chunks}</i>,
          }}
        />
      </BlockElement>

      <BlockElement title='Translate with anchor link'>
        <FormattedMessage
          id='app.greeting_link'
          defaultMessage='To buy a shoe, <a>visit our website</a> and <cta>buy a shoe</cta>'
          values={{
            a: (...chunks) => (
              <a target='_blank' rel='noopener noreferrer' href='https://www.shoe.com/'>
                {chunks}
              </a>
            ),
            cta: (...chunks) => <strong>{chunks}</strong>,
          }}
        />
      </BlockElement>
    </IntlProvider>
  );
};
