import React from 'react';
// Mui components
import Typography from '@material-ui/core/Typography';

export default () => (
  <>
    <Typography variant='h1' align='center'>
      404
    </Typography>
    <Typography variant='h3' align='center'>
      Page not found
    </Typography>
  </>
);
