export const LOADER_SHOW = '[ROOT/UI] LOADER_SHOW';
export const LOADER_HIDE = '[ROOT/UI] LOADER_HIDE';
export const PROGRESS_SHOW = '[ROOT/UI] PROGRESS_SHOW';
export const PROGRESS_HIDE = '[ROOT/UI] PROGRESS_HIDE';
export const ERROR_SHOW = '[ROOT/UI] ERROR_SHOW';
export const ERROR_HIDE = '[ROOT/UI] ERROR_HIDE';

const loaderShow = () => ({
  type: LOADER_SHOW,
});

const loaderHide = () => ({
  type: LOADER_HIDE,
});

const progressShow = () => ({
  type: PROGRESS_SHOW,
});

const progressHide = () => ({
  type: PROGRESS_HIDE,
});

const errorShow = (error, errorNeverHide, errorCloseDisabled) => {
  return {
    type: ERROR_SHOW,
    error,
    errorNeverHide,
    errorCloseDisabled,
  };
};

const errorHide = () => ({
  type: ERROR_HIDE,
});

export default {
  loaderShow,
  loaderHide,
  progressShow,
  progressHide,
  errorShow,
  errorHide,
};
