import createYupValidation from './createYupValidation';

export default (schema, config) => {
  const {name, validationType, validations} = config;
  const validator = createYupValidation(validationType, validations);

  if (validator) {
    schema[name] = validator;
  }

  return schema;
};
