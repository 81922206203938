import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
// Containers
import AppContainers from 'containers/AppContainers';
import {
  availableTenants,
  useTenantDomainExtension,
  readTenant,
  tenants,
  appVersion,
} from 'config';
import Scenarios from 'scenarios';
import themes from 'themes';
import AppTheme from 'components/AppTheme';
import AppScenario from 'components/AppScenario';
import 'App.css';

const availableTenant = useTenantDomainExtension ? [readTenant] : availableTenants;

// Console browser output
console.log(
  '%c© Edenred Online Sales',
  'font-size:12px;font-weight:bold;background-color:#F72717;color:white;padding:5px 10px;opacity',
  appVersion,
);

const getRouteBase = tenant => `/${useTenantDomainExtension ? '' : `${tenant}/`}`;

export default () => (
  <div className='App'>
    <AppContainers>
      <BrowserRouter>
        <Switch>
          <Switch>
            {/* Routes with banner redirect */}
            {availableTenant.map((tenant, key) => {
              const {theme, featureToggle, basePath, productPaths, vendors} = tenants[
                tenant
              ];
              const currentTheme = themes[theme];
              const {
                hasBannerRedirect,
                hasBasePath,
                hasRequiredParamsRedirect,
              } = featureToggle;
              const enabledBannerRedirect =
                hasBannerRedirect && hasBannerRedirect.isActive;
              const path = getRouteBase(tenant) + (hasBasePath ? `${basePath}/` : '');

              if (!enabledBannerRedirect) return null;

              return (
                <Route
                  exact
                  path={`${path}banner`}
                  key={key}
                  render={props => (
                    <AppTheme theme={currentTheme}>
                      <Scenarios.BannerRedirect
                        {...props}
                        storageItemName={hasBannerRedirect.storageItemName}
                        path={path}
                        vendors={vendors}
                        productPaths={productPaths}
                        hasRequiredParamsRedirect={hasRequiredParamsRedirect}
                      />
                    </AppTheme>
                  )}
                />
              );
            })}

            {availableTenant.map((tenant, key) => {
              const {featureToggle, basePath, productPaths} = tenants[tenant];
              const {hasBasePath, hasProductPaths} = featureToggle;
              const path = getRouteBase(tenant);

              // Routes with product paths
              if (hasProductPaths) {
                return Object.values(productPaths).map((productPath, index) => (
                  <Route key={index} path={path + productPath}>
                    <AppScenario tenant={tenant} />
                  </Route>
                ));
              }

              return (
                <Route key={key} path={path + (hasBasePath ? `${basePath}/` : '')}>
                  <AppScenario tenant={tenant} />
                </Route>
              );
            })}

            {!useTenantDomainExtension && (
              <Route>
                <AppTheme theme={themes.base}>
                  <Scenarios.TenantSwitcher />
                </AppTheme>
              </Route>
            )}
          </Switch>
        </Switch>
      </BrowserRouter>
    </AppContainers>
  </div>
);
