import base from './base';
import red from './red';
import green from './green';

const themes = {
  base,
  red,
  green,
};

export default themes;
