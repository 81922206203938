// Remove undefined properties from object
export default (obj, property = undefined) => {
  const hasMultipleProperties = Array.isArray(property);

  return Object.keys(obj).forEach(key => {
    const propertyCheck = hasMultipleProperties
      ? property.includes(obj[key])
      : obj[key] === property;

    return propertyCheck && delete obj[key];
  });
};
