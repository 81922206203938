// Default shared form data collections between both Visitor flows: Fuel/Benefit

export default {
  contactInfo: {
    rootCollection: [
      {
        id: 'contact_first_name',
        name: 'contact-first-name',
        position: 1,
        value: '',
        validationPreset: 'personName',
      },
      {
        id: 'contact_last_name',
        name: 'contact-last-name',
        position: 2,
        placeholder: 'Doe',
        value: '',
        validationPreset: 'personName',
      },
      {
        id: 'company_name',
        name: 'company-name',
        position: 3,
        value: '',
        validationPreset: 'companyName',
      },
      {
        id: 'mobile_phone',
        name: 'mobile-phone',
        position: 4,
        value: '',
        validationPreset: 'phoneNumbersGroup',
        validationOptions: {
          noSortEdges: true,
          currentValidationPreset: 'phoneNumberMobile',
          otherField: 'contact-phone',
        },
        mask: {
          hasNumberPositiveInteger: true,
        },
      },
      {
        id: 'contact_phone',
        name: 'contact-phone',
        position: 5,
        value: '',
        validationPreset: 'phoneNumbersGroup',
        validationOptions: {
          noSortEdges: true,
          currentValidationPreset: 'phoneNumberFlat',
          otherField: 'mobile-phone',
        },
        mask: {
          hasNumberPositiveInteger: true,
        },
      },
      {
        id: 'contact_email',
        name: 'contact-email',
        position: 6,
        value: '',
        validationPreset: 'email',
      },
      {
        id: 'contact_email_confirm',
        name: 'contact-email-confirm',
        position: 7,
        value: '',
        validationPreset: 'emailConfirm',
        pasteDisabled: true,
      },
      {
        id: 'gdpr',
        name: 'gdpr',
        position: 9,
        value: 'false',
        validationType: 'bool',
        validationPreset: 'checkbox',
      },
      {
        id: 'commercial_communications',
        name: 'commercial-communications',
        position: 10,
        value: 'false',
        validationType: 'bool',
        // validationPreset: 'checkbox',
      },
    ],
  },
  companyInfo: {
    rootCollection: [
      {
        id: 'company_name',
        name: 'company-name',
        position: 1,
        value: '',
        validationPreset: 'companyName',
        removed: true,
      },
      {
        id: 'fiscal_or_vat_number',
        name: 'fiscal-or-vat-number',
        position: 2,
        value: '',
        validationType: 'string',
        validationPreset: 'fiscalOrVatNumber',
        mask: {
          hasNumberPositiveInteger: true,
        },
        withTooltip: true,
      },
      {
        id: 'tax_office',
        name: 'tax-office',
        position: 3,
        value: '',
        validationType: 'string',
        validationPreset: 'taxOffice',
        withTooltip: true,
      },
      {
        id: 'mobile_phone_number',
        name: 'mobile-phone',
        position: 4,
        validationPreset: 'phoneNumbersGroup',
        validationOptions: {
          noSortEdges: true,
          currentValidationPreset: 'phoneNumberMobile',
          otherField: 'contact-phone',
        },
        mask: {
          hasNumberPositiveInteger: true,
        },
      },
      {
        id: 'phone_number',
        name: 'contact-phone',
        position: 5,
        value: '',
        validationPreset: 'phoneNumbersGroup',
        validationOptions: {
          noSortEdges: true,
          currentValidationPreset: 'phoneNumberFlat',
          otherField: 'mobile-phone',
        },
        mask: {
          hasNumberPositiveInteger: true,
        },
      },
      {
        id: 'email',
        name: 'contact-email',
        position: 6,
        value: '',
        validationPreset: 'email',
      },
    ],
    eInvoicePayCollection: [
      {
        id: 'e_archive_email',
        name: 'e-archive-email',
        position: 1,
        value: '',
        validationPreset: 'email',
        withTooltip: true,
      },
    ],
    billingAddress: [
      {
        id: 'address',
        name: 'address',
        position: 1,
        value: '',
        validationType: 'string',
        validationPreset: 'address',
      },
      {
        id: 'zip_code',
        name: 'zip-code',
        position: 2,
        value: '',
        validationType: 'string',
        validationPreset: 'zipCode',
        mask: {
          hasLeadingZero: true,
        },
      },
    ],
    deliveryAddress: [
      {
        id: 'address',
        name: 'address',
        position: 1,
        value: '',
        validationType: 'string',
        validationPreset: 'address',
      },
      {
        id: 'zip_code',
        name: 'zip-code',
        position: 2,
        value: '',
        validationType: 'string',
        validationPreset: 'zipCode',
        mask: {
          hasLeadingZero: true,
        },
      },
    ],
  },
};
