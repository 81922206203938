import React from 'react';
// Components - global
import AlertMessage from 'components/AlertMessage';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Collapse from '@material-ui/core/Collapse';
// Components - local
import {injectIntl} from 'react-intl';
import PaymentOptionsWithTooltip from './PaymentOptionsWithTooltip';
// React Intl

// Styles
const useStyles = makeStyles(theme => ({
  formControlWrapper: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  paymentLabelRequiredText: {
    margin: '0',
  },
}));

const OnlinePaymentOptions = ({children, paymentOptionsType}) =>
  paymentOptionsType === 'radio' ? (
    <RadioGroup>{children}</RadioGroup>
  ) : (
    <RadioGroup>{children}</RadioGroup>
  );

export default injectIntl(
  ({intl, translationIdentifierPrefix, paymentOptionsType, paymentOptions}) => {
    const classes = useStyles();
    // Intl
    const intlMessage = id => intl.formatMessage({id});

    return (
      <AlertMessage type='neutral' variant='outlined' icon={false}>
        <OnlinePaymentOptions paymentOptionsType={paymentOptionsType}>
          {paymentOptions.map(
            (
              {labelId, translationKey, isRequired, checked, handleChangeCallback},
              key,
            ) => (
              <div className={classes.formControlWrapper} key={key}>
                <FormControl required error={isRequired && !checked} fullWidth>
                  <PaymentOptionsWithTooltip
                    paymentOptionsType={paymentOptionsType}
                    translationIdentifierPrefix={translationIdentifierPrefix}
                    labelId={labelId}
                    translationKey={translationKey}
                    checked={checked}
                    handleChangeCallback={handleChangeCallback}
                  />

                  <Collapse in={isRequired && !checked}>
                    <FormHelperText classes={{root: classes.paymentLabelRequiredText}}>
                      {intlMessage(
                        `${translationIdentifierPrefix}_${paymentOptionsType}_required_${translationKey}`,
                      )}
                    </FormHelperText>
                  </Collapse>
                </FormControl>
              </div>
            ),
          )}
        </OnlinePaymentOptions>
      </AlertMessage>
    );
  },
);
