import React from 'react';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';
import {flatten} from 'flat';
import {useOnlyFallbackTranslations, isDev} from 'config';
//  Initial translations
import en from 'translations/en.json';
import tr from 'translations/tr.json';

const flattenDictionary = (obj, maxDepth = 3) => flatten(obj, {maxDepth});

const ConnectedIntlProvider = ({currentLanguage, translations, children}) => {
  const language = currentLanguage || 'en';
  const defaultTranslations = {
    en,
    tr,
  };

  const dictionaryFallback = flattenDictionary(defaultTranslations[language]);

  const translationsObject = () => {
    // If translations object ins't ready, return only dictionary fallback
    if (!translations) return dictionaryFallback;

    if (isDev) {
      const combinedTranslations = {
        ...dictionaryFallback,
        ...flattenDictionary(translations),
      };
      return useOnlyFallbackTranslations ? dictionaryFallback : combinedTranslations;
    }

    return flattenDictionary(translations);
  };

  return (
    <IntlProvider locale={language} messages={translationsObject()}>
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = state => {
  const {settings} = state.root;
  const {currentLanguage, translations} = settings;

  return {
    currentLanguage,
    translations,
  };
};

export default connect(mapStateToProps)(ConnectedIntlProvider);
