import merge from 'deepmerge';
import Montserrat from 'fonts/Montserrat';
import createTheme from '../createTheme';
import colors from '../green/colors';
// Fonts

const borderRadius = {
  small: '0.25rem',
  big: '1rem',
};

const spacing = {
  formField: 5,
};

const options = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': Montserrat,
        a: {
          color: colors.red,
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: borderRadius.big,
      },
    },
  },
  typography: {
    fontFamily: ['Montserrat, sans-serif'],
  },
  borderRadius,
  spacing: 12,
  palette: {
    tonalOffset: 0.1,
    primary: {
      main: colors.red,
    },
    secondary: {
      main: colors.blue,
    },
    themeColors: colors,
  },
  props: {
    MuiButton: {
      variant: 'outlined',
      disableElevation: true,
      style: {
        borderRadius: borderRadius.big,
      },
    },
    MuiTextField: {
      variant: 'outlined',
      margin: 'dense',
    },
  },
};

const currentTheme = createTheme(options);

const theme = merge(currentTheme, {
  formField: {
    wrapper: {
      maxWidth: 480,
    },
    field: {
      margin: `${spacing.formField}px 0`,
    },
  },
});

export default theme;
