import {
  SET_SELECTED_MEDIUM_TYPE,
  SET_AVAILABLE_MEDIUM_TYPES,
} from '../actions/mediumTypes';
import initialStates from './initialStates';

const initialState = initialStates.mediumTypes;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_MEDIUM_TYPE:
      return {
        ...state,
        selectedMediumType: action.value,
      };

    case SET_AVAILABLE_MEDIUM_TYPES:
      return {
        ...state,
        availableMediumTypes: action.value,
      };

    default:
      return state;
  }
};
