import React from 'react';
import {isDev, isDebugMode, appName, appVersion, persistSessionStorageKey} from 'config';
// HOC
import ConnectedIntlProvider from 'hoc/ConnectedIntlProvider';
// Redux
import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import {Provider} from 'react-redux';
import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {composeWithDevTools} from 'redux-devtools-extension';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
// Persistence
import {persistStore, persistReducer} from 'redux-persist';
import storageSession from 'redux-persist/es/storage/session';
import {PersistGate} from 'redux-persist/integration/react';
import {createWhitelistFilter} from 'redux-persist-transform-filter';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// Root action (RESET_STORE), reducers and middleware - this is merged with component props reducers and middleware
import {RESET_STORE} from 'store/actions/root';
import rootReducers from 'store/reducers';
import rootMiddleware from 'store/middleware';

export default props => {
  const {reducers, middleware, children, persistenceConfig} = props;

  // Persistence
  const whiteListFilterSubset = createWhitelistFilter('root', [
    'settings.currentLanguage',
    'settings.sessionId',
    'settings.instrumetationKey',
  ]);
  const persistConfig = {
    version: appVersion,
    key: appName,
    storage: storageSession,
    stateReconciler: autoMergeLevel2,
    whitelist: ['root', ...persistenceConfig.flowWhitelist],
    transforms: [whiteListFilterSubset],
  };

  try {
    const persistSessionStorageObject = sessionStorage.getItem(persistSessionStorageKey);
    /* eslint-disable no-underscore-dangle */
    const persistedAppVersion = JSON.parse(
      JSON.parse(persistSessionStorageObject)._persist,
    ).version;

    // If persisted app version differs than the app version, clear user persisted object
    if (appVersion !== persistedAppVersion) {
      sessionStorage.removeItem(persistSessionStorageKey);
    }
  } catch {
    // continue regardless of error
  }

  const rootReducersCombined = combineReducers(rootReducers);
  const appReducer = combineReducers({
    root: rootReducersCombined,
    ...reducers,
  });

  const reducer = (state, action) => {
    if (action.type === RESET_STORE) {
      // Keep from store 'currentLanguage' selected by user, 'availableLanguages' and 'translations'
      const {root} = state;
      const {settings} = root;
      const {currentLanguage, availableLanguages, translations, tenant} = settings;

      // Reset store with whitelist
      const whitelist = action?.payload?.whitelist || '';
      const whitelistToArray = Array.isArray(whitelist) ? whitelist : whitelist.split();
      const whitelistArray = whitelist ? whitelistToArray : [];

      let storeWhitelist = {};

      whitelistArray.forEach(storeKey => {
        storeWhitelist = {
          ...storeWhitelist,
          ...{
            [storeKey]: state[storeKey],
          },
        };
      });

      // Reset store with currentScene keep
      state = {
        root: {
          ...root,
          settings: {
            currentLanguage,
            availableLanguages,
            translations,
            tenant,
          },
        },
        ...storeWhitelist,
      };
    }

    return appReducer(state, action);
  };
  const persistedReducer = persistReducer(persistConfig, reducer);

  const composeEnhancers = isDebugMode ? composeWithDevTools : compose;
  const logger = createLogger({
    collapsed: (getState, action, logEntry) => !logEntry.error,
  });

  // Middlewares
  const middlewares = [
    ...(isDev ? [reduxImmutableStateInvariant()] : []),
    ...[thunk, ...rootMiddleware, ...middleware],
    ...(isDebugMode ? [logger] : []),
  ];

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedIntlProvider>{children}</ConnectedIntlProvider>
      </PersistGate>
    </Provider>
  );
};
