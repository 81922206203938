import React from 'react';
// Mui components
import Typography from '@material-ui/core/Typography';

export default props => {
  const {appName, appVersion} = props;

  return (
    <>
      <Typography variant='body1'>
        Application name: <strong>{appName}</strong>
      </Typography>
      <Typography variant='body1'>
        Version: <strong>{appVersion}</strong>
      </Typography>
    </>
  );
};
