import {tenants} from 'config';

const selectUrlGdpr = ({root, featureToggle}, gdprSuffix = null) => {
  const {settings} = root;
  const {productCode} = featureToggle;
  const {tenant, currentLanguage, blobStorageRootUrl} = settings;

  return `${blobStorageRootUrl}/gdpr/${tenant}_${currentLanguage}_${productCode}GDPR${
    gdprSuffix ? `_${gdprSuffix}` : ''
  }.pdf`;
};

const selectTenantCurrency = ({root}) => root.settings.currency;

const selectTenantCurrencyMaskSettings = ({root}) =>
  tenants[root.settings.tenant].tenantCurrencyMaskSettings;

const selectUTMValues = ({leads}) => leads;

export default {
  selectUrlGdpr,
  selectTenantCurrency,
  selectTenantCurrencyMaskSettings,
  selectUTMValues,
};
