import commonSelectors from './common';

const {selectProductCode, selectMediumType} = commonSelectors;

const checkHasDeliveryAddress = state => {
  const productCode = selectProductCode(state);
  const mediumType = selectMediumType(state);

  // Only one case with NO Delivery Address
  return !(productCode === '1' && mediumType === 'digital');
};

const checkHasOrderPicker = state => {
  const productCode = selectProductCode(state);
  const mediumType = selectMediumType(state);

  // Only one case with Order Picker
  return productCode === '1' && mediumType === 'plastic';
};

const selectCompanyAddressDetails = addressType => ({company}) => {
  const {serverFormData} = company;

  let type;

  switch (addressType) {
    case 'billing':
      type = 'billing_address';
      break;

    case 'delivery':
      type = 'delivery_address';
      break;

    default:
      break;
  }

  return {
    address: serverFormData[type].address,
    zipCode: serverFormData[type].zip_code,
    neighbourhood: serverFormData[type].neighbourhood.label,
    district: serverFormData[type].district.label,
    city: serverFormData[type].city.label,
  };
};

export default {
  checkHasDeliveryAddress,
  checkHasOrderPicker,
  selectCompanyAddressDetails,
};
