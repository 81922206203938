/*
  givenPath:    "contact-info"
  match.path:   "/tr"
  returns:      "/tr/contact-info"
   */

export default (givenPath, match) => {
  const {path} = match;
  const matchPath = path.substr(-1) === '/' ? path.slice(0, -1) : path;

  return `${matchPath}/${givenPath}`;
};
