import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {appName, appVersion, availableTenants} from 'config';
// Components - global

// Mui components
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PageNotFound from './components/PageNotFound';

// Components - local
import SmallHeader from './components/SmallHeader';
import AboutApplication from './components/AboutApplication';
import TenantsList from './components/TenantsList';
import TranslateTextExample from './components/TranslateTextExample';
import SimpleTableExample from './components/SimpleTableExample';

export default () => {
  const BlockElement = ({title, children}) => (
    <Box py={2}>
      <Typography variant='h2'>{title}</Typography>
      {children}
    </Box>
  );

  return (
    <Container maxWidth='sm'>
      <Box>
        <SmallHeader />

        <Paper elevation={5}>
          <Box px={2}>
            <Switch>
              <Route exact path='/'>
                <BlockElement title='About application'>
                  <AboutApplication appName={appName} appVersion={appVersion} />
                </BlockElement>

                <BlockElement title='Available tenants'>
                  <TenantsList availableTenants={availableTenants} />
                </BlockElement>

                <BlockElement title='Translate Text Example'>
                  <TranslateTextExample />
                </BlockElement>

                <BlockElement title='Simple Table Example'>
                  <SimpleTableExample />
                </BlockElement>
              </Route>

              <Route>
                <BlockElement>
                  <PageNotFound />
                </BlockElement>
              </Route>
            </Switch>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};
