import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
// Components - global
import SimpleTable from 'components/Table/Simple';
import CurrencyValue from 'components/CurrencyValue';
// Mui components
import {withTheme} from '@material-ui/core/styles';
import {lighten} from 'material-ui/styles/colorManipulator';
import Box from '@material-ui/core/Box';
// React Intl
import {FormattedMessage} from 'react-intl';

const mapStateToProps = (state, ownProps) => {
  // Selectors
  const {selectors, tableType} = ownProps;
  const {selectOrderSummaryData} = selectors;

  return {
    orderSummaryData: selectOrderSummaryData(tableType, state),
  };
};

export default compose(
  connect(mapStateToProps),
  withTheme,
)(({theme, totalValueMap, classes, translationIdentifierPrefix, orderSummaryData}) => {
  const {
    productCode,
    numberVehicles,
    totalAverageFuelConsumption,
    commission,
    totalToPay,
    orderWillingToPay,
  } = orderSummaryData;

  const totalValue = totalValueMap
    ? orderSummaryData[totalValueMap]
    : orderWillingToPay || totalToPay;

  // Intl
  const tableTranslate = keyId => (
    <FormattedMessage id={`${translationIdentifierPrefix}_table-summary_${keyId}`} />
  );

  const tableData = [
    {
      label: tableTranslate('label_product'),
      values: tableTranslate(`value_productCode_${productCode}`),
    },
    {
      label: (
        <Box fontWeight='fontWeightBold' color={theme.palette.themeColors.blueHot}>
          {tableTranslate('label_numberVehicles')}
        </Box>
      ),
      values: (
        <Box fontWeight='fontWeightBold' color={theme.palette.themeColors.blueHot}>
          {numberVehicles}
        </Box>
      ),
      rowBg: theme.palette.grey[200],
    },
    {
      label: tableTranslate('label_totalAverageFuelConsumption'),
      values: <CurrencyValue value={totalAverageFuelConsumption} />,
    },
    {
      label: tableTranslate('label_commission'),
      values: `${commission} %`,
    },
    {
      label: (
        <Box fontWeight='fontWeightBold' color={theme.palette.themeColors.blueHot}>
          {tableTranslate('label_totalToPay')}
        </Box>
      ),
      values: (
        <Box fontWeight='fontWeightBold' color={theme.palette.themeColors.blueHot}>
          <CurrencyValue value={totalValue} />
        </Box>
      ),
      rowBg: lighten(theme.palette.themeColors.green, theme.opacity.medium),
    },
  ];

  return <SimpleTable classes={classes} tableRows={tableData} />;
});
