import React, {Suspense, memo} from 'react';
import _ from 'lodash';
import {Route, Switch, Redirect, useRouteMatch} from 'react-router-dom';
import {routePath} from 'helpers';
// Components - global
import LoaderOverlay from 'components/Loader/Overlay';

export default memo(containerProps => {
  const {
    flowRoutes,
    staticRoutes,
    scenes,
    scenesChildrenComponents,
    staticPages,
    rootActions,
    flowActions,
    flowPaths,
    PageNotFound,
    visitorProductPath,
    visitorFlowName,
    translationIdentifierPrefixBase,
    translationIdentifierPrefix,
    tableType,
  } = containerProps;

  const match = useRouteMatch();
  const landingPagePath = flowRoutes[0].path;

  const RouteComponent = ({children, path, key}) => (
    <Route path={path} key={key}>
      <Suspense fallback={<LoaderOverlay />}>{children}</Suspense>
    </Route>
  );

  return (
    <Switch>
      {/* Landing page: Redirect to first page from flow */}
      <Route exact path={routePath('', match)}>
        <Redirect to={routePath(landingPagePath, match)} />
      </Route>

      {flowRoutes.map((route, key) => {
        const {path, sceneComponentName, childrenComponentsName, props} = route;
        const Scene = scenes[sceneComponentName];

        const childrenComponents = _.mapValues(
          childrenComponentsName,
          value => scenesChildrenComponents[value],
        );

        return RouteComponent({
          children: (
            <Scene
              {...props}
              currentPath={path}
              rootActions={rootActions}
              flowActions={flowActions}
              flowPaths={flowPaths}
              routeMatch={match}
              visitorProductPath={visitorProductPath}
              visitorFlowName={visitorFlowName}
              translationIdentifierPrefixBase={translationIdentifierPrefixBase}
              translationIdentifierPrefix={translationIdentifierPrefix}
              tableType={tableType}
              childrenComponents={childrenComponentsName && childrenComponents}
            />
          ),
          path: routePath(path, match),
          key,
        });
      })}

      {staticRoutes.map((route, key) => {
        const {path, sceneComponentName, props} = route;
        const StaticPage = staticPages[sceneComponentName];

        return RouteComponent({
          key,
          path: routePath(path, match),
          children: (
            <StaticPage
              {...props}
              flowActions={flowActions}
              rootActions={rootActions}
              translationIdentifierPrefix={translationIdentifierPrefix}
              currentPath={path}
              routeMatch={match}
            />
          ),
        });
      })}

      <Route component={() => <PageNotFound flowActions={flowActions} />} />
    </Switch>
  );
});
