import React from 'react';
// Components - global
import Popup from 'components/Popup';
//  FAQ Components
import HelpAccordion from './HelpAccordion';

const HelpModal = ({status, payload, handleStatus}) => {
  const {modalTitle, faqItems} = payload;
  const [expanded, setExpanded] = React.useState('');
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : '');
  };

  return (
    <Popup status={status} title={modalTitle} handleClose={handleStatus} centeredText>
      {faqItems.map((item, key) => {
        const itemID = `faq-item-${key}`;
        return (
          <HelpAccordion
            faqItem={item}
            key={key}
            itemID={itemID}
            expanded={expanded}
            handleChange={handleChange}
          />
        );
      })}
    </Popup>
  );
};

export default HelpModal;
