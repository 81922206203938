import {tenants} from 'config';
import {formDataParser} from 'helpers';
import rootActions from 'store/actions';
import companyActions from './company';

export const SET_CONTACT_ID = '[TURKEY_PRODUCTS/CONTACT] SET_CONTACT_ID';
export const SET_FORM_IS_DIRTY = '[TURKEY_PRODUCTS/CONTACT] SET_FORM_IS_DIRTY';
export const UPDATE_SERVER_FORM_DATA =
  '[TURKEY_PRODUCTS/CONTACT] UPDATE_SERVER_FORM_DATA';

const setContactId = value => ({
  type: SET_CONTACT_ID,
  value,
});

const setFormIsDirty = value => ({
  type: SET_FORM_IS_DIRTY,
  value,
});

const updateServerData = payload => ({
  type: UPDATE_SERVER_FORM_DATA,
  payload,
});

const serverFormData = (payload, nextStepSubmit) => async (dispatch, getState) => {
  const {rootCollection} = payload;
  const requestPayload = {...formDataParser(rootCollection)};
  const {contactId, formIsDirty} = getState().contact;
  const {companyUuid} = getState().company;

  if (!formIsDirty) {
    dispatch(setFormIsDirty(true));
  }

  // Clear irrelevant data from BE
  delete requestPayload.gdpr;
  delete requestPayload.commercial_communications;
  delete requestPayload.employee_gdpr;

  dispatch(updateServerData(requestPayload));

  // Create or update company data (company_name)
  if (nextStepSubmit) {
    await dispatch(companyActions.serverCompanyPartialData(requestPayload));
  }

  // Create contact
  if (nextStepSubmit && !contactId) {
    return dispatch(
      rootActions.api.makeRequest('company/createContact', [companyUuid, requestPayload]),
    ).then(response => dispatch(setContactId(response.data.data)));
  }

  // Update contact
  return (
    nextStepSubmit &&
    dispatch(
      rootActions.api.makeRequest('company/updateContact', [
        companyUuid,
        contactId,
        requestPayload,
      ]),
    )
  );
};

const setFormPrefillData = formData => async (dispatch, getState) => {
  const statesData = {
    leads: () => getState().leads,
    formIsDirty: () => getState().contact.formIsDirty,
    tenant: () => getState().root.settings.tenant,
  };
  const {hasPrefillFromLeads} = tenants[statesData.tenant()].featureToggle;

  if (statesData.formIsDirty() || !hasPrefillFromLeads) return;

  const {rootCollection} = formData;

  const formFieldsToLeadFieldsMap = {
    contact_phone: 'phone',
    mobile_phone: 'mobile',
    contact_first_name: 'firstName',
    contact_last_name: 'lastName',
    contact_email: 'email',
    company_name: 'company',
  };

  const updateFormDataValue = givenField => {
    const fieldData = rootCollection.find(el => el.id === givenField);
    if (fieldData) {
      fieldData.value = statesData.leads()[formFieldsToLeadFieldsMap[givenField]];
    }
  };

  Object.keys(formFieldsToLeadFieldsMap).forEach(fieldKey => {
    updateFormDataValue(fieldKey);
  });

  dispatch(serverFormData({rootCollection}));
};

export default {
  serverFormData,
  setFormPrefillData,
};
