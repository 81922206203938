import {
  SET_IS_DIRTY,
  SET_PAYMENT_TYPE,
  SET_CALENDAR_UNIT,
  SET_ALLOWANCE,
  SET_EMPLOYEE_NUMBER,
  SET_SAVINGS_CALCULATIONS,
} from '../actions/priceSimulatorBenefit';
import initialStates from './initialStates';

const initialState = initialStates.priceSimulatorBenefit;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_DIRTY:
      return {
        ...state,
        isDirty: action.value,
      };

    case SET_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.value,
      };

    case SET_CALENDAR_UNIT:
      return {
        ...state,
        calendar_unit: action.value,
      };

    case SET_ALLOWANCE:
      return {
        ...state,
        allowance: action.value,
      };

    case SET_EMPLOYEE_NUMBER:
      return {
        ...state,
        employee_number: action.value,
      };

    case SET_SAVINGS_CALCULATIONS:
      return {
        ...state,
        savingsCalculations: action.value,
      };

    default:
      return state;
  }
};
