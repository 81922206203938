import React from 'react';

// Mui components
import {makeStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// Styles
const useStyles = makeStyles({
  alignStart: {
    alignItems: 'flex-start',
  },
  alignWithCheckbox: {
    '& > .MuiTypography-root': {
      paddingTop: '0.75em',
    },
  },
});

export default props => {
  const {
    label,
    checked,
    onChange,
    color = 'primary',
    alignToTop,
    id,
    value,
    name,
  } = props;

  const classes = useStyles();

  return (
    <FormControlLabel
      className={alignToTop ? `${classes.alignStart} ${classes.alignWithCheckbox}` : ''}
      label={label}
      control={
        <Checkbox
          checked={checked}
          onChange={onChange && (event => onChange(event, id))}
          color={color}
          id={id}
          value={value}
          name={name}
        />
      }
    />
  );
};
