import React from 'react';
// Components - global
import SimpleTable from 'components/Table/Simple';
// Mui components
import Box from '@material-ui/core/Box';

export default () => (
  <SimpleTable
    tableRows={[
      {
        label: 'Label 1',
        values: 'Value 1',
      },
      {
        label: <Box fontWeight='fontWeightBold'>Label 2</Box>,
        values: 'Value 2',
      },
    ]}
  />
);
