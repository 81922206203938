import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {useLocation} from 'react-router-dom';
// React Helmet
import {Helmet} from 'react-helmet';
// Components - local
// Components - global
import Header from 'components/Header';
import Footer from 'components/Footer';
import HelpCustomer from 'components/HelpCustomer';
// HOC
import withAnalyticsTool from 'hoc/Analytics/.';
import analyticsEvents from 'hoc/Analytics/analyticsEvents';
import visitorFlows from '.';

const {pathWithUTMParams} = analyticsEvents;
// Inject head tags
const SEIndexingInjectHead = (
  <Helmet>
    {/* Disable search engine indexing */}
    <meta name='robots' content='noindex noarchive' />
  </Helmet>
);
const CookieInjectHead = ({cookieSrc}) => {
  // Cookie banner integration
  const sdkDoaminScript = cookieSrc.split('/')[4];

  return (
    <Helmet>
      <script type='text/javascript' src={cookieSrc} />
      <script
        src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
        data-document-language='true'
        type='text/javascript'
        charset='UTF-8'
        data-domain-script={sdkDoaminScript}
      />
      <script type='text/javascript'>function OptanonWrapper() {}</script>
    </Helmet>
  );
};

const VisitorFlowContainer = props => {
  const {
    helpCustomer,
    header,
    tenant,
    visitorProductPath,
    visitorFlowName,
    featureToggle,
    googleTrackingId,
    cookieBannerToken,
    options,
    layout,
    currentScene,
    gtmInit,
    gtmDataLayerPush,
    utmLeads,
  } = props;
  const {
    hasHeaderWithVendor,
    blockSearchEngineIndexing,
    analytics,
    cookieConsents,
  } = featureToggle;
  const {hasGtm, dataLayerUTM} = analytics.gtm;
  const {hasCookieBanner} = cookieConsents;
  const Flow = visitorFlows[visitorFlowName];
  const {containerMaxWidth} = layout;

  if (hasGtm && googleTrackingId) {
    gtmInit(googleTrackingId);
  }

  // Trigger GTM dataLayer
  const location = useLocation();
  useEffect(() => {
    if (utmLeads && Object.keys(utmLeads).length > 0 && dataLayerUTM) {
      gtmDataLayerPush(pathWithUTMParams(utmLeads));
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      {blockSearchEngineIndexing && SEIndexingInjectHead}
      {hasCookieBanner && cookieBannerToken && (
        <CookieInjectHead cookieSrc={cookieBannerToken} />
      )}
      <Header
        {...header}
        {...options.Header}
        logo={{
          ...options.Header.logo,
          image: options.Header.logo[visitorFlowName],
        }}
        containerMaxWidth={containerMaxWidth}
        extendedLayout={visitorFlowName === 'Benefit'}
        // Vendor Logo only Fuel
        hasVendor={visitorFlowName === 'Fuel' && hasHeaderWithVendor}
      />
      <Flow.Container
        tenant={tenant}
        featureToggle={featureToggle}
        visitorFlowName={visitorFlowName}
        visitorProductPath={visitorProductPath}
        containerMaxWidth={containerMaxWidth}
        extendedLayout={visitorFlowName === 'Benefit'}
      />
      {!options.HelpCustomer.disabledOnRoutes.includes(currentScene) && (
        <HelpCustomer {...helpCustomer} />
      )}
      <Footer
        {...options.Footer}
        hasEnabledTopImage={options.Footer?.TopImage?.enabledOnRoutes.includes(
          currentScene,
        )}
        tenant={tenant}
      />
    </>
  );
};

const mapStateToProps = state => {
  const {availableLanguages, translations, currentLanguage} = state.root.settings;
  const {currentScene} = state.flow;
  const {googleTrackingId, cookieBannerToken} = state.root.settings;
  const {leads} = state;

  return {
    header: {
      availableLanguages,
      translations,
      currentLanguage,
      vendorLogoPath: state.featureToggle.vendorLogoPath,
    },
    helpCustomer: {
      currentScene,
    },
    googleTrackingId,
    cookieBannerToken,
    currentScene,
    utmLeads: leads,
  };
};

export default compose(withAnalyticsTool, connect(mapStateToProps))(VisitorFlowContainer);
