import _ from 'lodash';

import {convertCurrencyStringToFloat, convertStringToInt} from 'utils';

import rootSelectors from 'store/selectors';
import commonSelectors from './common';
import orderSelectors from './order';

const {selectTenantCurrency, selectTenantCurrencyMaskSettings} = rootSelectors;
const {
  selectMediumType,
  selectPaymentType,
  selectCalendarUnitRange,
  selectAllowanceRange,
} = commonSelectors;

const selectEmployeeListColumns = (state, allTableData) => {
  const paymentType = selectPaymentType(state);
  const mediumType = selectMediumType(state);
  const {columns} = allTableData;
  const {common, collection} = columns;

  const getCollectionColumns = selectedColumns =>
    _.intersectionBy(
      collection,
      selectedColumns.map(name => ({name})),
      'name',
    );

  const dailyCommon = getCollectionColumns(['days', 'daily-value', 'total']);
  const monthlyCommon = getCollectionColumns(['monthly-value']);

  const columnsComposition = {
    daily: {
      plastic: [...getCollectionColumns(['phone']), ...dailyCommon],
      digital: [...getCollectionColumns(['phone']), ...dailyCommon],
    },
    monthly: {
      plastic: [...getCollectionColumns(['phone']), ...monthlyCommon],
      digital: [...getCollectionColumns(['phone']), ...monthlyCommon],
    },
  };

  // Remove removed fields
  return _.filter(
    [...common, ...columnsComposition[paymentType][mediumType]],
    ({removed}) => !removed,
  );
};

const selectEmployeeListOrderValues = (state, payload) => {
  const paymentType = selectPaymentType(state);
  const currency = selectTenantCurrency(state);
  const currencySettings = selectTenantCurrencyMaskSettings(state);

  const currencyStrToFloat = value =>
    convertCurrencyStringToFloat(value, currency, currencySettings);

  const {days, loadValue} = payload;

  switch (paymentType) {
    case 'daily':
      return {
        days,
        'daily-value': currencyStrToFloat(loadValue),
        total: days * currencyStrToFloat(loadValue),
      };

    case 'monthly':
      return {
        'monthly-value': currencyStrToFloat(loadValue),
      };

    default:
      return false;
  }
};

const selectEmployeeListDraftRows = state => {
  const calendarUnitRange = selectCalendarUnitRange(state);
  const allowanceRange = selectAllowanceRange(state);

  const days = calendarUnitRange.default;
  const loadValue = allowanceRange.default;

  return selectEmployeeListOrderValues(state, {days, loadValue});
};

const selectEmployeeListOrderTotalValue = (state, givenRowLines = []) => {
  // Total value of order
  const paymentType = selectPaymentType(state);
  // Row lines are selected from state or from 'givenRowLines' variable
  const rowLines =
    givenRowLines.length > 0
      ? givenRowLines
      : orderSelectors.selectRows('employees')(state);

  let totalValue = 0;

  switch (paymentType) {
    case 'daily':
      rowLines.forEach(
        row => (totalValue += row.days * convertStringToInt(row['daily-value'])),
      );
      return totalValue;

    case 'monthly':
      rowLines.forEach(row => (totalValue += convertStringToInt(row['monthly-value'])));
      return totalValue;

    default:
      return totalValue;
  }
};

export default {
  selectEmployeeListColumns,
  selectEmployeeListOrderValues,
  selectEmployeeListDraftRows,
  selectEmployeeListOrderTotalValue,
};
