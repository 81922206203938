const selectProductCode = ({featureToggle}) => featureToggle.productCode;

const selectMediumType = state => {
  const productCode = selectProductCode(state);
  const {mediumTypes} = state;

  return productCode === '1' ? mediumTypes.selectedMediumType : 'ring';
};

const selectPaymentType = ({priceSimulatorBenefit}, withShortHand) => {
  const {paymentType} = priceSimulatorBenefit;

  if (withShortHand) {
    return (paymentType === 'daily' && 'day') || (paymentType === 'monthly' && 'month');
  }

  return paymentType;
};

const selectCalendarUnit = ({priceSimulatorBenefit}) =>
  priceSimulatorBenefit.calendar_unit;

const selectBeneficiariesRange = ({root}) => root.settings.rangeBeneficiaries;

const selectCalendarUnitRange = state => {
  const paymentType = selectPaymentType(state);
  const {rangeNumberMonths, rangeNumberWorkingDays} = state.root.settings;

  return paymentType === 'monthly' ? rangeNumberMonths : rangeNumberWorkingDays;
};

const selectAllowanceRange = state => {
  const paymentType = selectPaymentType(state);
  const {rangeAllowanceMonths, rangeAllowanceDays} = state.root.settings;

  return paymentType === 'monthly' ? rangeAllowanceMonths : rangeAllowanceDays;
};

const selectUrlPdfTermsConditions = state => {
  const {settings} = state.root;
  const {tenant, currentLanguage, blobStorageRootUrl} = settings;
  // Selectors
  const productCode = selectProductCode(state);

  return `${blobStorageRootUrl}/terms-conds/${tenant}_${currentLanguage}_${productCode}_terms-and-cond.pdf`;
};

const selectUrlPdfContract = state => {
  const productCode = selectProductCode(state);
  const {settings} = state.root;
  const {tenant, currentLanguage, blobStorageRootUrl} = settings;

  return `${blobStorageRootUrl}/terms-conds/${tenant}_${currentLanguage}_${productCode}_contract.pdf`;
};

export default {
  selectProductCode,
  selectMediumType,
  selectPaymentType,
  selectCalendarUnit,
  selectBeneficiariesRange,
  selectCalendarUnitRange,
  selectAllowanceRange,
  selectUrlPdfTermsConditions,
  selectUrlPdfContract,
};
