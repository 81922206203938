import {tenants} from 'config';
import {renameObjectProperty} from 'utils';
import {formDataParser} from 'helpers';
import api from './api';

const contactUsMessage = payload => dispatch => {
  const {rootCollection} = payload;

  const requestPayload = {
    ...formDataParser(rootCollection),
  };

  // Replace lName & fName with full_name
  requestPayload.full_name = `${requestPayload.first_name} ${requestPayload.last_name}`;
  delete requestPayload.first_name;
  delete requestPayload.last_name;

  renameObjectProperty('contact_company_name', 'company_name', requestPayload);

  return dispatch(api.makeRequest('notifications/contactUs', [requestPayload])).then(
    response => response,
  );
};

const setContactUsPrefillData = contactUsFormData => (dispatch, getState) => {
  const statesData = {
    contactInfoFormData: () => getState().contact.serverFormData,
    tenant: () => getState().root.settings.tenant,
  };
  const {hasPrefillOnContactUs} = tenants[statesData.tenant()].featureToggle;
  const {rootCollection} = contactUsFormData;
  const contactInfoPhone =
    statesData.contactInfoFormData().mobile_phone ||
    statesData.contactInfoFormData().contact_phone;
  const contactCompanyName = statesData.contactInfoFormData().company_name;

  if (hasPrefillOnContactUs) {
    rootCollection.forEach(field => {
      switch (field.name) {
        case 'contact-phone':
          field.value = contactInfoPhone;
          break;

        case 'contact-company-name':
          field.value = contactCompanyName;
          break;

        default:
          // Enums mapping (to do)
          field.value = statesData.contactInfoFormData()[field.name.replaceAll('-', '_')];
      }
    });
  }

  return contactUsFormData;
};

export default {
  contactUsMessage,
  setContactUsPrefillData,
};
