import React from 'react';
// Mui components
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
// Components - local
import Row from './components/Row';

export default ({classes, tableRows, tableHead, ariaLabel, withPaperContainer}) => (
  <TableContainer className={classes} component={withPaperContainer && Paper}>
    <Table aria-label={ariaLabel}>
      {tableHead && (
        <TableHead>
          {tableHead.map((row, key) => (
            <Row key={key} data={row} />
          ))}
        </TableHead>
      )}

      <TableBody>
        {tableRows.map((row, key) => (
          <Row data={row} key={key} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
