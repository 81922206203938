const mock = {
  contact: {
    formIsDirty: true,
    serverFormData: {
      contact_first_name: 'FName',
      contact_last_name: 'LName',
      company_name: 'Edenred Coding Geeks SRL',
      mobile_phone: '5999999999',
      contact_phone: '2123456789',
      contact_email: 'i.am.dummy@test.com',
      contact_email_confirm: 'i.am.dummy@test.com',
      number_employees: '2',
      number_vehicles: '2',
    },
  },
  company: {
    formIsDirty: true,
    serverFormData: {
      company_name: 'Edenred Coding Geeks SRL (2)',
      fiscal_or_vat_number: '8340052014',
      tax_office: '1234',
      mobile_phone_number: '5999999999',
      phone_number: '2999999999',
      email: 'i.am.dummy@company.com',
      billing_address: {
        address: 'Dummies Street 345',
        zip_code: '20800',
        city: {
          id: 8,
          code: '08',
          label: 'ARTVİN',
        },
        district: {
          id: 170,
          code: '170',
          label: 'Arhavi',
          city_id: 8,
        },
        neighbourhood: {
          id: 2537,
          code: '2537',
          label: 'Arhavi',
          district_id: 170,
        },
      },
      delivery_address: {
        address: 'Besiktas Street',
        zip_code: '12344',
        city: {
          id: 14,
          code: '14',
          label: 'BOLU',
        },
        district: {
          id: 548,
          code: '548',
          label: 'Kaynaşlı',
          city_id: 14,
        },
        neighbourhood: {
          id: 225,
          code: '225',
          label: 'Üçköprü',
          district_id: 548,
        },
      },
      order_picker: {
        first_name: 'Dummy',
        last_name: 'Boy',
        mobile_phone: '5111111111',
        phone: '2111111111',
        email: 'dummy.boy@curier.com',
      },
      e_invoice_pay: {
        e_archive_email: 'dummy.boy@company-invoice.com',
      },
    },
    deliveryAddressCollapsed: true,
    orderPickerCollapsed: true,
    eInvoicePayCollapsed: true,
  },
  consents: {
    acceptedGdpr: true,
    acceptedMarketing: true,
    acceptedTerms: true,
  },
  mediumTypes: {
    selectedMediumType: 'plastic',
  },
  priceSimulatorFuel: {
    isDirty: true,
    number_vehicles: 2,
    average_fuel_consumption: 1000,
    multiplication: 2000,
    commission: 5,
    total_savings: 100,
  },
  priceSimulatorBenefitMonthly: {
    isDirty: true,
    paymentType: 'monthly',
    calendar_unit: 1,
    allowance: 500,
    employee_number: 2,
    savingsCalculations: {
      cashDailyMealNoVAT: 25.25,
      cashDailyTotalNoVAT: 25,
      cashIncomeTax: 712.8,
      cashSGKEmployee: 734.8,
      cashSGKPremium: 489.5,
      cashStampDuty: 30.8,
      cashVAT: 0.25,
      cashVATDiscount: 0,
      employeeFreeCost: 2.1,
      monthBudget: 2750,
      monthPayrollCost: 4744.54,
      savingsPercentage: 72.56,
      ticketRestaurantCostMonth: 2750,
      ticketRestaurantCostYear: 33000,
      ticketRestaurantDailyMealNoVAT: 25.25,
      ticketRestaurantDailyTotalNoVAT: 25,
      ticketRestaurantIncomeTax: 0,
      ticketRestaurantSGKEmployee: 0,
      ticketRestaurantSGKPremium: 0,
      ticketRestaurantStampDuty: 0,
      ticketRestaurantTaxSavingsMonth: 1995.4,
      ticketRestaurantTaxSavingsYear: 23944.8,
      ticketRestaurantVAT: 0.25,
      ticketRestaurantVATDiscount: -27.5,
      totalCost: 2750,
      totalCostTax: 1967.9,
      yearBudget: 33000,
      yearPayrollCost: 56934.5,
    },
  },
  priceSimulatorBenefitDaily: {
    isDirty: true,
    paymentType: 'daily',
    calendar_unit: 22,
    allowance: 23,
    employee_number: 2,
    savingsCalculations: {
      cashDailyMealNoVAT: 25.25,
      cashDailyTotalNoVAT: 25,
      cashIncomeTax: 712.8,
      cashSGKEmployee: 734.8,
      cashSGKPremium: 489.5,
      cashStampDuty: 30.8,
      cashVAT: 0.25,
      cashVATDiscount: 0,
      employeeFreeCost: 2.1,
      monthBudget: 2750,
      monthPayrollCost: 4744.54,
      savingsPercentage: 72.56,
      ticketRestaurantCostMonth: 2750,
      ticketRestaurantCostYear: 33000,
      ticketRestaurantDailyMealNoVAT: 25.25,
      ticketRestaurantDailyTotalNoVAT: 25,
      ticketRestaurantIncomeTax: 0,
      ticketRestaurantSGKEmployee: 0,
      ticketRestaurantSGKPremium: 0,
      ticketRestaurantStampDuty: 0,
      ticketRestaurantTaxSavingsMonth: 1995.4,
      ticketRestaurantTaxSavingsYear: 23944.8,
      ticketRestaurantVAT: 0.25,
      ticketRestaurantVATDiscount: -27.5,
      totalCost: 2750,
      totalCostTax: 1967.9,
      yearBudget: 33000,
      yearPayrollCost: 56934.5,
    },
  },
  order: {
    paymentInitiated: false,
  },
  orderFuel: {
    orderData: {
      order_request_lines: [
        {
          'plate-number': 'B123EDN',
          'vehicle-type': 'automobile-gasoline',
          'medium-type': 'card',
          'vehicle-purpose': 'passenger',
        },
      ],
      totalToPay: 1900,
      totalAverageFuelConsumption: 2000,
    },
  },
  orderBenefitMonthly: {
    isDirty: true,
    orderData: {
      client_id: '4b726ca9-a720-4efa-a26a-36e9b8ee7078',
      order_request_lines: [
        {
          'employee-first-name': 'FName',
          'employee-last-name': 'LName',
          'monthly-value': 600,
        },
      ],
    },
    orderCalculations: {
      invoiceTotal: 621.03,
      orderNet: 594.06,
      commissionNet: 17.82,
      commissionVat: 3.21,
      orderVat: 5.94,
    },
  },
  orderBenefitDaily: {
    orderData: {
      client_id: '225b5755-c677-401a-a82b-21bf48b14ae7',
      product_ref: '1',
      order_request_lines: [
        {
          'employee-first-name': 'FName',
          'employee-last-name': 'Lname',
          'employee-id': '999',
          'monthly-value': 575.5,
        },
        {
          'employee-first-name': 'FName',
          'employee-last-name': 'LName',
          'employee-id': '111',
          'monthly-value': 50.29,
        },
      ],
    },
    orderCalculations: {
      invoiceTotal: 647.73,
      orderNet: 619.59,
      commissionNet: 18.59,
      commissionVat: 3.35,
      orderVat: 6.2,
    },
  },
};

// Monthly
mock.orderBenefit = mock.orderBenefitMonthly;
mock.priceSimulatorBenefit = mock.priceSimulatorBenefitMonthly;

// Daily
// mock.orderBenefit = mock.orderBenefitDaily;
// mock.priceSimulatorBenefit = mock.priceSimulatorBenefitDaily;

export default mock;
