import {SET_LEADS} from '../actions/leads';
import initialStates from './initialStates';

const initialState = initialStates.leads;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LEADS:
      return {
        ...state,
        ...action.value,
      };

    default:
      return state;
  }
};
