import React from 'react';

// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// Styles
const useStyles = makeStyles(theme => ({
  button: {
    padding: 0,
    color: theme.palette.themeColors.blueLight,
    textTransform: 'none',
    border: 'none',
    verticalAlign: 'inherit',
    minWidth: 'inherit',
    '&:hover': {
      background: 'transparent',
      textDecoration: 'underline',
      color: theme.palette.themeColors.blueDark,
    },
  },
}));

export default ({target, href, children, handleClick}) => (
  <Button
    disableRipple
    className={useStyles().button}
    target={target}
    href={href}
    onClick={handleClick}
  >
    {children}
  </Button>
);
