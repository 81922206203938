import {objectClone} from 'utils';
import commonSelectors from './common';
import contactSelectors from './contact';
import companySelectors from './company';

// Selectors
const {selectProductCode, selectMediumType} = commonSelectors;
const {selectContactFullName} = contactSelectors;
const {selectCompanyAddressDetails} = companySelectors;

// Beneficiary List
const selectRows = tableType => (state, givenRowLines = []) => {
  // Row lines are selected from state or from 'givenRowLines' variable
  const orderLines = orderState =>
    givenRowLines.length > 0
      ? givenRowLines
      : state[orderState].orderData.order_request_lines;

  switch (tableType) {
    case 'vehicles':
      return orderLines('orderFuel');

    case 'employees':
      return orderLines('orderBenefit');

    default:
      return 0;
  }
};
const selectNumberOfRows = tableType => (state, givenRowLines) =>
  selectRows(tableType)(state, givenRowLines).length;
const selectNumberOfDraftRows = tableType => ({
  root,
  priceSimulatorFuel,
  priceSimulatorBenefit,
}) => {
  const {defaultNumberOfEmptyRowsOrder} = root.settings;
  const vehiclesNo = priceSimulatorFuel.number_vehicles;
  const employeesNo = priceSimulatorBenefit.employee_number;

  const blankRowsCheck = value =>
    value > defaultNumberOfEmptyRowsOrder ? defaultNumberOfEmptyRowsOrder : value;

  switch (tableType) {
    case 'vehicles':
      return blankRowsCheck(vehiclesNo);

    case 'employees':
      return blankRowsCheck(employeesNo);

    default:
      return 0;
  }
};

// Order
const selectOrderPayload = (state, payload) => {
  const productCode = selectProductCode(state);
  const {companyUuid} = state.company;

  // Remove unnecessary data from order request lines: ---
  const orderLinesDraft = payload.map(x => {
    const row = {...x};
    delete row.key;
    delete row.tableData;
    return row;
  });
  // -----------------------------------------------------

  // Check and remove draft rows (unmodified rows with empty values) from order lines:
  const orderLinesState = [];
  let emptyRowsIndexes = [];

  // Check for empty values on each row
  orderLinesDraft.forEach((o, i) =>
    Object.values(o).filter(v => v === '' && emptyRowsIndexes.push(i)),
  );

  // Remove duplicates
  emptyRowsIndexes = Array.from(new Set(emptyRowsIndexes));

  // Update new order lines array
  orderLinesDraft.forEach((o, i) => {
    if (!emptyRowsIndexes.includes(i)) {
      orderLinesState.push(o);
    }
  });
  // ---------------------------------------------------

  // Enums mapping (to do)
  const orderLinesRequest = objectClone(orderLinesState);

  orderLinesRequest.map(requestLine =>
    Object.keys(requestLine).map(fieldName => {
      const fieldId = fieldName.replaceAll('-', '_');
      const fieldValue = requestLine[fieldName].toString().replaceAll('-', '_');

      // Update key name and value
      requestLine[fieldId] = fieldValue;
      return delete requestLine[fieldName];
    }),
  );
  // ---------------------------------------------------

  const commonPayload = {
    // On visitor flow client_id is equal with company uuid
    client_id: companyUuid,
    product_ref: productCode,
    order_request_lines: orderLinesRequest,
  };

  const orderRequest = {
    ...commonPayload,
    order_request_lines: orderLinesRequest,
  };

  const orderState = {
    ...commonPayload,
    order_request_lines: orderLinesState,
  };

  return {
    orderRequest,
    orderState,
  };
};
const selectContactDetails = contactType => state => {
  const {company, contact} = state;
  const {deliveryAddressCollapsed} = company;

  // Selectors
  const productCode = selectProductCode(state);
  const mediumType = selectMediumType(state);
  const contactFullName = selectContactFullName(state);
  const companyAddressDetails = addressType =>
    selectCompanyAddressDetails(addressType)(state);

  const companyData = company.serverFormData;
  const contactData = contact.serverFormData;

  const taxOffice = companyData.tax_office;
  const hasTaxOffice = productCode !== '1' && taxOffice;

  const clientContactDetails = {
    name: companyData.company_name,
    fiscalNumber: companyData.fiscal_or_vat_number,
    ...(() => hasTaxOffice && {taxOffice})(),
  };

  let personContactDetails = {
    name: contactFullName,
    phone: contactData.contact_phone,
    phoneGsm: contactData.mobile_phone,
    email: contactData.contact_email,
  };

  switch (contactType) {
    case 'client':
      return {
        ...clientContactDetails,
        ...companyAddressDetails('billing'),
      };

    case 'person':
      if (mediumType === 'plastic') {
        if (deliveryAddressCollapsed) {
          personContactDetails = {
            ...personContactDetails,
            ...companyAddressDetails('delivery'),
          };
        } else {
          personContactDetails = {
            ...personContactDetails,
            ...companyAddressDetails('billing'),
          };
        }
      }

      return personContactDetails;

    default:
      return false;
  }
};

// Payment
const selectPaymentMethod = ({order}) => order.paymentMethod;
const checkCanCompleteOrder = state => {
  const {order} = state;
  const {paymentMethodOnlineOptionsIsDirty} = order;

  // Selectors
  const productCode = selectProductCode(state);
  const paymentMethod = selectPaymentMethod(state);

  if (productCode === '1') {
    return paymentMethod;
  }

  return paymentMethod === 'online-payment'
    ? paymentMethodOnlineOptionsIsDirty
    : paymentMethod;
};

const selectOrderValue = state => {
  const {orderFuel, orderBenefit} = state;

  // Selectors
  const productCode = selectProductCode(state);

  return productCode === '1'
    ? orderBenefit.orderCalculations.invoiceTotal
    : orderFuel.orderWillingToPay || orderFuel.orderData.totalToPay;
};

export default {
  selectRows,
  selectNumberOfRows,
  selectNumberOfDraftRows,
  selectOrderPayload,
  selectContactDetails,
  selectPaymentMethod,
  checkCanCompleteOrder,
  selectOrderValue,
};
