import {useStoreMock} from 'config';
import merge from 'deepmerge';
import storeMockRoot from '../storeMockRoot';

const initial = {
  ui: {
    isLoading: null,
    isProgress: null,
    error: null,
    errorNeverHide: null,
    errorCloseDisabled: null,
  },
  settings: {
    tenant: null,
    sessionId: null,
    currentLanguage: null,
    translations: null,
  },
};

const state = useStoreMock ? merge(initial, storeMockRoot) : initial;

export default state;
