import React, {PureComponent} from 'react';
// Components - local
import ToasterError from './ToasterError';

export default WrappedComponent => {
  return class extends PureComponent {
    constructor(props) {
      super(props);

      this.state = {
        errorMessage: null,
        open: props.error || false,
        errorNeverHide: props.errorNeverHide || false,
        errorCloseDisabled: props.errorCloseDisabled || false,
      };

      this.handleClose = this.handleClose.bind(this);
    }

    componentDidUpdate(prevProps) {
      const {error, errorNeverHide, errorCloseDisabled} = this.props;

      if (error !== prevProps.error) {
        this.setState({
          errorNeverHide,
          errorCloseDisabled,
          open: (error && true) || false,
          // Keep in local state the last error message - transition fix
          errorMessage: error || prevProps.error,
        });
      }
    }

    handleClose(event, reason) {
      if (reason !== 'clickaway') {
        const {dismissServerError} = this.props;
        dismissServerError();
        this.setState({open: false});
      }
    }

    render() {
      const {open, errorMessage, errorNeverHide, errorCloseDisabled} = this.state;

      return (
        <>
          {errorMessage && (
            <ToasterError
              open={open}
              onClose={this.handleClose}
              error={errorMessage}
              neverHide={errorNeverHide}
              closeDisabled={errorCloseDisabled}
            />
          )}
          <WrappedComponent {...this.props} />
        </>
      );
    }
  };
};
