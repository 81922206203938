import React, {useState} from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
// React Intl
import {FormattedMessage} from 'react-intl';
// Utils
import {objectClone} from 'utils';
// ContactUs Modal
import ContactUsModal from './components/ContactUsModal';
import ButtonAnchor from '../ButtonAnchor';

const useStyles = makeStyles(theme => ({
  contactUsWrapper: {
    marginBottom: theme.spacing(1),
  },
}));

const ContactUs = ({formData}) => {
  const [modalStatus, setModalStatus] = useState(false);
  const classes = useStyles();
  const handleStatus = () => setModalStatus(!modalStatus);
  const blankFormData = objectClone(formData);

  return (
    <>
      <ContactUsModal
        status={modalStatus}
        formData={formData}
        blankFormData={blankFormData}
        handleStatus={handleStatus}
      />
      <div className={classes.contactUsWrapper}>
        <ButtonAnchor handleClick={handleStatus}>
          <FormattedMessage id='global.contact-us_trigger_text' />
        </ButtonAnchor>
      </div>
    </>
  );
};

export default ContactUs;
