import {
  SET_ORDER_ID,
  SET_ORDER_DATA,
  SET_ORDER_WILLING_TO_PAY,
} from '../actions/orderFuel';
import initialStates from './initialStates';

const initialState = initialStates.orderFuel;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_ID:
      return {
        ...state,
        orderId: action.value,
      };

    case SET_ORDER_DATA:
      return {
        ...state,
        orderData: action.value,
      };

    case SET_ORDER_WILLING_TO_PAY:
      return {
        ...state,
        orderWillingToPay: action.value,
      };

    default:
      return state;
  }
};
