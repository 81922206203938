import {createSelector} from 'reselect';
import commonSelectors from './common';

const {
  selectPaymentType,
  selectBeneficiariesRange,
  selectCalendarUnitRange,
  selectAllowanceRange,
} = commonSelectors;

const selectInputsRanges = rangeType =>
  createSelector(
    [selectCalendarUnitRange, selectAllowanceRange, selectBeneficiariesRange],
    (calendarUnit, allowance, numberEmployees) => ({
      calendarUnit: calendarUnit[rangeType],
      allowance: allowance[rangeType],
      numberEmployees: numberEmployees[rangeType],
    }),
  );

export {selectInputsRanges};

export default {
  selectPaymentType,
};
