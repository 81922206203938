const schemaObject = (type, p) => ({
  type,
  params: typeof p === 'string' ? [p] : p,
});

// Validations:
export const required = message => schemaObject('required', message);

export const error = message => schemaObject('typeError', message);

export const min = (message, minValue) => schemaObject('min', [minValue, message]);

export const max = (message, maxValue) => schemaObject('max', [maxValue, message]);

export const integer = message => schemaObject('integer', message);

export const positive = message => schemaObject('positive', message);

export const matches = (message, regex) => schemaObject('matches', [regex, message]);

export const length = (message, lengthValue) =>
  schemaObject('length', [lengthValue, message]);

export const when = (fieldId, settings) => schemaObject('when', [fieldId, settings]);

export const oneOf = message => schemaObject('oneOf', [[true], message]);

export const notOneOf = (message, arr) => schemaObject('notOneOf', [arr, message]);

export const minPrice = (message, minValue) =>
  schemaObject('minPriceWithCurrency', [minValue, message]);

export const maxPrice = (message, maxValue) =>
  schemaObject('maxPriceWithCurrency', [maxValue, message]);

export const matchingValues = (value1, value2, message) =>
  schemaObject('matchingValues', [value1, value2, message]);

export default null;
