import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/icons/Language';
// Components - local
import CustomSettingsTurkey from './CustomSettingsTurkey';

const useStyles = makeStyles({
  button: {
    textDecoration: 'none',
    color: 'black',
  },
});

const customSettingsTenant = 'tr';

export default props => {
  const classes = useStyles();
  const {availableTenants} = props;

  return (
    <List>
      {availableTenants.map((tenant, key) => (
        <Fragment key={key}>
          <NavLink to={`/${tenant}`} className={classes.button}>
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <Icon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={tenant.toUpperCase()} />
            </ListItem>
          </NavLink>
          {tenant === customSettingsTenant && (
            <CustomSettingsTurkey navLinkClass={classes.button} tenant={tenant} />
          )}
        </Fragment>
      ))}
    </List>
  );
};
