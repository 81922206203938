import React from 'react';
import rangeSettings from 'validations/rangeSettings';
import NumberFormat from 'react-number-format';
import maskRangeIsAllowed from '../maskRangeIsAllowed';

export default props => {
  const {
    inputRef,
    settings,
    maskErrorHighlightForRange = false,
    maskRange,
    maskRangeId,
    errorHighlightCallback,
    ...other
  } = props;
  const validationRanges = rangeSettings(settings);

  // Don't need to spread unuseful NumberFormat component props
  delete other.errorHighlightCallback;
  delete other.maskRangeId;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      displayType='number'
      allowNegative={false}
      isAllowed={({floatValue}) =>
        maskRangeIsAllowed(
          floatValue,
          maskRange,
          maskRangeId,
          validationRanges,
          errorHighlightCallback,
          maskErrorHighlightForRange,
        )
      }
      decimalSeparator={false}
    />
  );
};
