import React from 'react';
import {delay} from 'config';

const minDelay = delay.routerLoad;

const commonPromiseLazyLoad = file =>
  Promise.all([file, new Promise(resolve => setTimeout(resolve, minDelay))]).then(
    ([moduleExports]) => moduleExports,
  );

const routerStaticPageLazyLoad = staticPageName =>
  React.lazy(() => {
    const staticPagePath = `scenarios/components/StaticPages/${staticPageName}`;
    const staticPageFile = import(`../${staticPagePath}`);

    return commonPromiseLazyLoad(staticPageFile);
  });

const routerSceneLazyLoad = (scene, scenarioName) =>
  React.lazy(() => {
    const scenePath = scenarioName
      ? `scenarios/${scenarioName}/scenes`
      : 'scenarios/components/scenes';
    const sceneFile = import(`../${scenePath}/${scene}`);

    return commonPromiseLazyLoad(sceneFile);
  });

export {routerSceneLazyLoad, routerStaticPageLazyLoad};
