import React from 'react';
// Mui components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PrevIcon from '@material-ui/icons/ChevronLeft';
import NextIcon from '@material-ui/icons/ChevronRight';

// React Intl HOC
import {injectIntl} from 'react-intl';

const StepperControls = props => {
  const {
    next,
    prev,
    nextDisabled,
    prevDisabled,
    translationIdentifierPrefix,
    intl,
  } = props;

  return (
    <Grid justifyContent={!prevDisabled ? 'space-between' : 'flex-end'} container>
      {!prevDisabled && (
        <Grid item>
          <Button
            color='primary'
            disabled={prevDisabled}
            startIcon={<PrevIcon />}
            onClick={prev}
          >
            {intl.formatMessage({
              id: `${translationIdentifierPrefix}_stepper-controls_previous`,
            })}
          </Button>
        </Grid>
      )}

      {!nextDisabled && (
        <Grid item>
          <Button
            variant='contained'
            color='primary'
            disabled={nextDisabled}
            endIcon={<NextIcon />}
            onClick={next}
          >
            {intl.formatMessage({
              id: `${translationIdentifierPrefix}_stepper-controls_next`,
            })}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default injectIntl(StepperControls);
