import {
  SET_TENANT,
  UPDATE_SETTINGS,
  UPDATE_TRANSLATIONS,
  SET_SESSION_ID,
  SET_CURRENT_LANGUAGE,
} from '../actions/settings';
import initialStates from './initialStates';

const initialState = initialStates.settings;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_TENANT:
      return {
        ...state,
        tenant: action.value,
      };

    case UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
      };

    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.value,
      };

    case SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.value,
      };

    default:
      return state;
  }
};
