import SummaryTableFuel from './SummaryTableFuel';
import SummaryPaymentTableBenefit from './SummaryPaymentTableBenefit';
import PaymentMethodsFuel from './PaymentMethods/PaymentMethodsFuel';
import PaymentMethodsBenefit from './PaymentMethods/PaymentMethodsBenefit';
import PaymentStatusFuel from './PaymentStatus/PaymentStatusFuel';
import PaymentStatusBenefit from './PaymentStatus/PaymentStatusBenefit';

export default {
  fuel: {
    SummaryTableFuel,
    PaymentMethodsFuel,
    PaymentStatusFuel,
  },
  benefit: {
    SummaryPaymentTableBenefit,
    PaymentMethodsBenefit,
    PaymentStatusBenefit,
  },
};
