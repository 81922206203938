/*
givenPath:    "price-simulator"
match.path:   "/tr/contact-info"
returns:      "/tr/price-simulator"
 */

export default (givenPath, match) => {
  const {path} = match;
  const lastPath = path.split('/').pop();

  return path.replace(lastPath, givenPath);
};
