import config from 'config';
import * as yup from 'yup';
import createYupSchema from 'validations/createYupSchema';
import Presets from 'validations/presets';
import {convertCurrencyStringToFloat} from 'utils';

export default (data, presets, settings, dynamicValidationOptions) => {
  const {tenant, currency} = settings;
  const {tenantCurrencyMaskSettings} = config[tenant];

  const currencyStrToFloat = value =>
    convertCurrencyStringToFloat(value, currency, tenantCurrencyMaskSettings);

  // Custom validations
  yup.addMethod(yup.string, 'minPriceWithCurrency', function minPriceWithCurrency(
    limitValue,
    message,
  ) {
    return this.test(
      'minPriceWithCurrency',
      message,
      value => currencyStrToFloat(value) >= limitValue,
    );
  });

  yup.addMethod(yup.string, 'maxPriceWithCurrency', function maxPriceWithCurrency(
    limitValue,
    message,
  ) {
    return this.test(
      'maxPriceWithCurrency',
      message,
      value => currencyStrToFloat(value) <= limitValue,
    );
  });

  yup.addMethod(yup.mixed, 'matchingValues', function matchingValues(
    value1,
    value2,
    message,
  ) {
    return this.test('equals', message, () => value1 === value2);
  });

  const myPresets = new Presets(presets, settings, dynamicValidationOptions);

  // Parse data object
  Object.keys(data).forEach(group => {
    data[group].forEach((f, i) => {
      const {validationPreset, validationType = 'string', validationOptions} = f;
      if (validationPreset) {
        const validations = myPresets[validationPreset](
          data[group][i].name,
          validationOptions,
        );

        data[group][i] = {
          ...data[group][i],
          validationType,
          validations,
        };
      }
    });
  });

  // No Sort Edges for Yup shape
  const noSortEdgesData = {};
  Object.keys(data).forEach(group => {
    noSortEdgesData[group] = [];
    data[group].forEach(f => {
      const {name, validationOptions} = f;
      const hasSortEdges = validationOptions?.noSortEdges;

      if (!hasSortEdges) return;

      noSortEdgesData[group].push(name);
    });
  });

  // Validators - dynamic yup schema: parse formData and declare initialValues and validateSchema
  const validationSchema = {};
  Object.keys(data).forEach(group => {
    data[group].forEach(() => {
      const yepSchema = data[group].reduce(createYupSchema, {});
      validationSchema[group] = yup.object().shape(yepSchema, noSortEdgesData[group]);
    });
  });

  // return simple schema with simple 'root' object key or nested schema: e.g. 'rootCollection' group
  return validationSchema.root
    ? validationSchema.root
    : yup.object().shape(validationSchema);
};
