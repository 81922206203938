import React from 'react';
import {isDev} from 'config';
// React Intl
import {FormattedMessage} from 'react-intl';
// Components - global
import ToasterMessage from 'components/Toasters/ToasterMessage';

export default props => {
  const {error, open, onClose, neverHide = false, closeDisabled} = props;

  const onCloseAlert = closeDisabled ? null : onClose;

  // Error Messages
  const {status} = error;
  const {messages, message} = error;
  const defaultId = typeof error === 'string' ? error : 'GENERIC_500';
  let errorMessage = <FormattedMessage id={`error.${defaultId}`} />;
  let supportMessage;

  // Backend error message case as array
  if (messages && messages.length > 0) {
    if (status === 'failed') {
      // Currently we support only 1 toast so we will display only the first error from BE
      // TODO: we will implement notistack library to handle this requirement.
      const {code, text, supportId} = messages[0];
      errorMessage = <FormattedMessage id={`error.${code}`} defaultMessage={text} />;
      supportMessage = supportId && (
        <FormattedMessage id='error.FE_support-id' values={{ID: supportId}} />
      );
    }
  }

  // Error message thrown with new error
  if (message) {
    errorMessage = <FormattedMessage id={`error.${message}`} />;
  }

  if (isDev && message) {
    console.error(message);
  }

  return (
    <ToasterMessage
      neverHide={neverHide}
      status={open}
      onClose={onClose}
      onCloseAlert={onCloseAlert}
      messageText={errorMessage}
      messageSecondaryText={supportMessage}
    />
  );
};
