import React from 'react';

// Mui components
import CircularProgress from '@material-ui/core/CircularProgress';

const options = {
  large: {
    size: 40,
    thickness: 3,
  },
  normal: {
    size: 24,
  },
};

export default ({large, classes}) => (
  <CircularProgress
    {...(large ? options.large : options.normal)}
    classes={{root: classes}}
  />
);
