import React from 'react';
// Mui components
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  rowBg: props => ({
    backgroundColor: props.rowBg,
  }),
  columnBg: props => ({
    backgroundColor: props.columnBg,
  }),
}));

const Row = ({data}) => {
  const {label, values, rowBg, columnBg, columnBgIndex} = data;

  const val = Array.isArray(values) ? values : [values];

  const classes = useStyles({
    rowBg,
    columnBg,
    columnBgIndex,
  });

  return (
    <TableRow className={classes.rowBg}>
      <TableCell
        component='th'
        scope='row'
        className={columnBgIndex === 0 ? classes.columnBg : ''}
      >
        {label}
      </TableCell>
      {val.map((value, key) => (
        <TableCell
          key={key}
          align='right'
          className={columnBgIndex === key + 1 ? classes.columnBg : ''}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default Row;
