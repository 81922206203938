export const SET_CURRENT_SCENE = '[TURKEY_PRODUCTS] SET_CURRENT_SCENE';
export const SET_NAVIGATION_STEP_LEGEND_VISIBILITY =
  '[TURKEY_PRODUCTS] SET_NAVIGATION_STEP_LEGEND_VISIBILITY';
export const SET_NAVIGATION_STEP_ACTIVE = '[TURKEY_PRODUCTS] SET_NAVIGATION_STEP_ACTIVE';
export const SET_NAVIGATION_STEPS_VISIBILITY =
  '[TURKEY_PRODUCTS] SET_NAVIGATION_STEPS_VISIBILITY';
export const NAVIGATION_NEXT_TRIGGER = '[TURKEY_PRODUCTS] NAVIGATION_NEXT_TRIGGER';
export const NAVIGATION_PREV_TRIGGER = '[TURKEY_PRODUCTS] NAVIGATION_PREV_TRIGGER';
export const CLEAR_NAVIGATION_TRIGGERS = '[TURKEY_PRODUCTS] CLEAR_NAVIGATION_TRIGGERS';
export const SET_NAVIGATION_NEXT_DISABLED =
  '[TURKEY_PRODUCTS] SET_NAVIGATION_NEXT_DISABLED';
export const SET_NAVIGATION_PREV_DISABLED =
  '[TURKEY_PRODUCTS] SET_NAVIGATION_PREV_DISABLED';
export const SET_NAVIGATION_VISIBILITY = '[TURKEY_PRODUCTS] SET_NAVIGATION_VISIBILITY';
export const SET_NAVIGATION_NEXT_SCENE_PATH =
  '[TURKEY_PRODUCTS] SET_NAVIGATION_NEXT_SCENE_PATH';
export const SET_NAVIGATION_PREV_SCENE_PATH =
  '[TURKEY_PRODUCTS] SET_NAVIGATION_PREV_SCENE_PATH';
export const SET_FLOW_PATHS = '[TURKEY_PRODUCTS] SET_FLOW_PATHS';
export const SET_FLOW_MAX_DEPTH = '[TURKEY_PRODUCTS] SET_FLOW_MAX_DEPTH';

const setCurrentScene = value => ({
  type: SET_CURRENT_SCENE,
  value,
});

const setNavigationLegendVisibility = value => ({
  type: SET_NAVIGATION_STEP_LEGEND_VISIBILITY,
  value,
});

const setNavigationStepActive = value => ({
  type: SET_NAVIGATION_STEP_ACTIVE,
  value,
});

const setNavigationStepsVisibility = value => ({
  type: SET_NAVIGATION_STEPS_VISIBILITY,
  value,
});

const navigationNextTrigger = () => ({
  type: NAVIGATION_NEXT_TRIGGER,
});

const navigationPrevTrigger = () => ({
  type: NAVIGATION_PREV_TRIGGER,
});

const clearNavigationTriggers = () => ({
  type: CLEAR_NAVIGATION_TRIGGERS,
});

const setNavigationNextDisabled = value => ({
  type: SET_NAVIGATION_NEXT_DISABLED,
  value,
});

const setNavigationPrevDisabled = value => ({
  type: SET_NAVIGATION_PREV_DISABLED,
  value,
});

const setNavigationVisibility = value => ({
  type: SET_NAVIGATION_VISIBILITY,
  value,
});

const setNavigationNextScenePath = value => ({
  type: SET_NAVIGATION_NEXT_SCENE_PATH,
  value,
});

const setNavigationPrevScenePath = value => ({
  type: SET_NAVIGATION_PREV_SCENE_PATH,
  value,
});

const setFlowPaths = value => ({
  type: SET_FLOW_PATHS,
  value,
});

const setFlowMaxDepth = value => ({
  type: SET_FLOW_MAX_DEPTH,
  value,
});

const checkFlowDepth = checkedPath => (dispatch, getState) => {
  const currentState = () => getState().flow;

  const {flowPaths, currentScene, flowMaxDepth} = currentState();

  if (!flowPaths) return Promise.resolve(false);

  const flowSteps = flowPaths.map(r => r);
  const checkedStep = flowSteps.indexOf(checkedPath);
  const currentStep = flowSteps.indexOf(currentScene);
  let flowDepth = flowSteps.indexOf(flowMaxDepth);
  // If depth is negative, declare it 0
  flowDepth = flowDepth < 0 ? 0 : flowDepth;

  return Promise.resolve(
    (() => {
      if (checkedPath) {
        return checkedStep < 0 ? false : flowDepth >= checkedStep;
      }

      return flowDepth <= currentStep;
    })(),
  );
};

const updateFlowMaxDepth = path => dispatch => {
  dispatch(checkFlowDepth()).then(
    isReached => isReached && dispatch(setFlowMaxDepth(path)),
  );
};

export default {
  setCurrentScene,
  setNavigationLegendVisibility,
  setNavigationStepActive,
  setNavigationStepsVisibility,
  setFlowMaxDepth,
  navigationNextTrigger,
  navigationPrevTrigger,
  clearNavigationTriggers,
  setNavigationNextDisabled,
  setNavigationPrevDisabled,
  setNavigationVisibility,
  setNavigationNextScenePath,
  setNavigationPrevScenePath,
  setFlowPaths,
  updateFlowMaxDepth,
  checkFlowDepth,
};
