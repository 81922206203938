/*
  withMerge = true (priceSimulatorFuel format)
  input: [
    [{id: "contact_first_name"}],
    [{id: "contact_last_name"}],
  ];
  output: {
    contact_first_name: "...",
    contact_last_name: "...",
  };

  -------------------------

  withMerge = false
  input: [
    {id: "contact_first_name"},
    {id: "contact_last_name"},
  ];
  output: {
    contact_first_name: "...",
    contact_last_name: "...",
  };
*/

export default (data, withMerge = false) => {
  let formData = withMerge ? [] : data;
  const updatedFormData = {};

  if (withMerge) {
    data.forEach(formLine => (formData = [...formData, ...formLine]));
  }

  formData.forEach(field => {
    updatedFormData[field.id || field.name] = field.value || '';
  });

  return updatedFormData;
};
