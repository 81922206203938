import root from './root';
import api from './api';
import ui from './ui';
import settings from './settings';
import contactUs from './contactUs';

export {
  api as apiActions,
  ui as uiActions,
  settings as settingsActions,
  contactUs as contactUsActions,
};

export default {
  root,
  api,
  ui,
  settings,
  contactUs,
};
