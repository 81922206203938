import React from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
// Components - global
import SceneTitle from 'components/Scene/Title';
// Components - local
import {FormattedHTMLMessage} from 'react-intl';
import IconStatus from './IconStatus';
// React Intl

// Styles
const useStyles = makeStyles(() => ({
  description: {
    textAlign: 'left',
    '& ul, & ol': {
      listStylePosition: 'inside',
      margin: 0,
      padding: 0,
    },
  },
}));

export default ({
  translationIdentifierPrefix,
  paymentStatus,
  withSubtitle,
  orderValue,
}) => (
  <>
    <IconStatus type={paymentStatus} />

    <SceneTitle translationIdentifierPrefix={translationIdentifierPrefix} />

    {withSubtitle && (
      <SceneTitle
        onlySubtitle
        withSubtitle
        translationIdentifierPrefix={`${translationIdentifierPrefix}`}
        subtitleVariant='h3'
      />
    )}

    <div className={useStyles().description}>
      <FormattedHTMLMessage
        id={`${translationIdentifierPrefix}_description`}
        values={{VALUE: orderValue}}
      />
    </div>
  </>
);
