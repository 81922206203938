import React from 'react';
import {compose} from 'redux';
// Components - global
import PaymentMethods from 'ComponentsForm/PaymentMethods';
// Components - local
import {injectIntl} from 'react-intl';
import Disclaimer from './components/Disclaimer';
// React Intl

export default compose(injectIntl)(
  ({
    intl,
    availablePaymentMethods,
    translationIdentifierPrefix,
    paymentMethod,
    setPaymentMethod,
    legendIntl,
  }) => {
    // Intl
    const translation = `${translationIdentifierPrefix}_payment-methods-selector`;

    const paymentMethodChildren = {
      'bank-transfer': (
        <Disclaimer alertIntl={`${translation}_disclaimer-text_bank-transfer`} />
      ),
    };

    const paymentMethodsData = availablePaymentMethods.map(method => ({
      value: method,
      label: intl.formatMessage({
        id: `${translation}_label_${method}`,
      }),
      checked: method === paymentMethod,
      children: paymentMethodChildren[method],
    }));

    return (
      <PaymentMethods
        translationIdentifierPrefix={translationIdentifierPrefix}
        legendIntl={legendIntl}
        data={paymentMethodsData}
        onChange={(event, value) => setPaymentMethod(value)}
      />
    );
  },
);
