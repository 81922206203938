import React from 'react';
import {connect} from 'react-redux';

import currencyValue from './currencyValue';

const mapStateToProps = state => ({
  tenant: state.root.settings.tenant,
  currency: state.root.settings.currency,
});

export default connect(mapStateToProps)(
  ({value, tenant, currency, disabledDecimals, className}) => {
    const currencyVal = currencyValue(value, tenant, currency, disabledDecimals);

    return className ? <span className={className}>{currencyVal}</span> : currencyVal;
  },
);

export {currencyValue};
