/* eslint-disable camelcase */
import rootActions from 'store/actions';
import selectors from '../selectors';

export const SET_PAYMENT_METHOD = '[TURKEY_PRODUCTS/ORDER] SET_PAYMENT_METHOD';
export const SET_PAYMENT_INITIATED = '[TURKEY_PRODUCTS/ORDER] SET_PAYMENT_INITIATED';
export const SET_PAYMENT_METHOD_ONLINE_OPTIONS_IS_DIRTY =
  '[TURKEY_PRODUCTS/ORDER] SET_PAYMENT_METHOD_ONLINE_OPTIONS_IS_DIRTY';

const setPaymentMethod = value => ({
  type: SET_PAYMENT_METHOD,
  value,
});

const setPaymentMethodOnlineOptionsIsDirty = value => ({
  type: SET_PAYMENT_METHOD_ONLINE_OPTIONS_IS_DIRTY,
  value,
});

const setPaymentInitiated = value => ({
  type: SET_PAYMENT_INITIATED,
  value,
});

const serverOrderData = (payload, orderId) => dispatch => {
  // Create order
  if (!orderId) {
    return dispatch(rootActions.api.makeRequest('order/createOrder', [payload])).then(
      response => {
        const {requestCancelled} = response;
        const orderResponse = response.data?.data;

        if (requestCancelled) return false;

        return {
          hasCreatedOrder: true,
          orderId: response.data.data.functional_reference,
          orderResponse,
        };
      },
    );
  }

  // Update order
  return dispatch(
    rootActions.api.makeRequest('order/updateOrder', [orderId, payload]),
  ).then(response => {
    const {requestCancelled} = response;
    const orderResponse = response.data?.data;

    if (requestCancelled) return false;

    return {orderResponse};
  });
};

const serverFileDownload = () => (dispatch, getState) => {
  // Selectors
  const {selectProductCode, selectMediumType, selectPaymentType} = selectors;
  const productCode = selectProductCode(getState());
  const mediumType = selectMediumType(getState());
  const paymentType = selectPaymentType(getState(), true);

  const fileUploadRequestParameters = [productCode, mediumType];

  if (productCode === '1') {
    fileUploadRequestParameters.push(paymentType);
  }

  return dispatch(
    rootActions.api.makeRequest('order/fileDownload', fileUploadRequestParameters),
  ).then(response => response.data);
};

const serverFileUpload = fileBuffer => (dispatch, getState) => {
  // Selectors
  const {selectProductCode, selectMediumType, selectPaymentType} = selectors;
  const productCode = selectProductCode(getState());
  const mediumType = selectMediumType(getState());
  const paymentType = selectPaymentType(getState(), true);

  const requestParameters = [fileBuffer, productCode];

  if (productCode === '1') {
    requestParameters.push(mediumType, paymentType);
  }

  return dispatch(
    rootActions.api.makeRequest('order/fileUpload', requestParameters),
  ).then(response => response.data.data);
};

const serverOrderSubmit = orderId => dispatch =>
  dispatch(rootActions.api.makeRequest('order/status', [orderId, 'new']));

const serverCreatePayment = (orderId, payload) => (dispatch, getState) => {
  // Selectors
  const {selectPaymentMethod} = selectors;
  const paymentMethod = selectPaymentMethod(getState());

  // Enums mapping (to do)
  const paymentMethodRequest = paymentMethod.replaceAll('-', '_');

  return dispatch(
    rootActions.api.makeRequest('payment/createPayment', [
      {
        order_id: orderId,
        payment_type: paymentMethodRequest,
        ...payload,
      },
    ]),
  ).then(response => {
    const paymentResponse = response.data.data;
    const {state, payment_URL} = paymentResponse;

    return {
      externalUrl: payment_URL,
      status: state,
    };
  });
};

const serverPaymentStatus = (orderId, payload) => dispatch => {
  const {status} = payload;

  const requestBody = {
    payment_status: status,
  };

  return dispatch(
    rootActions.api.makeRequest('order/updateOrderPaymentStatus', [orderId, requestBody]),
  );
};

const serverOrderCompletion = (orderId, paymentStatus, payload) => (
  dispatch,
  getState,
) => {
  const {consents} = getState();
  const {acceptedCommercials} = consents;

  return dispatch(
    rootActions.api.makeRequest('notifications/orderCompletion', [
      {
        order_id: orderId,
        payment_status: paymentStatus,
        commercial_communication: acceptedCommercials,
        ...payload,
      },
    ]),
  );
};

export default {
  setPaymentMethod,
  setPaymentMethodOnlineOptionsIsDirty,
  setPaymentInitiated,
  serverOrderData,
  serverFileDownload,
  serverFileUpload,
  serverOrderSubmit,
  serverCreatePayment,
  serverOrderCompletion,
  serverPaymentStatus,
};
