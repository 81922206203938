import _ from 'lodash';
import {objectClone, convertStringToInt, removePropertiesFromObject} from 'utils';
import orderActions from './order';
import selectors from '../selectors';

export const CLEAR_ORDER = '[TURKEY_PRODUCTS/ORDER_BENEFIT] CLEAR_ORDER';
export const CLEAR_ORDER_WITHOUT_ID =
  '[TURKEY_PRODUCTS/CLEAR_ORDER_WITHOUT_ID] CLEAR_ORDER_WITHOUT_ID';
export const SET_IS_DIRTY = '[TURKEY_PRODUCTS/ORDER_BENEFIT] SET_IS_DIRTY';
export const SET_ORDER_ID = '[TURKEY_PRODUCTS/ORDER_BENEFIT] SET_ORDER_ID';
export const SET_ORDER_DATA = '[TURKEY_PRODUCTS/ORDER_BENEFIT] SET_ORDER_DATA';
export const SET_ORDER_CALCULATIONS =
  '[TURKEY_PRODUCTS/ORDER_BENEFIT] SET_ORDER_CALCULATIONS';

const clearOrder = () => ({
  type: CLEAR_ORDER,
});

const clearOrderWithoutId = () => ({
  type: CLEAR_ORDER_WITHOUT_ID,
});

const setIsDirty = value => ({
  type: SET_IS_DIRTY,
  value,
});

const columnsNameToId = columns => {
  // Enums mapping (to do)
  // Columns backend notation mapping: name to id
  const nameToId = {};

  columns.forEach(({id, name}) => {
    nameToId[name] = id;
  });

  return nameToId;
};

const setOrderId = value => ({
  type: SET_ORDER_ID,
  value,
});

const setOrderData = value => ({
  type: SET_ORDER_DATA,
  value,
});

const setOrderCalculations = value => ({
  type: SET_ORDER_CALCULATIONS,
  value,
});

const orderData = (payload, columns) => async (dispatch, getState) => {
  const {orderBenefit} = getState();
  const {orderId, isDirty} = orderBenefit;
  // Selectors
  const {
    selectOrderPayload,
    selectPaymentType,
    selectMediumType,
    selectCalendarUnit,
    selectEmployeeListOrderValues,
  } = selectors;

  const {orderState, orderRequest} = selectOrderPayload(getState(), payload);
  const paymentType = selectPaymentType(getState(), true);
  const mediumType = selectMediumType(getState());
  const calendarUnit = selectCalendarUnit(getState());
  const employeeListOrderValues = orderValuesPayload =>
    selectEmployeeListOrderValues(getState(), orderValuesPayload);

  const orderStateClone = objectClone(orderState);

  const columnsMap = columnsNameToId(columns);

  if (!isDirty) {
    dispatch(setIsDirty(true));
  }

  // State Payload ---------------------------------------
  orderState.order_request_lines.forEach(line => {
    if (paymentType === 'day') {
      const {total, 'daily-value': dailyValue} = employeeListOrderValues({
        loadValue: line['daily-value'],
        days: line.days,
      });

      line['daily-value'] = dailyValue;
      line.total = total;
    } else if (paymentType === 'month') {
      const {'monthly-value': monthlyValue} = employeeListOrderValues({
        loadValue: line['monthly-value'],
      });

      line['monthly-value'] = monthlyValue;
    }
  });

  // -----------------------------------------------------

  // Backend Payload -------------------------------------
  // Add time unit
  orderRequest.time_unit = paymentType;

  // Clean all order_request_lines before parsing
  orderRequest.order_request_lines = [];

  // orderRequest parse
  orderStateClone.order_request_lines.forEach(line => {
    const numberOfVouchers = paymentType === 'month' ? calendarUnit : line.days;

    // Remove fields
    delete line.days;
    delete line.total;

    // Rename field keys with columns backend notation mapping from 'columnsMap'
    const updatedLine = _.mapKeys(line, (value, key) => columnsMap[key]);

    // Add or change fields
    updatedLine.medium_type = mediumType;
    updatedLine.number_of_vouchers = numberOfVouchers;
    updatedLine.amount = convertStringToInt(updatedLine.amount);

    // Push new updated line
    orderRequest.order_request_lines.push(updatedLine);
  });
  // -----------------------------------------------------

  // Update state and backend request --------------------
  dispatch(setOrderData(orderState));

  // If order has 0 rows, clear order calculations
  if (!orderState.order_request_lines.length) {
    dispatch(setOrderCalculations({}));
  }

  return dispatch(orderActions.serverOrderData(orderRequest, orderId)).then(response => {
    if (!response) return;

    const {hasCreatedOrder, orderResponse} = response;
    const orderMap = {
      total_fees_value: 'commissionNet',
      total_loaded_value: 'orderNet',
      total_to_pay: 'invoiceTotal',
      commission_vat: 'commissionVat',
      order_vat: 'orderVat',
    };
    const orderCalculationsState = {};
    const orderCalculationsResponse = hasCreatedOrder
      ? orderResponse.valueing
      : orderResponse;

    if (hasCreatedOrder) {
      dispatch(setOrderId(response.orderId));
    }

    Object.keys(orderCalculationsResponse).forEach(
      o => (orderCalculationsState[orderMap[o]] = orderCalculationsResponse[o]),
    );

    removePropertiesFromObject(orderCalculationsState, null);

    dispatch(setOrderCalculations(orderCalculationsState));
  });
  // -----------------------------------------------------
};

const orderDownload = () => dispatch => dispatch(orderActions.serverFileDownload());

const orderUpload = (uploadPayload, columns) => (dispatch, getState) => {
  // Selectors
  const {selectPaymentType, selectEmployeeListOrderValues} = selectors;
  const paymentType = selectPaymentType(getState());
  const employeeListOrderValues = payload =>
    selectEmployeeListOrderValues(getState(), payload);

  const columnsMap = columnsNameToId(columns);

  return dispatch(orderActions.serverFileUpload(uploadPayload)).then(
    fileUploadResponse => {
      const parsedResponse = [];

      fileUploadResponse.forEach(row => {
        let parsedRow = {};

        Object.keys(row).map(objectKey => {
          // Enums mapping (to do)
          const parsedKey = _.findKey(columnsMap, o => o === objectKey);

          // Common row fields from columnsMap
          if (parsedKey) {
            parsedRow[parsedKey] = row[objectKey];
          }

          // Number of vouchers
          if (paymentType === 'daily') {
            parsedRow.days = row.number_of_vouchers;
          }

          // Order values
          const orderValues = employeeListOrderValues({
            days: row.number_of_vouchers,
            loadValue: row.load_value,
          });

          return (parsedRow = {...parsedRow, ...orderValues});
        });

        parsedResponse.push(parsedRow);
      });

      return parsedResponse;
    },
  );
};

const orderSubmit = () => (dispatch, getState) => {
  const {orderBenefit} = getState();
  const {orderId} = orderBenefit;

  return dispatch(orderActions.serverOrderSubmit(orderId));
};

const createPayment = () => (dispatch, getState) => {
  const {orderBenefit} = getState();
  const {orderId} = orderBenefit;

  return dispatch(orderActions.serverCreatePayment(orderId));
};

const orderCompletion = payload => (dispatch, getState) => {
  const {status} = payload;

  const {orderBenefit} = getState();
  const {orderId} = orderBenefit;

  return dispatch(orderActions.serverOrderCompletion(orderId, status));
};

const orderFinished = payload => (dispatch, getState) => {
  const {orderBenefit} = getState();
  const {orderId} = orderBenefit;

  return Promise.all([
    dispatch(orderCompletion(payload)),
    dispatch(orderActions.serverPaymentStatus(orderId, payload)),
  ]);
};

export default {
  clearOrder,
  clearOrderWithoutId,
  setOrderId,
  orderData,
  setOrderCalculations,
  orderDownload,
  orderUpload,
  orderSubmit,
  createPayment,
  orderCompletion,
  orderFinished,
};
