import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

//  Mui components
import {makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(1.5),
    textAlign: 'left',
  },
  heading: {
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  body: {
    fontSize: '1rem',
  },
}));

const HelpAccordion = ({faqItem, itemID, expanded, handleChange}) => {
  const {question, answer} = faqItem;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel expanded={expanded === itemID} onChange={handleChange(itemID)}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${itemID}-content`}
          id={`${itemID}-header`}
        >
          <Typography className={classes.heading}>{question}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography className={classes.body}>{ReactHtmlParser(answer)}</Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
};

HelpAccordion.propTypes = {
  faqItem: PropTypes.exact({
    question: PropTypes.string,
    answer: PropTypes.string,
  }).isRequired,
  itemID: PropTypes.string.isRequired,
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default HelpAccordion;
