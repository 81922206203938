import React, {useState} from 'react';
import TagManager from 'react-gtm-module';

export default WrappedComponent => props => {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  const gtmInit = async googleTrackingId => {
    const {featureToggle} = props;
    const {events} = featureToggle.analytics.gtm;

    if (!googleTrackingId || analyticsEnabled) return;

    await TagManager.initialize({
      gtmId: googleTrackingId,
      events,
    });

    setAnalyticsEnabled(true);
  };

  const gtmDataLayerPush = dataLayer => {
    return TagManager.dataLayer({
      dataLayer,
    });
  };

  return (
    <WrappedComponent {...props} gtmInit={gtmInit} gtmDataLayerPush={gtmDataLayerPush} />
  );
};
