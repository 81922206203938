import React from 'react';
import _ from 'lodash';
// Mui components
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import Collapse from '@material-ui/core/Collapse';

export default props => {
  const {
    label,
    checked,
    onChange,
    color = 'primary',
    id,
    value,
    name,
    withCollapse,
    children,
  } = props;

  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Radio
            checked={checked}
            onChange={ev => onChange(ev, value)}
            color={color}
            id={id}
            value={value}
            name={name}
          />
        }
        value={value}
      />
      <Collapse in={withCollapse && checked && !_.isEmpty(children)}>{children}</Collapse>
    </>
  );
};
