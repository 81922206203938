/* eslint-disable import/newline-after-import */
const logoFuel = require('assets/tr/logo-ticket-car.png');
const logoBenefit = require('assets/tr/logo-ticket-restaurant.svg');

const images = {
  logo: {
    logoFuel,
    logoBenefit,
  },
};

const vendors = [
  {
    id: '1',
    name: 'benefit',
    commercialName: 'Ticket Restaurant',
    type: 'benefit',
  },
  {
    id: '4',
    name: 'petrol-ofisi',
    commercialName: 'Petrol Ofisi',
    type: 'fuel',
  },
  {
    id: '41',
    name: 'total',
    commercialName: 'Total',
    type: 'fuel',
  },
  {
    id: '42',
    name: 'bp',
    commercialName: 'bp',
    type: 'fuel',
  },
];

export default {
  theme: 'green',
  scenario: 'TurkeyProducts',
  productPaths: {
    fuel: 'ticket-car',
    benefit: 'ticket-restaurant',
  },
  visitorFlowName: 'Visitor',
  authFlowName: 'Auth',
  featureToggle: {
    hasRequiredParamsRedirect: {
      isActive: true,
      redirectUri: 'https://edenred.com.tr/',
    },
    hasPrefillFromLeads: true,
    hasPrefillOnContactUs: true,
    hasProductPaths: true,
    hasBasePath: false,
    hasHeaderWithVendor: true,
    hasBannerRedirect: {
      isActive: true,
      storageItemName: 'bannerToken',
    },
    analytics: {
      gtm: {
        hasGtm: true,
        dataLayerUTM: true,
        events: {
          Pageview: 'Page view',
        },
      },
    },
    cookieConsents: {
      hasCookieBanner: true,
    },
    blockSearchEngineIndexing: true,
  },
  options: {
    Header: {
      vendorLogoSettings: {
        width: 50,
        alt: 'Vendor logo',
      },
      logo: {
        width: 150,
        alt: 'Edenred logo',
        Fuel: images.logo.logoFuel,
        Benefit: images.logo.logoBenefit,
      },
    },
    HelpCustomer: {
      disabledOnRoutes: ['order-status'],
    },
    Footer: {
      TopImage: {
        enabledOnRoutes: ['order-payment'],
        imagePath: 'payment-mobil-express.png',
        maxWidth: '160px',
      },
      ContactUs: {
        formData: {
          rootCollection: [
            {
              id: 'first_name',
              name: 'contact-first-name',
              value: '',
              validationPreset: 'personName',
            },
            {
              id: 'last_name',
              name: 'contact-last-name',
              value: '',
              validationPreset: 'personName',
            },
            {
              id: 'contact_company_name',
              name: 'contact-company-name',
              value: '',
              validationPreset: 'companyName',
            },
            {
              id: 'phone',
              name: 'contact-phone',
              value: '',
              validationPreset: 'phoneNumberFlat',
              validationOptions: {
                isRequired: true,
              },
            },
            {
              id: 'email',
              name: 'contact-email',
              value: '',
              validationPreset: 'email',
            },
            {
              id: 'text',
              name: 'contact-message',
              value: '',
              validationPreset: 'contactMessage',
              multiline: true,
              multilineRows: 3,
            },
            {
              id: 'gdpr',
              name: 'gdpr',
              value: 'false',
              validationType: 'bool',
              validationPreset: 'checkbox',
            },
          ],
        },
      },
    },
  },
  layout: {
    containerMaxWidth: 'md',
  },
  appSettingsMap: {
    MobilePhoneRegex: 'regexFormPhoneMobile',
    PlateNumberRegex: 'regexPlateNumber',
    MaxVehicleNbr: 'maxVehiclesNumber',
    MaxBeneficiareNbr: 'maxEmployeesNumber',
    AverageFuelCost: 'fuelCostAverage',
    MinTotalFuelCost: 'fuelCostMin',
    MaxTotalFuelCost: 'fuelCostMax',
    AvailableProductRefs: 'availableVendors',
    ClientExistsInBORedirectURL: 'clientExistsInBoRedirectUrl',
    RangeBeneficieries: 'rangeBeneficiaries',
    RangeNrOfMonths: 'rangeNumberMonths',
    RangeWorkingDays: 'rangeNumberWorkingDays',
    RangeMonthlyAllowance: 'rangeAllowanceMonths',
    RangeFacingValue: 'rangeAllowanceDays',
    NumberOfDaysInMonth: 'workingDaysInMonth',
    DefaultNrOfEmptyRowsOrder: 'defaultNumberOfEmptyRowsOrder',
  },
  appSettingsMock: [
    {
      name: 'AvailableProductRefs',
      value: vendors,
    },
  ],
  persistenceConfig: {
    flowWhitelist: [
      'flow',
      'leads',
      'contact',
      'mediumTypes',
      'priceSimulatorFuel',
      'priceSimulatorBenefit',
      'company',
      'order',
      'orderFuel',
      'orderBenefit',
      'consents',
    ],
  },
  currencyFormat: '{value} {symbol}',
  tenantCurrencyMaskSettings: {
    allowNegative: false,
    allowLeadingZeros: false,
    decimalSeparator: ',',
    thousandSeparator: ' ',
    decimalScale: 2,
    fixedDecimalScale: true,
  },
  vendors,
};
