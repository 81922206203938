import {convertKebabToCamel} from 'utils';

const thankYou = path => ({
  event: 'Pageview',
  pagePath: `/thank-you/${path}`,
  pageTitle: 'Thank You',
});

const pathWithUTMParams = UTM => {
  const {pathname} = window.location;

  // Build page title
  const lastSlash = pathname.lastIndexOf('/');
  const pageTitle = convertKebabToCamel(pathname.substring(lastSlash + 1));

  // Build URL with query params
  const {utmSource, utmMedium, utmContent, utmCampaign} = UTM;
  const pagePath = `${pathname}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_content=${utmContent}&utm_campaign=${utmCampaign}`;

  return {
    event: 'Pageview',
    pagePath,
    pageTitle,
  };
};

export default {thankYou, pathWithUTMParams};
