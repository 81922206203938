import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
// Components - global
import PaymentMethods from 'ComponentsForm/PaymentMethods';
// Components - local
import {injectIntl} from 'react-intl';
import Disclaimer from './components/Disclaimer';
import OnlinePayment from './components/OnlinePayment';
// React Intl

const mapStateToProps = ({order, consents}) => {
  const {paymentMethodOnlineOptionsIsDirty} = order;
  const {acceptedAutoPaymentWithCardSave} = consents;

  return {
    paymentMethodOnlineOptionsIsDirty,
    acceptedAutoPaymentWithCardSave,
  };
};

const mapDispatchToProps = (dispatch, {orderActions, flowActions}) => ({
  setPaymentMethodOnlineOptionsIsDirty: value =>
    dispatch(orderActions.setPaymentMethodOnlineOptionsIsDirty(value)),
  setAcceptedAutoPaymentWithCardSave: value =>
    dispatch(flowActions.consents.setAcceptedAutoPaymentWithCardSave(value)),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(
  ({
    intl,
    availablePaymentMethods,
    translationIdentifierPrefix,
    paymentMethod,
    setPaymentMethod,
    legendIntl,
    paymentMethodOnlineOptionsIsDirty,
    setPaymentMethodOnlineOptionsIsDirty,
    acceptedAutoPaymentWithCardSave,
    setAcceptedAutoPaymentWithCardSave,
  }) => {
    // Intl
    const translation = `${translationIdentifierPrefix}_payment-methods-selector`;

    const onlinePaymentOptionChange = (autoPaymentSelected = false) => {
      if (!paymentMethodOnlineOptionsIsDirty) {
        setPaymentMethodOnlineOptionsIsDirty(true);
      }

      return setAcceptedAutoPaymentWithCardSave(autoPaymentSelected);
    };

    const onlinePaymentOptions = [
      {
        labelId: 'autoPaymentWithCardSave',
        translationKey: 'auto-payment-with-card-save',
        checked: acceptedAutoPaymentWithCardSave,
        handleChangeCallback: () => onlinePaymentOptionChange(true),
      },
      {
        labelId: 'manualPaymentWithoutCardSave',
        translationKey: 'manual-payment-without-card-save',
        checked: !acceptedAutoPaymentWithCardSave && paymentMethodOnlineOptionsIsDirty,
        handleChangeCallback: () => onlinePaymentOptionChange(false),
      },
    ];

    // Payment Methods
    const paymentMethodChildren = {
      'online-payment': (
        <OnlinePayment
          translationIdentifierPrefix={translation}
          paymentOptionsType='radio'
          paymentOptions={onlinePaymentOptions}
        />
      ),
      'bank-transfer': (
        <Disclaimer
          translationIdentifierPrefix={translation}
          alertIntl={`${translation}_disclaimer-text_bank-transfer`}
        />
      ),
    };

    const paymentMethodsData = availablePaymentMethods.map(method => ({
      value: method,
      label: intl.formatMessage({
        id: `${translation}_label_${method}`,
      }),
      checked: method === paymentMethod,
      children: paymentMethodChildren[method],
    }));

    return (
      <PaymentMethods
        translationIdentifierPrefix={translationIdentifierPrefix}
        legendIntl={legendIntl}
        data={paymentMethodsData}
        onChange={(event, value) => setPaymentMethod(value)}
      />
    );
  },
);
