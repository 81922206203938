import React, {PureComponent} from 'react';
import {persistSessionStorageKey} from 'config';
// Components - global
import LoaderOverlay from 'components/Loader/Overlay';

export default class extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasMissingRequiredParams: true,
      requiredParams: {
        productCode: null,
        productType: null,
      },
      optionalParams: {
        product: null,
        utmSource: null,
        utmMedium: null,
        utmContent: null,
        utmCampaign: null,
        subsource: null,
        subSourceDetail: null,
        leadSource: null,
        phone: null,
        mobile: null,
        firstName: null,
        lastName: null,
        email: null,
        company: null,
      },
    };

    this.parseSearchParameters = this.parseSearchParameters.bind(this);
  }

  async componentDidMount() {
    const {storageItemName} = this.props;

    // Clear session storage
    sessionStorage.removeItem(storageItemName);
    sessionStorage.removeItem(persistSessionStorageKey);

    // Parse search parameters and update state
    await this.parseSearchParameters('requiredParams');
    await this.parseSearchParameters('optionalParams');

    // Update session storage if required params from state don't contain null values
    const {requiredParams, optionalParams} = this.state;
    const hasMissingRequiredParams = Object.values(requiredParams).includes(null);
    this.setState({hasMissingRequiredParams});

    if (hasMissingRequiredParams) {
      return;
    }

    sessionStorage.setItem(
      storageItemName,
      JSON.stringify({...requiredParams, ...optionalParams}),
    );
  }

  parseSearchParameters(paramsType) {
    const {state, props} = this;
    const {location} = props;
    const {search} = location;
    const locationSearch = new URLSearchParams(search);
    const paramsKeys = Object.keys(state[paramsType]);

    // Update state after parsing search parameters
    paramsKeys.forEach(param => {
      const paramValue = locationSearch.get(param);
      this.setState(prevState => ({
        [paramsType]: {
          ...prevState[paramsType],
          [param]: paramValue,
        },
      }));
    });
  }

  render() {
    const {props, state} = this;
    const {path, vendors, productPaths, hasRequiredParamsRedirect} = props;
    const {hasMissingRequiredParams} = state;
    const {productCode} = state.requiredParams;
    const currentVendor = vendors.find(v => v.id === productCode);
    const productPath = productPaths[currentVendor?.type];

    // If missing required params redirect to redirectUri when redirect is active, else go on with journey
    const redirectPath = hasMissingRequiredParams
      ? hasRequiredParamsRedirect?.isActive && hasRequiredParamsRedirect.redirectUri
      : path + productPath;

    if (redirectPath) {
      window.location.href = redirectPath;
    }

    return <LoaderOverlay />;
  }
}
