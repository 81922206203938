import React from 'react';
import {compose} from 'redux';
import merge from 'deepmerge';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import {lighten} from 'material-ui/styles/colorManipulator';
import Alert from '@material-ui/lab/Alert';
// React Intl
import {injectIntl} from 'react-intl';

// Styles
const commonStyles = theme => ({
  root: {
    borderRadius: theme.borderRadius.big,
  },
});

const useStylesDefault = makeStyles(theme => {
  const {opacity, palette} = theme;

  const alertColor = color => `${lighten(color, opacity.medium)}`;

  return {
    ...commonStyles(theme),
    message: {
      width: '100%',
    },
    standardWarning: {
      backgroundColor: alertColor(palette.warning.light),
    },
    standardInfo: {
      backgroundColor: alertColor(palette.info.light),
    },
    outlinedWarning: {
      borderColor: alertColor(palette.warning.light),
    },
    outlinedInfo: {
      borderColor: alertColor(palette.info.light),
    },
    outlinedSuccess: {
      borderColor: alertColor(palette.success.light),
    },
  };
});

const useStylesNeutral = (variant, greyTint = 300) =>
  makeStyles(theme =>
    merge(
      commonStyles(theme),
      (() =>
        (variant === 'outlined' && {
          root: {borderColor: theme.palette.grey[greyTint]},
        }) ||
        (variant === 'filled' && {
          root: {
            backgroundColor: theme.palette.grey[greyTint],
            color: theme.palette.themeColors.blueHot,
          },
        }))(),
    ),
  );

export default compose(injectIntl)(
  ({
    intl,
    intlValues,
    alertText,
    alertIntl,
    type,
    neutralGreyTint,
    variant,
    icon,
    children,
  }) => {
    // Intl
    const intlMessage = msg => intl.formatMessage({id: msg, values: intlValues});
    const text = (alertIntl && intlMessage(alertIntl)) || alertText;

    const alertProps = type !== 'neutral' && {severity: type};
    const alertClasses =
      type !== 'neutral'
        ? useStylesDefault()
        : useStylesNeutral(variant, neutralGreyTint)();

    return (
      <Alert {...alertProps} variant={variant} icon={icon} classes={alertClasses}>
        {text}
        {children}
      </Alert>
    );
  },
);
