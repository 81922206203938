import React from 'react';

export default () => {
  return (
    <>
      <h1>TicketRestaurant (CZ)</h1>
      <h3>To be migrated...</h3>
    </>
  );
};
