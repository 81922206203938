/* eslint-disable camelcase  */
import {
  convertCurrencyStringToFloat,
  removePropertiesFromObject,
  checkNumberInRange,
  convertStringToInt,
} from 'utils';
import rootActions from 'store/actions';
import selectors from '../selectors';
import leadsActions from './leads';

export const SET_IS_DIRTY = '[TURKEY_PRODUCTS/PRICE_SIMULATOR_BENEFIT] SET_IS_DIRTY';
export const SET_PAYMENT_TYPE =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_BENEFIT] SET_PAYMENT_TYPE';
export const SET_CALENDAR_UNIT =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_BENEFIT] SET_CALENDAR_UNIT';
export const SET_ALLOWANCE = '[TURKEY_PRODUCTS/PRICE_SIMULATOR_BENEFIT] SET_ALLOWANCE';
export const SET_EMPLOYEE_NUMBER =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_BENEFIT] SET_EMPLOYEE_NUMBER';
export const SET_SAVINGS_CALCULATIONS =
  '[TURKEY_PRODUCTS/PRICE_SIMULATOR_BENEFIT] SET_SAVINGS_CALCULATIONS';

const setIsDirty = value => ({
  type: SET_IS_DIRTY,
  value,
});

const setPaymentType = value => ({
  type: SET_PAYMENT_TYPE,
  value,
});

const setCalendarUnit = value => ({
  type: SET_CALENDAR_UNIT,
  value: convertStringToInt(value),
});

const setAllowance = allowanceValue => (dispatch, getState) => {
  // Selectors
  const {selectTenantCurrency, selectTenantCurrencyMaskSettings} = selectors;
  const currency = selectTenantCurrency(getState());
  const currencySettings = selectTenantCurrencyMaskSettings(getState());

  const value = convertCurrencyStringToFloat(allowanceValue, currency, currencySettings);

  return dispatch({
    type: SET_ALLOWANCE,
    value,
  });
};

const setEmployeeNumber = value => ({
  type: SET_EMPLOYEE_NUMBER,
  value: convertStringToInt(value),
});

const setSavingsCalculations = value => ({
  type: SET_SAVINGS_CALCULATIONS,
  value,
});

const fetchCalculations = () => async (dispatch, getState) => {
  const {priceSimulatorBenefit, root} = getState();
  const {workingDaysInMonth} = root.settings;
  const {employee_number, calendar_unit, allowance} = priceSimulatorBenefit;
  // Selectors
  const {selectPaymentType, selectProductCode} = selectors;
  const paymentType = selectPaymentType(getState());
  const productCode = selectProductCode(getState());

  // Months to days conversion
  const workingDays =
    paymentType === 'monthly' ? calendar_unit * workingDaysInMonth : calendar_unit;

  // Allowance calculation
  const voucherValue = paymentType === 'monthly' ? allowance / workingDays : allowance;

  // Server-side calculation
  await dispatch(
    rootActions.api.makeRequest('pricer/computeSavings', [
      {
        product_ref: productCode,
        working_days: workingDays,
        voucher_value: voucherValue,
        nr_employees: employee_number,
      },
    ]),
  ).then(response => {
    const {requestCancelled} = response;
    const savingsResponse = response.data?.data?.savings;

    if (requestCancelled || !savingsResponse) return false;

    const savingsMap = {
      budget_month: 'monthBudget',
      budget_year: 'yearBudget',
      payroll_cost_month: 'monthPayrollCost',
      payroll_cost_year: 'yearPayrollCost',
      TR_cost_month: 'ticketRestaurantCostMonth',
      TR_cost_year: 'ticketRestaurantCostYear',
      TR_taxsavings_month: 'ticketRestaurantTaxSavingsMonth',
      TR_taxsavings_year: 'ticketRestaurantTaxSavingsYear',
      'savings_%': 'savingsPercentage',
      employee_free_cost: 'employeeFreeCost',
      cash_daily_total_noVAT: 'cashDailyTotalNoVAT',
      TR_daily_total_noVAT: 'ticketRestaurantDailyTotalNoVAT',
      cash_VAT: 'cashVAT',
      TR_VAT: 'ticketRestaurantVAT',
      cash_daily_meal_no_VAT: 'cashDailyMealNoVAT',
      TR_daily_meal_no_VAT: 'ticketRestaurantDailyMealNoVAT',
      cash_stamp_duty: 'cashStampDuty',
      TR_stamp_duty: 'ticketRestaurantStampDuty',
      cash_income_tax: 'cashIncomeTax',
      TR_income_tax: 'ticketRestaurantIncomeTax',
      cash_SGK_Premium: 'cashSGKPremium',
      TR_SGK_Premium: 'ticketRestaurantSGKPremium',
      SGK_Employer_Premium_Share: 'cashSGKEmployee',
      TR_SGK_Employee: 'ticketRestaurantSGKEmployee',
      cash_VAT_discount: 'cashVATDiscount',
      TR_VAT_discount: 'ticketRestaurantVATDiscount',
      'Total Tax Cost': 'totalCostTax',
      'Total Tax Cost TR': 'totalCost',
    };

    const savingsState = {};

    savingsResponse.map(s => {
      const {savings_type, savings_value} = s;
      const savingName = savingsMap[savings_type];

      if (!savingName) return null;

      return (savingsState[savingName] = savings_value);
    });

    removePropertiesFromObject(savingsState, undefined);

    return dispatch(setSavingsCalculations(savingsState));
  });
};

const resetValues = paymentType => async (dispatch, getState) => {
  const numberEmployees = getState().contact.serverFormData.number_employees;

  // Set payment type
  await dispatch(setPaymentType(paymentType));

  // Selectors
  const {selectInputsRanges} = selectors;
  const selector = selectInputsRanges('default')(getState());
  const {calendarUnit, allowance} = selector;

  // Set calendar and allowance values
  dispatch(setCalendarUnit(calendarUnit));
  dispatch(setAllowance(allowance));
  // Set number of employees from contact scene
  dispatch(setEmployeeNumber(numberEmployees));

  await dispatch(fetchCalculations());
};

const initialize = defaultPaymentType => async (dispatch, getState) => {
  const {priceSimulatorBenefit} = getState();
  const {isDirty} = priceSimulatorBenefit;

  // Is form is already dirty, don't restore default values
  if (isDirty) return;

  await dispatch(resetValues(defaultPaymentType));
};

const checkForRangeExceeds = () => async (dispatch, getState) => {
  const {priceSimulatorBenefit} = getState();

  let hasExceeds = false;

  // Selectors
  const {selectInputsRanges} = selectors;
  const selector = rangeType => selectInputsRanges(rangeType)(getState());
  const ranges = {
    min: selector('min'),
    max: selector('max'),
  };
  const {min, max} = ranges;

  // Fields name map from current state to notation to settings range notation
  const fieldsMap = {
    calendar_unit: 'calendarUnit',
    allowance: 'allowance',
    employee_number: 'numberEmployees',
  };
  const fieldsNameArray = Object.keys(fieldsMap);

  // Check only for exceeds
  for (let i = 0; i < fieldsNameArray.length; i += 1) {
    const fieldName = fieldsNameArray[i];
    const fieldMappedName = fieldsMap[fieldName];
    const value = priceSimulatorBenefit[fieldName];
    const isInRange = checkNumberInRange(
      value,
      min[fieldMappedName],
      max[fieldMappedName],
    );

    if (!isInRange) {
      // Enums mapping (to do)
      hasExceeds = fieldName.replaceAll('_', '-');
      break;
    }
  }

  return hasExceeds;
};

// Custom leads submit
const updateLeadsSubmit = () => (dispatch, getState) => {
  const {priceSimulatorBenefit, root} = getState();
  const {workingDaysInMonth} = root.settings;
  const {employee_number, calendar_unit, allowance} = priceSimulatorBenefit;
  // Selectors
  const {selectMediumType, selectProductCode, selectPaymentType} = selectors;
  const mediumType = selectMediumType(getState());
  const productCode = selectProductCode(getState());
  const paymentType = selectPaymentType(getState());

  const leadsValue =
    paymentType === 'monthly'
      ? allowance / workingDaysInMonth / calendar_unit
      : allowance;

  const leadsPayload = {
    medium_type: mediumType,
    value: leadsValue,
    item_count: employee_number,
    product_ref: productCode,
  };

  dispatch(leadsActions.serverLeads(leadsPayload));
};

export default {
  setIsDirty,
  setPaymentType,
  setCalendarUnit,
  setAllowance,
  setEmployeeNumber,
  fetchCalculations,
  resetValues,
  initialize,
  checkForRangeExceeds,
  updateLeadsSubmit,
};
