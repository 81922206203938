import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
// Components - global
import FormField from 'ComponentsForm/Field';
// React Intl HOC
import {injectIntl} from 'react-intl';

const FormFields = props => {
  const {
    formData,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    classesField,
    WrapperField,
    tenant,
    translationPrefix,
    customRefs = {},
    centeredInputText,
  } = props;

  const {fieldWrapper, fieldItem} = classesField;

  const groupName = Object.keys(formData).shift();
  // Remove booleans (checkboxes) from iteration
  const newFormData = formData[groupName].filter(e => e.validationType !== 'bool');

  return (
    <>
      {newFormData.map((field, key) => {
        const {
          name,
          type,
          disabled,
          mask,
          multiline,
          multilineRows,
          pasteDisabled,
          withTooltip,
          tooltipPlacement,
          isLoading,
        } = field;
        const validationErrors = errors[groupName];
        const hasError =
          validationErrors &&
          validationErrors[name] &&
          touched[groupName] &&
          touched[groupName][name];
        const errorIntl = hasError && `error.FE_input-${validationErrors[name]}`;
        const intlTranslations = id => `${translationPrefix}_${id}_${name}`;

        return (
          <WrapperField key={key}>
            <div className={[fieldWrapper, 'form-field'].join(' ')}>
              <FormField
                refs={field.ref && customRefs[field.ref]}
                onChange={event => handleChange(event, name)}
                onBlur={handleBlur}
                type={type}
                name={`${groupName}.${name}`}
                classes={fieldItem}
                value={values[groupName][name]}
                placeholderIntl={translationPrefix && intlTranslations('placeholder')}
                labelIntl={translationPrefix && intlTranslations('label')}
                error={hasError}
                errorIntl={errorIntl}
                errorIntlFieldName={name}
                disabled={disabled}
                mask={mask}
                tenant={tenant}
                multiline={multiline}
                multilineRows={multilineRows}
                centeredInputText={centeredInputText}
                pasteDisabled={pasteDisabled}
                withTooltip={withTooltip}
                tooltipPlacement={tooltipPlacement}
                tooltipIntl={translationPrefix && intlTranslations('tooltip')}
                isLoading={isLoading}
              />
            </div>
          </WrapperField>
        );
      })}
    </>
  );
};

const mapStateToProps = state => ({
  tenant: state.root.settings.tenant,
  currentScene: state.flow.currentScene,
});

export default compose(connect(mapStateToProps), injectIntl)(FormFields);
