import React from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: theme.zIndex.progressBar,
  },
}));

export default () => <LinearProgress className={useStyles().root} variant='query' />;
