import actions from '../store/actions';
import selectors from '../store/selectors';
import formData from './formDataFuel';
import sharedData from './sharedDataFuel';

const flowActions = actions;
const {steps, vehicleListTableData, modalBigClientVariant} = sharedData;

const flowRoutes = [
  {
    path: 'contact-info',
    sceneComponentName: 'ContactInfo',
    props: {
      selectors,
      componentActions: actions.contact,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 1,
        nextDisabled: false,
        prevDisabled: true,
        navigationVisibility: true,
        nextScenePath: 'price-simulator',
        prevScenePath: null,
      },
      formData: formData.contactInfo,
      sceneTitleImage: 'contact-info/card-ticket-car.jpg',
      modalBigClientVariant,
    },
  },
  {
    path: 'price-simulator',
    sceneComponentName: 'PriceSimulatorFuel',
    props: {
      selectors,
      componentActions: actions.priceSimulatorFuel,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 1.5,
        nextDisabled: false,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'company-info',
        prevScenePath: 'contact-info',
      },
      formData: formData.priceSimulatorFuel,
    },
  },
  {
    path: 'company-info',
    sceneComponentName: 'CompanyInfo',
    props: {
      selectors,
      componentActions: actions.company,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 2,
        nextDisabled: false,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'vehicle-list',
        prevScenePath: 'price-simulator',
      },
      formData: formData.companyInfo,
      locationSelectorData: {
        billingAddress: [
          {
            name: 'city',
            validationType: 'string',
            validationPreset: 'justRequired',
            value: '',
          },
          {
            name: 'district',
            validationType: 'string',
            validationPreset: 'justRequired',
            value: '',
          },
          {
            name: 'neighbourhood',
            validationType: 'string',
            validationPreset: 'justRequired',
            value: '',
          },
        ],
        deliveryAddress: [
          {
            name: 'city',
            validationType: 'string',
            validationPreset: 'justRequired',
          },
          {
            name: 'district',
            validationType: 'string',
            validationPreset: 'justRequired',
          },
          {
            name: 'neighbourhood',
            validationType: 'string',
            validationPreset: 'justRequired',
          },
        ],
      },
    },
  },
  {
    path: 'vehicle-list',
    sceneComponentName: 'VehicleList',
    props: {
      selectors,
      componentActions: actions.orderFuel,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 2.5,
        nextDisabled: true,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'order-summary',
        prevScenePath: 'company-info',
      },
      tableData: {
        downloadedFileName: 'vehicles.xlsx',
        ...vehicleListTableData,
      },
      modalBigClientVariant,
    },
  },
  {
    path: 'order-summary',
    sceneComponentName: 'OrderSummaryFuel',
    props: {
      selectors,
      componentActions: actions.orderFuel,
      navigation: {
        stepLegendVisibility: true,
        stepActive: 2.5,
        nextDisabled: false,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'order-payment',
        prevScenePath: 'vehicle-list',
      },
      formData: {
        rootCollection: [
          {
            id: 'total_willing_to_pay',
            name: 'total-willing-to-pay',
            value: '',
            validationPreset: 'range',
            validationOptions: {
              priceRange: true,
              min: 'fuelCostMin',
              max: 'fuelCostMax',
              isRequired: true,
            },
            mask: {
              hasCurrency: true,
              fieldCurrencyMaskSettings: {
                decimalSeparator: false,
              },
            },
            withTooltip: true,
            tooltipPlacement: 'right',
          },
          {
            id: 'terms',
            name: 'terms',
            value: 'false',
            validationType: 'bool',
            validationPreset: 'checkbox',
          },
        ],
      },
      tableData: {
        ...vehicleListTableData,
      },
      modalBigClientVariant,
    },
  },
  {
    path: 'order-payment',
    sceneComponentName: 'OrderPayment',
    childrenComponentsName: {
      PaymentSummary: 'SummaryTableFuel',
      PaymentMethodsScene: 'PaymentMethodsFuel',
    },
    props: {
      paymentMethods: ['online-payment', 'bank-transfer'],
      withSubtitle: false,
      selectors,
      componentActions: {
        ...actions.order,
        ...actions.orderFuel,
      },
      navigation: {
        stepLegendVisibility: true,
        stepActive: 3,
        nextDisabled: true,
        prevDisabled: false,
        navigationVisibility: true,
        nextScenePath: 'order-status',
        prevScenePath: 'order-summary',
      },
    },
  },
  {
    path: 'order-status',
    sceneComponentName: 'OrderStatus',
    childrenComponentsName: {
      PaymentStatus: 'PaymentStatusFuel',
    },
    props: {
      selectors,
      componentActions: {
        ...actions.order,
        ...actions.orderFuel,
      },
      navigation: {
        stepLegendVisibility: true,
        stepActive: 3.5,
        nextDisabled: true,
        prevDisabled: false,
        navigationVisibility: false,
        nextScenePath: '',
        prevScenePath: 'order-payment',
      },
    },
  },
];

// NOTE: Will be used to store different product specific values
const coreSettings = {
  translationIdentifierKey: 'fuel',
  tableType: 'vehicles',
};

// NOTE: Will be used for any static routes
const staticRoutes = [
  {
    path: 'big-client',
    sceneComponentName: 'BigClient',
  },
  {
    path: 'payment-initiated',
    sceneComponentName: 'PaymentInitiated',
  },
];

const flowPaths = flowRoutes.map(item => item.path);

export {flowActions, flowPaths};

export default {
  steps,
  flowRoutes,
  staticRoutes,
  coreSettings,
};
