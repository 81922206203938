import React from 'react';
import {appVersion} from 'config';
// React Intl HOC
import {FormattedMessage} from 'react-intl';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
// ContactUS
import Contact from 'components/ContactUs';

const useStyles = makeStyles(theme => ({
  footer: {
    background: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.common.black,
    padding: '2rem 1rem',
  },
  appVersion: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    opacity: 0.1,
    pointerEvents: 'none',
  },
  topImage: {
    marginBottom: theme.spacing(1),
  },
}));

const Footer = ({ContactUs, TopImage, hasEnabledTopImage, tenant}) => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const {imagePath, maxWidth} = TopImage;

  return (
    <footer className={classes.footer}>
      {hasEnabledTopImage && (
        <img
          alt='FooterTopImage'
          src={`/images/${tenant}/${imagePath}`}
          style={{maxWidth}}
          className={classes.topImage}
        />
      )}
      <Contact {...ContactUs} />
      <div>
        <span>
          <FormattedMessage id='global.footer_copyright' values={{YEAR: currentYear}} />
        </span>
      </div>
      <div className={classes.appVersion}>{appVersion}</div>
    </footer>
  );
};

export default Footer;
