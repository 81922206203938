import rootActions from 'store/actions';
import flowActions from './flow';

export const SET_LEADS = '[TURKEY_PRODUCTS/LEADS] SET_LEADS';

const setLeads = value => ({
  type: SET_LEADS,
  value,
});

const serverLeads = payload => async (dispatch, getState) => {
  const statesData = {
    currentLeadId: () => getState().leads.leadUuid,
    companyUuid: () => getState().company.companyUuid,
    leadData: () => getState().leads,
    productRef: () => getState().featureToggle.productCode,
    flowPaths: () => getState().flow.flowPaths,
    currentScene: () => getState().flow.currentScene,
    flowMaxDepth: () => getState().flow.flowMaxDepth,
  };

  const currentLeadId = statesData.currentLeadId();

  if (!currentLeadId) {
    dispatch(
      rootActions.api.makeRequest('leads/createLeads', [
        statesData.companyUuid(),
        {
          product_ref: statesData.productRef(),
          last_step: statesData.currentScene(),
          data_bag: {...statesData.leadData(), leadSource: 'WEB'},
        },
      ]),
    ).then(response => dispatch(setLeads({leadUuid: response.data.data})));
  } else {
    const {checkFlowDepth} = flowActions;

    // Update leads
    dispatch(checkFlowDepth()).then(isReached => {
      const requestPayload = {
        ...{last_step: statesData.flowMaxDepth()},
        ...payload,
      };

      // Send maxFlowDepth to server only when we have something to send
      if (Object.keys(requestPayload).length > 0) {
        const companyUuid = statesData.companyUuid();

        if (isReached) {
          dispatch(
            rootActions.api.makeRequest('leads/updateLeads', [
              companyUuid,
              currentLeadId,
              requestPayload,
            ]),
          );
        }
      }
    });
  }
};

export default {
  setLeads,
  serverLeads,
};
