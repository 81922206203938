import {
  SET_ACCEPTED_GDPR,
  SET_ACCEPTED_COMMERCIALS,
  SET_ACCEPTED_MARKETING,
  SET_ACCEPTED_TERMS,
  SET_ACCEPTED_AUTO_PAYMENT_WITH_CARD_SAVE,
} from '../actions/consents';
import initialStates from './initialStates';

const initialState = initialStates.consents;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCEPTED_GDPR:
      return {
        ...state,
        acceptedGdpr: action.payload,
      };

    case SET_ACCEPTED_COMMERCIALS:
      return {
        ...state,
        acceptedCommercials: action.payload,
      };

    case SET_ACCEPTED_MARKETING:
      return {
        ...state,
        acceptedMarketing: action.payload,
      };

    case SET_ACCEPTED_TERMS:
      return {
        ...state,
        acceptedTerms: action.value,
      };

    case SET_ACCEPTED_AUTO_PAYMENT_WITH_CARD_SAVE:
      return {
        ...state,
        acceptedAutoPaymentWithCardSave: action.value,
      };

    default:
      return state;
  }
};
