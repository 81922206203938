import {routePath} from 'helpers';

export default null;

export const commonStateToProps = (state, paymentInitiatedCheck) => {
  const {root, flow} = state;

  // Root
  const {isProgress} = root.ui;
  const {
    formEmailRegex,
    formPersonNameRegex,
    formZipRegex,
    formPhoneRegex,
    regexFormPhoneMobile,
    regexPlateNumber,
    tenant,
  } = root.settings;

  // Flow
  const {navigation} = flow;

  // Order
  const {paymentInitiated} = state.order;

  return {
    navigation,
    isProgress,
    validationsRegex: {
      formEmailRegex,
      formPersonNameRegex,
      formZipRegex,
      formPhoneRegex,
      regexFormPhoneMobile,
      regexPlateNumber,
    },
    tenant,
    ...(paymentInitiatedCheck && {paymentInitiated}),
  };
};

export const commonDispatchToProps = (dispatch, ownProps) => {
  const {
    rootActions,
    flowActions,
    navigation,
    currentPath,
    flowPaths,
    routeMatch,
  } = ownProps;

  const {
    stepLegendVisibility,
    stepActive,
    nextDisabled,
    prevDisabled,
    navigationVisibility,
    nextScenePath,
    prevScenePath,
  } = navigation;

  // Root
  const loaderShow = () => dispatch(rootActions.ui.loaderShow());
  const loaderHide = () => dispatch(rootActions.ui.loaderHide());
  const progressShow = () => dispatch(rootActions.ui.progressShow());
  const progressHide = () => dispatch(rootActions.ui.progressHide());
  const errorShow = message => dispatch(rootActions.ui.errorShow(message));
  // Flow
  const sceneDefaults = paymentInitiated => {
    dispatch(flowActions.flow.setCurrentScene(currentPath));

    // Guard check
    return dispatch(flowActions.flow.checkFlowDepth(currentPath)).then(isAllowed => {
      const firstScenePath = routePath(flowPaths[0], routeMatch);

      if (isAllowed && !paymentInitiated) {
        dispatch(flowActions.flow.setNavigationLegendVisibility(stepLegendVisibility));
        dispatch(flowActions.flow.setNavigationStepActive(stepActive));
        dispatch(flowActions.flow.setNavigationNextDisabled(nextDisabled));
        dispatch(flowActions.flow.setNavigationPrevDisabled(prevDisabled));
        dispatch(flowActions.flow.setNavigationVisibility(navigationVisibility));
        dispatch(flowActions.flow.setNavigationNextScenePath(nextScenePath));
        dispatch(flowActions.flow.setNavigationPrevScenePath(prevScenePath));
        return Promise.resolve();
      }

      if (paymentInitiated) {
        window.location.href = routePath('payment-initiated', routeMatch);
      } else {
        window.location.href = firstScenePath;
      }

      return Promise.reject(new Error('Guard check: scene not available'));
    });
  };

  const sceneLeads = (hasDisabledServerLeads = true) =>
    hasDisabledServerLeads && dispatch(flowActions.leads.serverLeads());

  const clearNaviTriggers = () => dispatch(flowActions.flow.clearNavigationTriggers());
  const bigClientEmail = () => dispatch(flowActions.bigClient.email());
  const setBigClientChoice = choice =>
    dispatch(flowActions.bigClient.setBigClientChoice(choice));
  const setBigClientModalButtonsAreDirty = value =>
    dispatch(flowActions.bigClient.setModalButtonsAreDirty(value));

  // Company contents
  const updateConsents = payload =>
    dispatch(flowActions.consents.updateConsents(payload));

  const updateFlowMaxDepth = nextScene =>
    dispatch(flowActions.flow.updateFlowMaxDepth(nextScene));

  return {
    sceneDefaults,
    sceneLeads,
    loaderShow,
    loaderHide,
    progressShow,
    progressHide,
    errorShow,
    clearNaviTriggers,
    bigClientEmail,
    setBigClientChoice,
    setBigClientModalButtonsAreDirty,
    updateConsents,
    updateFlowMaxDepth,
  };
};
