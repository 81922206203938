import React from 'react';
// React Intl
import {injectIntl} from 'react-intl';
// Mui components
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box';
// Components - local
import LabelFormRadio from './LabelFormRadio';

const PaymentMethods = ({intl, legendText, legendIntl, data, onChange}) => {
  // Intl
  const intlMessage = msg => intl.formatMessage({id: msg});
  const labelText = (legendIntl && intlMessage(legendIntl)) || legendText;

  return (
    <FormControl component='fieldset'>
      {labelText && (
        <Box mb={1}>
          <FormLabel component='legend'>{labelText}</FormLabel>
        </Box>
      )}

      <RadioGroup>
        {data.map(({name, value, label, checked, children}, key) => (
          <LabelFormRadio
            key={key}
            onChange={onChange}
            name={name}
            value={value}
            label={label}
            checked={checked}
            withCollapse
          >
            {children}
          </LabelFormRadio>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default injectIntl(PaymentMethods);
