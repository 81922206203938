import React from 'react';
import {tenants} from 'config';
import themes from 'themes';
import scenarios from 'scenarios';
import AppTheme from './AppTheme';

export default props => {
  const {tenant} = props;
  const tenantConfig = tenants[tenant];
  const {scenario, theme} = tenantConfig;

  const Scenario = scenarios[scenario];
  const scenarioTheme = themes[theme];

  return (
    <AppTheme theme={scenarioTheme}>
      <Scenario tenant={tenant} {...tenantConfig} />
    </AppTheme>
  );
};
