import React from 'react';
// Components - global
import LabelFormCheckbox from 'ComponentsForm/LabelFormCheckbox';
import LabelFormRadio from 'ComponentsForm/LabelFormRadio';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
// React Intl
import {injectIntl} from 'react-intl';

// Styles
const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > label': {
      marginRight: 'auto',
    },
  },
  helpIcon: {
    fontSize: '1.125rem',
    marginLeft: theme.spacing(0.5),
    color: theme.palette.grey[600],
  },
}));

export default injectIntl(
  ({
    intl,
    labelId,
    translationIdentifierPrefix,
    translationKey,
    checked,
    handleChangeCallback,
    paymentOptionsType,
  }) => {
    const classes = useStyles();
    // Intl
    const intlMessage = id => intl.formatMessage({id});

    const PaymentOptions = props => {
      switch (paymentOptionsType) {
        case 'radio':
          return <LabelFormRadio {...props} />;

        case 'checkbox':
          return <LabelFormCheckbox {...props} />;

        default:
          return <></>;
      }
    };

    return (
      <div className={classes.wrapper}>
        <PaymentOptions
          id={labelId}
          label={intlMessage(
            `${translationIdentifierPrefix}_${paymentOptionsType}_${translationKey}`,
          )}
          checked={checked}
          onChange={event => handleChangeCallback(event.target.checked)}
        />

        {checked}

        <Tooltip
          className={classes.tooltip}
          arrow
          placement='bottom'
          title={intlMessage(`${translationIdentifierPrefix}_tooltip_${translationKey}`)}
        >
          <HelpOutlineIcon className={classes.helpIcon} />
        </Tooltip>
      </div>
    );
  },
);
