import commonSelectors from './common';
import orderSelectors from './order';

const selectOrderSummaryData = (tableType, state) => {
  // Selectors
  const {selectProductCode} = commonSelectors;
  const {selectNumberOfRows} = orderSelectors;
  const productCode = selectProductCode(state);
  const numberVehicles = selectNumberOfRows(tableType)(state);

  const {orderData, orderWillingToPay} = state.orderFuel;
  const {totalAverageFuelConsumption, totalToPay} = orderData;
  const {commission} = state.priceSimulatorFuel;

  return {
    productCode,
    numberVehicles,
    totalAverageFuelConsumption,
    commission,
    totalToPay,
    orderWillingToPay,
  };
};

export default {selectOrderSummaryData};
