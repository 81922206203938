import selectors from '../selectors';

export const SET_PRODUCT_CODE = '[TURKEY_PRODUCTS/FEATURE_TOGGLE] SET_PRODUCT_CODE';
export const SET_PRODUCT_TYPE = '[TURKEY_PRODUCTS/FEATURE_TOGGLE] SET_PRODUCT_TYPE';
export const SET_VENDOR_NAME = '[TURKEY_PRODUCTS/FEATURE_TOGGLE] SET_VENDOR_NAME';
export const SET_VENDOR_LOGO_PATH =
  '[TURKEY_PRODUCTS/FEATURE_TOGGLE] SET_VENDOR_LOGO_PATH';

const setProductCode = value => ({
  type: SET_PRODUCT_CODE,
  value,
});

const setProductType = value => ({
  type: SET_PRODUCT_TYPE,
  value,
});

const setVendorName = value => ({
  type: SET_VENDOR_NAME,
  value,
});

const setVendorLogoPath = value => ({
  type: SET_VENDOR_LOGO_PATH,
  value,
});

const setVendor = () => (dispatch, getState) => {
  // Selectors
  const {selectProductCode} = selectors;
  const productCode = selectProductCode(getState());

  const {availableVendors, tenant} = getState().root.settings;
  const getFromVendors = value => availableVendors.find(e => e.id === productCode)[value];
  const imagePath = `/${tenant}/logo-vendors/${getFromVendors('name')}.png`;

  dispatch(setVendorName(getFromVendors('name')));
  dispatch(setVendorLogoPath(imagePath));
};

export default {
  setProductCode,
  setProductType,
  setVendor,
};
