import React from 'react';
// Mui components
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

// React Intl HOC
import {injectIntl} from 'react-intl';

const StepperLegend = props => {
  const {steps, stepActive, translationIdentifierPrefix, intl} = props;
  const step = stepActive - 1;

  return (
    <Stepper
      style={{
        backgroundColor: 'transparent',
        padding: 0,
      }}
      activeStep={step}
      alternativeLabel
    >
      {steps.map((label, key) => {
        return (
          <Step key={label.id} completed={step > key}>
            <StepLabel>
              {intl.formatMessage({
                id: `${translationIdentifierPrefix}stepper-legend_${label.id}`,
              })}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default injectIntl(StepperLegend);
