/* eslint-disable no-case-declarations */
import rootActions from 'store/actions';
import {defaultAppSettingsMap, tenants} from 'config';

export default ({getState, dispatch}) => next => action => {
  switch (action.type) {
    case '[ROOT/SETTINGS] SET_TENANT':
      dispatch(rootActions.api.setHeadersTenant(action.value));
      break;

    case '[ROOT/SETTINGS] SET_SESSION_ID':
      dispatch(rootActions.api.setHeadersSession(action.value));
      break;

    case '[ROOT/SETTINGS] SET_CURRENT_LANGUAGE':
      dispatch(rootActions.api.setHeadersLanguage(action.value));
      break;

    case '[ROOT/SETTINGS] FETCH_SETTINGS':
      // Custom middleware action (sync/async) interceptor
      // Transform data and perform async operations and dispatching

      // Payload response
      const settingsPayload = action.payload;
      const settingsArray = [];

      settingsPayload.forEach(payload =>
        payload.data.data.map(s => settingsArray.push(s)),
      );

      // If sessionId exists, use it from the state - the sessionId should be persisted in session state
      const {sessionId, currentLanguage} = getState().root.settings;
      const appSessionId = sessionId || settingsPayload[0].headers['x-session-id']; // Use x-session-id from global settigns request using index 0

      // Settings reducer
      const settingsReducer = {};
      const gwHost = () => settingsReducer.edgRootUrl;
      const defaultLanguage = () => settingsReducer.availableLanguages[0];
      const gwClientId = () => settingsReducer.edgClientId;
      const gwSecretId = () => settingsReducer.edgSecretId;
      // Tenant settings
      const {tenant} = getState().root.settings;
      const tenantAppSettingsMap = tenants[tenant].appSettingsMap;
      const settingsArrayMock = tenants[tenant].appSettingsMock;
      const appSettingsMap = {...defaultAppSettingsMap, ...tenantAppSettingsMap};
      const appSettingsKeys = Object.keys(appSettingsMap);
      // Language
      const language = () => currentLanguage || defaultLanguage();

      // Manipulate settings array from server-side
      settingsArray.concat(settingsArrayMock).reduce((accumulator, setting) => {
        accumulator[setting.name] = setting.value;
        return accumulator;
      }, settingsReducer);

      // Updating settings object according to application needs
      Object.keys(settingsReducer).forEach(setting => {
        // Rename settings keys with front-end convention
        const newKey = appSettingsMap[setting];

        if (newKey) {
          settingsReducer[newKey] = settingsReducer[setting];
          delete settingsReducer[setting];
        }

        // Keep from server only required settings keys for current tenant
        const isNotIncluded = !appSettingsKeys.includes(setting);
        if (isNotIncluded) {
          delete settingsReducer[setting];
        }
      });
      dispatch(rootActions.settings.setCurrentLanguage(language()));
      dispatch(rootActions.settings.updateSettings(settingsReducer));
      dispatch(rootActions.settings.setSessionId(appSessionId));
      dispatch(rootActions.api.setGwHost(gwHost()));
      dispatch(rootActions.api.setGwHeadersSecretId(gwClientId()));
      dispatch(rootActions.api.setGwHeadersClientId(gwSecretId()));
      break;

    case '[ROOT/SETTINGS] FETCH_TRANSLATIONS':
      const translationsPayload = action.payload.data.data.translations;
      dispatch(rootActions.settings.updateTranslations(translationsPayload));
      break;

    default:
      break;
  }

  next(action);
};
