import React from 'react';
import {publicImgPath} from 'config';
// Components - global
import Logo from 'components/Logo';
import LanguageSelector from 'components/LanguageSelector';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  box: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
    height: theme.spacing(5),
  },
  container: ({containerMaxWidth, extendedLayout}) =>
    extendedLayout
      ? theme.extendedLayoutContainer
      : {maxWidth: theme.breakpoints.values[containerMaxWidth]},
  logoVendor: {
    display: 'flex',
    alignItems: 'center',
  },
  toolbar: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
  },
}));

export default props => {
  const {
    hasVendor,
    vendorLogoSettings,
    vendorLogoPath,
    logo,
    availableLanguages,
    translations,
    currentLanguage,
  } = props;
  const classes = useStyles(props);

  const LogoComponent = () => <Logo {...logo} additionalClasses={classes.logo} />;
  const LogoVendorComponent = () =>
    vendorLogoPath && (
      <Logo
        {...vendorLogoSettings}
        image={publicImgPath + vendorLogoPath}
        additionalClasses={classes.logoVendor}
      />
    );
  const HeaderToolbar = () => (
    <Toolbar className={classes.toolbar}>
      <Box className={classes.box}>
        {hasVendor ? <LogoVendorComponent /> : null}
        <LogoComponent />
      </Box>

      <LanguageSelector
        language={currentLanguage}
        languages={availableLanguages}
        translations={translations}
      />
    </Toolbar>
  );

  return (
    <Box className={classes.root}>
      <AppBar position='sticky' color='inherit' elevation={0}>
        <Container disableGutters classes={{root: classes.container}}>
          <HeaderToolbar />
        </Container>
      </AppBar>
    </Box>
  );
};
