import React, {useRef} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {recursiveMap} from 'utils';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
// Validation
import {Formik, Form} from 'formik';
import createSchema from 'validations/createFormikValidationSchema';

// Styles
const useStyles = makeStyles(() => ({
  centerForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const mapStateToProps = state => ({
  settings: state.root.settings,
});

export default compose(connect(mapStateToProps))(props => {
  const form = useRef(null);
  const classes = useStyles();
  const {
    children,
    presets = {},
    settings,
    formData,
    validateOnChange = true,
    validateOnBlur = true,
    submitEvent,
    submit,
    change,
    classesField,
    WrapperField,
    translationIdentifierPrefix,
    centeredForm = false,
    resetFormAfterSubmit = false,
  } = props;

  const newFormData = Array.isArray(formData) ? {rootCollection: formData} : formData;
  // Create formik validation schema
  const validationSchema = createSchema(newFormData, presets, settings);

  // Initial values
  const initialValues = {};
  Object.keys(formData).forEach(group => {
    initialValues[group] = {};
    formData[group].forEach(item => {
      initialValues[group][item.name] = item.value || '';
    });
  });

  // Programmatically submit event
  if (submitEvent) {
    // Formik warning fix:
    // Cannot update a component (`Formik`) while rendering a different component (`Unknown`).
    // https://github.com/formium/formik/issues/1218

    setTimeout(() => {
      form.current.submitForm();
    }, 0);
  }

  return (
    <Formik
      innerRef={form}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
      onSubmit={(values, {resetForm}) => {
        submit(values);

        // If resetForm was required
        if (resetFormAfterSubmit) {
          resetForm({values: initialValues});
        }
      }}
    >
      {formikProps => (
        <Form
          onSubmit={formikProps.handleSubmit}
          onChange={change}
          className={centeredForm ? classes.centerForm : ''}
        >
          {recursiveMap(children, child =>
            React.cloneElement(
              child,
              (() =>
                Object.prototype.hasOwnProperty.call(child.props, 'formData') && {
                  ...formikProps,
                  classesField,
                  WrapperField,
                  translationIdentifierPrefix,
                })(),
            ),
          )}
        </Form>
      )}
    </Formik>
  );
});
