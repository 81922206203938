// Components - local
import ToasterTransition from './ToasterTransition';

export default {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transitionDuration: 500,
  TransitionComponent: ToasterTransition,
  autoHideDuration: 3000,
};
