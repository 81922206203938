import {updateHeaders, updateHost} from 'api';
import {apiVariantsSettings} from 'config/index';

// Handle api request logic in this middleware
export default () => next => action => {
  const {value} = action;

  switch (action.type) {
    case '[ROOT/API] SET_HEADERS_TENANT':
      updateHeaders('*', 'x-tenant', value);
      break;

    case '[ROOT/API] SET_HEADERS_SESSION':
      updateHeaders('*', 'x-session-id', value);
      break;

    case '[ROOT/API] SET_HEADERS_LANGUAGE':
      updateHeaders('*', 'x-language', value);
      break;

    case '[ROOT/API] SET_GW_HOSTS':
      apiVariantsSettings.forEach(item => {
        updateHost(item.instanceName, value, item.hostVariant);
      });
      break;

    case '[ROOT/API] SET_GW_HEADERS_CLIENT_ID':
      apiVariantsSettings.forEach(item => {
        updateHeaders(item.instanceName, 'x-client-id', value);
      });
      break;

    case '[ROOT/API] SET_GW_HEADERS_SECRET_ID':
      apiVariantsSettings.forEach(item => {
        updateHeaders(item.instanceName, 'x-secret-id', value);
      });
      break;

    default:
      break;
  }

  next(action);
};
