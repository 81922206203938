import flow from './flow';
import leads from './leads';
import consents from './consents';
import bigClient from './bigClient';
import featureToggle from './featureToggle';
import contact from './contact';
import mediumTypes from './mediumTypes';
import priceSimulatorFuel from './priceSimulatorFuel';
import priceSimulatorBenefit from './priceSimulatorBenefit';
import company from './company';
import order from './order';
import orderFuel from './orderFuel';
import orderBenefit from './orderBenefit';
import loseData from './loseData';

export default {
  flow,
  leads,
  consents,
  bigClient,
  featureToggle,
  contact,
  mediumTypes,
  priceSimulatorFuel,
  priceSimulatorBenefit,
  company,
  order,
  orderFuel,
  orderBenefit,
  loseData,
};
