const checkForBigClientModal = (variant, valueToCheck) => state => {
  const statesData = {
    settings: () => state.root.settings,
    bigClient: () => state.bigClient,
  };
  const {maxVehiclesNumber, maxEmployeesNumber, fuelCostMax} = statesData.settings();
  const {modalButtonsAreDirty} = statesData.bigClient();

  switch (variant) {
    case 'max-vehicles':
      return valueToCheck > maxVehiclesNumber && !modalButtonsAreDirty;

    case 'max-value':
      return valueToCheck > fuelCostMax && !modalButtonsAreDirty;

    case 'exceeding-max-value':
      return valueToCheck > maxEmployeesNumber && !modalButtonsAreDirty;

    default:
      return false;
  }
};

export default {
  checkForBigClientModal,
};
