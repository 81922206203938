import React from 'react';
// Mui components
import Box from '@material-ui/core/Box';
// Components - local
import ContentStatus from './components/ContentStatus';
import ButtonRetryPayment from './components/ButtonRetryPayment';

export default ({
  translationIdentifierPrefix,
  paymentStatus,
  paymentMethod,
  productCode,
  canReOrder,
  handleRetryPayment,
  orderValue,
}) => {
  // Intl
  const translation = `${translationIdentifierPrefix}_${paymentMethod}_${paymentStatus}_${productCode}`;

  return (
    <>
      <ContentStatus
        translationIdentifierPrefix={translation}
        paymentStatus={paymentStatus}
        withSubtitle
        orderValue={orderValue}
      />

      {canReOrder && (
        <Box mt={2}>
          <ButtonRetryPayment
            translationIdentifierPrefix={translation}
            handleRetryPayment={handleRetryPayment}
          />
        </Box>
      )}
    </>
  );
};
