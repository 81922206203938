import React from 'react';
import {compose} from 'redux';
// Components - global
import StaticPage from 'components/StaticPage';
// Mui components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import {withStyles} from '@material-ui/core/styles';
// React Intl
import {FormattedMessage} from 'react-intl';

// Styles
const useStyles = theme => ({
  bigClientIcon: {
    color: theme.palette.red,
    fontSize: '5rem',
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
  },
});

export default compose(withStyles(useStyles))(props => {
  const {classes, flowActions} = props;
  const translationIdentifierKey = 'global.page-not-found';
  return (
    <StaticPage
      statusTitleId={`${translationIdentifierKey}_title`}
      flowActions={flowActions}
    >
      <Box className={classes.fullWidth}>
        <Box mt={2} className={classes.alignCenter}>
          <SentimentVeryDissatisfiedIcon className={classes.bigClientIcon} />
        </Box>
        <Box mt={3} mb={2}>
          <Typography variant='h4'>
            <Box className={classes.textCenter}>
              <FormattedMessage id={`${translationIdentifierKey}_subtitle`} />
            </Box>
          </Typography>
        </Box>
      </Box>
    </StaticPage>
  );
});
