import {routerSceneLazyLoad, routerStaticPageLazyLoad} from './routerLazyLoad';
import routePath from './routePath';
import routePathSlice from './routePathSlice';
import routeReplaceLastPath from './routeReplaceLastPath';
import formDataParser from './formDataParser';
import formDataCreator from './formDataCreator';

export default null;
export {
  routerSceneLazyLoad,
  routerStaticPageLazyLoad,
  routePath,
  routePathSlice,
  routeReplaceLastPath,
  formDataParser,
  formDataCreator,
};
