import React from 'react';
import NumberFormat from 'react-number-format';

export default props => {
  const {inputRef, currency, ...other} = props;

  // Don't need to spread unuseful NumberFormat component props
  delete other.dispatch;
  delete other.errorHighlightCallback;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      displayType='number'
      allowNegative={false}
      isNumericString
      suffix=' %'
    />
  );
};
