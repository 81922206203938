import {
  SET_CURRENT_SCENE,
  SET_NAVIGATION_STEP_LEGEND_VISIBILITY,
  SET_NAVIGATION_STEP_ACTIVE,
  NAVIGATION_NEXT_TRIGGER,
  NAVIGATION_PREV_TRIGGER,
  SET_NAVIGATION_NEXT_DISABLED,
  SET_NAVIGATION_PREV_DISABLED,
  CLEAR_NAVIGATION_TRIGGERS,
  SET_NAVIGATION_NEXT_SCENE_PATH,
  SET_NAVIGATION_PREV_SCENE_PATH,
  SET_FLOW_PATHS,
  SET_FLOW_MAX_DEPTH,
  SET_NAVIGATION_VISIBILITY,
  SET_NAVIGATION_STEPS_VISIBILITY,
} from '../actions/flow';
import initialStates from './initialStates';

const initialState = initialStates.flow;

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_SCENE:
      return {
        ...state,
        currentScene: action.value,
      };

    case SET_NAVIGATION_STEP_LEGEND_VISIBILITY:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          stepLegendVisibility: action.value,
        },
      };

    case SET_NAVIGATION_STEP_ACTIVE:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          stepActive: action.value,
        },
      };

    case SET_NAVIGATION_STEPS_VISIBILITY: {
      return {
        ...state,
        navigation: {
          ...state.navigation,
          stepLegendVisibility: action.value,
        },
      };
    }

    case NAVIGATION_NEXT_TRIGGER:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          nextTrigger: true,
        },
      };

    case NAVIGATION_PREV_TRIGGER:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          prevTrigger: true,
        },
      };

    case CLEAR_NAVIGATION_TRIGGERS:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          nextTrigger: initialState.navigation.nextTrigger,
          prevTrigger: initialState.navigation.prevTrigger,
        },
      };

    case SET_NAVIGATION_NEXT_DISABLED:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          nextDisabled: action.value,
        },
      };

    case SET_NAVIGATION_PREV_DISABLED:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          prevDisabled: action.value,
        },
      };

    case SET_NAVIGATION_VISIBILITY:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          navigationVisibility: action.value,
        },
      };

    case SET_NAVIGATION_NEXT_SCENE_PATH:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          nextScenePath: action.value,
        },
      };

    case SET_NAVIGATION_PREV_SCENE_PATH:
      return {
        ...state,
        navigation: {
          ...state.navigation,
          prevScenePath: action.value,
        },
      };

    case SET_FLOW_PATHS:
      return {
        ...state,
        flowPaths: action.value,
      };

    case SET_FLOW_MAX_DEPTH:
      return {
        ...state,
        flowMaxDepth: action.value,
      };

    default:
      return state;
  }
};
