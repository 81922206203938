import React, {useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {convertStringToInt} from 'utils';
// React Intl
import {FormattedMessage, injectIntl} from 'react-intl';
// Value fieldSettings for validation
import fieldSettings from 'validations/fieldSettings';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
// Components - local
import Loader from 'components/Loader';
import FieldTooltip from './FieldTooltip';
// Masks
import {
  MaskLeadingZero,
  MaskNumberPositiveInteger,
  MaskCurrency,
  MaskPercentage,
} from './Masks';

const useStyles = makeStyles(() => ({
  uppercase: {
    textTransform: 'uppercase',
  },
  inputWrapper: {
    position: 'relative',
    width: '100%',
  },
  inputWrapperLoading: {
    '& input': {
      color: 'transparent',
    },
  },
  inputLoader: {
    position: 'absolute',
    right: '1rem',
  },
  inputCentered: {
    '& input': {
      textAlign: 'center',
    },
  },
}));

const mapStateToProps = state => ({
  settings: state.root.settings,
});

export default compose(
  connect(mapStateToProps),
  injectIntl,
)(props => {
  const {
    onChange,
    onBlur,
    type = 'text',
    classes,
    value,
    name,
    placeholderIntl,
    placeholderText = '',
    labelIntl,
    labelText,
    helperIntl,
    helperText = '',
    tooltipIntl,
    tooltipText = '',
    error,
    errorIntl,
    errorText = '',
    errorIntlFieldName,
    uppercaseText,
    InputLabelProps,
    InputProps,
    disabled,
    withHelperSpace = true,
    fullWidth = true,
    intl,
    size,
    shrink = true,
    mask = {},
    tenant,
    multiline,
    multilineRows = 2,
    refs,
    isLoading,
    hideValueOnLoading,
    centeredInputText,
    pasteDisabled = false,
    withTooltip = false,
    tooltipPlacement,
    withAutoSelect = false,
    settings,
  } = props;

  // State
  const [errorHighlight, setErrorHighlight] = useState(false);

  const {
    fieldCurrencyMaskSettings,
    hasNumberPositiveInteger,
    hasCurrency,
    hasPercentage,
    hasLeadingZero,
    maskErrorHighlightForRange,
    maskRange,
    maskRangeId,
  } = mask;

  const CustomInputLabelProps = () => ({
    ...InputLabelProps,
    ...(() => shrink && {shrink})(),
  });

  // Classes
  const myClasses = useStyles();
  const textFieldClasses = centeredInputText
    ? myClasses.inputCentered + (classes ? ` ${classes}` : '')
    : classes;

  // Intl
  const intlMessage = msg => intl.formatMessage({id: msg});
  const intlFormattedMessage = formattedMsg => {
    const fieldValidationValues = fieldSettings[tenant][errorIntlFieldName] || {};

    // Get validation value from fieldSettings (FE) or settings store (BE)
    Object.keys(fieldValidationValues).forEach(x => {
      const field = fieldValidationValues[x];
      const strIdentification = 'settings/';
      const fromStore = typeof field === 'string' && field.includes(strIdentification);

      if (fromStore) {
        const storeKey = field.replace(strIdentification, '');
        const storeKeys = storeKey.split('/');
        let settingsSel = settings;

        storeKeys.map(e => (settingsSel = settingsSel[e]));
        fieldValidationValues[x] = convertStringToInt(settingsSel);
      }
    });

    return <FormattedMessage id={formattedMsg} values={fieldValidationValues} />;
  };
  const placeholder =
    (placeholderIntl && intlMessage(placeholderIntl)) || placeholderText;
  const label = (labelIntl && intlMessage(labelIntl)) || labelText;
  const helper = () =>
    // If error exist, overwrite the helper with the error message
    error
      ? (errorIntl && intlFormattedMessage(errorIntl)) || errorText
      : (helperIntl && intlMessage(helperIntl)) || helperText || (withHelperSpace && ' ');
  const tooltip =
    withTooltip && ((tooltipIntl && intlMessage(tooltipIntl)) || tooltipText);

  // Mask
  const customMask = () => ({
    inputComponent: (() =>
      (hasLeadingZero && MaskLeadingZero) ||
      (hasNumberPositiveInteger && MaskNumberPositiveInteger) ||
      (hasCurrency && MaskCurrency) ||
      (hasPercentage && MaskPercentage))(),
  });

  // Disable onPaste function
  const handlePaste = e => {
    e.preventDefault();
  };

  // Loader
  const InputLoader = ({enabled}) =>
    enabled ? (
      <InputAdornment className={myClasses.inputLoader}>
        <Loader />
      </InputAdornment>
    ) : (
      <></>
    );

  // Error Highlight
  let errorHighlightTimeout;
  const errorHighlightDuration = 2000;

  const errorHighlightCallback = hasError => {
    if (!hasError) return;

    setErrorHighlight(hasError);

    clearTimeout(errorHighlightTimeout);

    errorHighlightTimeout = setTimeout(() => {
      setErrorHighlight(!hasError);
    }, errorHighlightDuration);
  };

  let inputRef;

  return (
    <div className={myClasses.inputWrapper}>
      <TextField
        inputRef={input => {
          inputRef = input;
        }}
        ref={refs}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        className={textFieldClasses}
        value={hideValueOnLoading ? '' : value}
        name={name}
        placeholder={placeholder}
        label={label}
        helperText={helper()}
        error={errorHighlight || error}
        InputLabelProps={CustomInputLabelProps()}
        InputProps={{
          startAdornment: <InputLoader enabled={isLoading} />,
          endAdornment:
            withTooltip && !isLoading ? (
              <FieldTooltip tooltipPlacement={tooltipPlacement} text={tooltip} />
            ) : (
              ''
            ),
          inputProps: {
            ...{settings},
            ...(uppercaseText && {className: myClasses.uppercase}),
            ...(fieldCurrencyMaskSettings && {fieldCurrencyMaskSettings}),
            ...(maskRange && {
              maskErrorHighlightForRange,
              maskRange,
              maskRangeId,
              errorHighlightCallback,
            }),
          },
          ...customMask(),
          ...InputProps,
        }}
        fullWidth={fullWidth}
        disabled={disabled || isLoading}
        size={size}
        multiline={multiline}
        rows={multilineRows}
        onPaste={pasteDisabled ? handlePaste : null}
        onClick={() => {
          if (withAutoSelect) {
            inputRef.focus();
            inputRef.select();
          }
        }}
      />
    </div>
  );
});
