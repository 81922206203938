import merge from 'deepmerge';
import Montserrat from 'fonts/Montserrat';
import createTheme from '../createTheme';
import colors from './colors';
// Fonts

const borderRadius = {
  small: '0.5rem',
  big: '2rem',
};

const spacing = {
  formField: 10,
};

const options = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': Montserrat,
        a: {
          textDecoration: 'none',
          color: colors.blueLight,
          fontWeight: 600,
          '&:hover': {
            textDecoration: 'underline',
            color: colors.blueDark,
          },
        },
      },
    },
    MuiTypography: {
      root: {
        color: colors.blueHot,
      },
      body1: {
        fontSize: '0.9rem',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: borderRadius.big,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 600,
      },
    },
    MuiButton: {
      label: {
        fontWeight: 600,
      },
      containedPrimary: {
        color: colors.white,

        '&:hover': {
          backgroundColor: colors.blue,
        },
      },
      outlinedPrimary: {
        color: colors.blue,
        borderColor: 'transparent',

        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: colors.blue,
        },
      },
    },
    MuiStepLabel: {
      root: {
        fontWeight: 600,
      },
    },
    MuiStepIcon: {
      text: {
        fill: colors.white,
      },
    },
    MuiAlertTitle: {
      root: {
        color: colors.white,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.red,
      },
    },
  },
  typography: {
    fontFamily: ['Montserrat, sans-serif'],
  },
  borderRadius,
  spacing: 12,
  palette: {
    tonalOffset: 0.1,
    primary: {
      main: colors.green,
    },
    secondary: {
      main: colors.blueLight,
    },
    themeColors: colors,
  },
  props: {
    MuiButton: {
      variant: 'outlined',
      disableElevation: true,
      style: {
        borderRadius: borderRadius.big,
      },
    },
    MuiTextField: {
      variant: 'outlined',
      margin: 'dense',
    },
  },
};

const currentTheme = createTheme(options);

const theme = merge(currentTheme, {
  // Style overwrites for extendedLayout core condition
  extendedLayoutContainer: {
    maxWidth: 1080,
    '& .form-field': {
      maxWidth: 360,
    },
  },
  formField: {
    wrapper: {
      maxWidth: '20rem',
    },
    field: {
      margin: `${spacing.formField}px 0`,
    },
    beneficiaryTableMinWidth: 160,
  },
  // Overwrite currentTheme properties e.g.
  // breakpoints: currentTheme.breakpoints,
});

export default theme;
