export default {
  steps: [{id: 'client-info'}, {id: 'invoice-info'}, {id: 'payment'}],
  modalBigClientVariant: 'max-vehicles',
  vehicleListTableData: {
    columnsLookups: ['vehicle-type', 'medium-type', 'vehicle-purpose'],
    columns: [
      {
        id: 'plate_number',
        name: 'plate-number',
        validationPreset: 'unique',
        validationOptions: {
          translationPrefix: 'plateNumberIsUsed',
          validationPresetInherited: 'plateNumber',
        },
        validationStylesOptions: {
          uppercase: true,
        },
      },
      {
        id: 'vehicle_type',
        name: 'vehicle-type',
        validationPreset: 'justRequired',
      },
      {
        id: 'medium_type',
        name: 'medium-type',
        validationPreset: 'justRequired',
      },
      {
        id: 'vehicle_purpose',
        name: 'vehicle-purpose',
        validationPreset: 'justRequired',
      },
    ],
  },
};
