import {checkNumberInRange} from 'utils';

export default (
  floatValue,
  maskRange,
  maskRangeId,
  validationRanges,
  errorHighlightCallback,
  maskErrorHighlightForRange,
) => {
  if (maskRange) {
    const currentMaskRange = maskRangeId ? maskRange[maskRangeId] : maskRange;
    const {min, max} = currentMaskRange;

    const isInRange = checkNumberInRange(
      floatValue,
      validationRanges[min],
      validationRanges[max],
    );

    errorHighlightCallback(!isInRange);

    if (!maskErrorHighlightForRange) {
      return isInRange;
    }
  }

  return floatValue !== 0;
};
