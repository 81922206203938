const logo = require('assets/generic/logo.png');

const images = {
  logo: {
    default: logo,
  },
};

export default {
  theme: 'green',
  scenario: 'TicketRestaurant',
  visitorFlowName: 'Visitor',
  authFlowName: 'Auth',
  featureToggle: {},
  options: {
    Header: {
      Logo: {
        width: 120,
        alt: 'Edenred logo',
        image: images.logo.default,
      },
    },
    HelpCustomer: {},
  },
  currencyFormat: '{value} {symbol}',
  tenantCurrencyMaskSettings: {
    allowNegative: false,
    thousandSeparator: true,
    isNumericString: true,
    fixedDecimalScale: true,
    decimalScale: 2,
  },
};
