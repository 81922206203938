import {routerSceneLazyLoad} from 'helpers';

const scenarioName = 'TurkeyProducts';

const ContactInfo = routerSceneLazyLoad('ContactInfo'); // import a shared scene between multiple scenarios
const MediumTypes = routerSceneLazyLoad('MediumTypes'); // import a shared scene between multiple scenarios
const PriceSimulatorFuel = routerSceneLazyLoad('PriceSimulatorFuel', scenarioName); // import a specific scene for this current current scenario
const PriceSimulatorBenefit = routerSceneLazyLoad('PriceSimulatorBenefit', scenarioName); // import a specific scene for this current current scenario
const CompanyInfo = routerSceneLazyLoad('CompanyInfo');
const VehicleList = routerSceneLazyLoad('VehicleList', scenarioName);
const EmployeeList = routerSceneLazyLoad('EmployeeList', scenarioName);
const OrderSummaryFuel = routerSceneLazyLoad('OrderSummaryFuel', scenarioName);
const OrderSummaryBenefit = routerSceneLazyLoad('OrderSummaryBenefit', scenarioName);
const OrderPayment = routerSceneLazyLoad('OrderPayment');
const OrderStatus = routerSceneLazyLoad('OrderStatus');

export default {
  ContactInfo,
  MediumTypes,
  PriceSimulatorFuel,
  PriceSimulatorBenefit,
  CompanyInfo,
  VehicleList,
  EmployeeList,
  OrderSummaryFuel,
  OrderSummaryBenefit,
  OrderPayment,
  OrderStatus,
};
