export default {
  steps: [{id: 'your-information'}, {id: 'your-order'}, {id: 'order-confirmation'}],
  modalBigClientVariant: 'exceeding-max-value',
  employeeListTableData: {
    columns: {
      common: [
        {
          id: 'first_name',
          name: 'employee-first-name',
          validationPreset: 'personName',
        },
        {
          id: 'last_name',
          name: 'employee-last-name',
          validationPreset: 'personName',
        },
        {
          id: 'employee_functional_ref',
          name: 'employee-id',
          removed: true,
          validationPreset: 'unique',
          validationOptions: {
            translationPrefix: 'employeeIdIsUsed',
            validationPresetInherited: 'employeeId',
          },
          withoutMinWidth: true,
        },
      ],
      collection: [
        {
          id: 'phone',
          name: 'phone',
          validationPreset: 'phoneNumberMobile',
          validationOptions: {
            isRequired: true,
          },
          withTooltip: true,
        },
        {
          id: 'days',
          name: 'days',
          validationPreset: 'range',
          validationOptions: {
            numberRange: true,
            min: 'rangeNumberWorkingDaysMin',
            max: 'rangeNumberWorkingDaysMax',
            isRequired: true,
          },
          mask: {
            hasNumberPositiveInteger: true,
          },
          withoutMinWidth: true,
        },
        {
          id: 'amount',
          name: 'daily-value',
          validationPreset: 'range',
          validationOptions: {
            priceRange: true,
            min: 'rangeAllowanceDaysMin',
            max: 'rangeAllowanceDaysMax',
            isRequired: true,
          },
          mask: {
            hasCurrency: true,
          },
        },
        {
          id: 'amount',
          name: 'monthly-value',
          validationPreset: 'range',
          validationOptions: {
            priceRange: true,
            min: 'rangeAllowanceMonthsMin',
            max: 'rangeAllowanceMonthsMax',
            isRequired: true,
          },
          mask: {
            hasCurrency: true,
          },
          withoutMinWidth: true,
        },
        {
          id: 'total',
          name: 'total',
          disabled: true,
          mask: {
            hasCurrency: true,
          },
          withoutMinWidth: true,
        },
      ],
    },
  },
};
