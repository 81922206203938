import React, {useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
// Mui components
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {withStyles} from '@material-ui/core/styles';
// React Intl
import {FormattedMessage} from 'react-intl';
// Components - global
import WrapperBlank from 'components/WrapperBlank';
import Popup from 'components/Popup';
import Loader from 'components/Loader';
import FormValidation from 'ComponentsForm/Validation';
import FormFields from 'ComponentsForm/Validation/components/FormFields';
import FormCheckbox from 'ComponentsForm/Validation/components/FormCheckbox';
import ButtonAnchorWithUrl from 'components/ButtonAnchorWithUrl';
// Form data from core
import {commonStateToProps} from 'ScenarioComponents/scenes/shared/connectedCommonProps';
// Redux
import {uiActions, contactUsActions} from 'store/actions';
// Root selectors
import rootSelectors from 'store/selectors';

// Styles
const useStyles = theme => ({
  buttons: {
    padding: theme.spacing(2, 0, 1),
  },
  fieldWrapper: {
    ...theme.formField.wrapper,
  },
  checkboxWrapper: {
    '& > div': {
      '&': {
        margin: 0,
      },
      '& > label': {
        marginRight: 0,
      },
    },
  },
  status: {
    color: theme.palette.green,
  },
  checkboxMargin: {
    marginTop: 0,
  },
  checkboxError: {
    marginLeft: '5rem',
  },
  centeredText: {
    textAlign: 'center',
  },
});

const ContactUsModal = props => {
  const {
    status,
    formData,
    blankFormData,
    classes,
    handleStatus,
    validationPresets,
    tenant,
    errorShow,
    contactUsMessage,
    acceptedGdpr,
    setContactUsPrefillData,
    urlPdfGdpr,
  } = props;

  const [contactData, setContactData] = useState(formData);
  const [validationData, setValidationData] = useState(formData);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [gdprChoice, setGdprChoice] = useState(false);
  const [popupStatus, setPopupStatus] = useState(false);

  const createValidation = useCallback(newFormData => {
    const newValidationData = newFormData;

    const gdprValidations = newValidationData.rootCollection.find(f => f.name === 'gdpr');

    gdprValidations.value = gdprChoice || 'false';
    setValidationData(newValidationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newFormData = {
      rootCollection: acceptedGdpr
        ? setContactUsPrefillData(formData).rootCollection
        : formData.rootCollection.map(field => {
            // Reset form
            return {...field, value: ''};
          }),
    };

    createValidation(newFormData);
    setContactData(newFormData);
  }, [acceptedGdpr, setContactUsPrefillData, formData, status, createValidation]);

  const title = <FormattedMessage id='global.contact-us_popup_title' />;
  const submitBtnText = <FormattedMessage id='global.contact-us_submit_text' />;
  const successTitle = <FormattedMessage id='global.contact-us_message-sent-title' />;
  const successMessage = <FormattedMessage id='global.contact-us_message-sent' />;
  const successBtnText = <FormattedMessage id='global.contact-us_success_text' />;

  const handleSubmit = () => {
    if (gdprChoice) {
      setLoading(true);
      contactUsMessage(contactData)
        .then(() => {
          setSuccess(true);

          setContactData(blankFormData);
          setGdprChoice(false);
          setPopupStatus(true);

          // Clean status after 5s
          setTimeout(() => {
            setSuccess(false);
          }, 5000);
        })
        .catch(err => errorShow(err))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChange = event => {
    const {target} = event;
    const {value, name, checked} = target;
    const splitted = name.split('.');
    const group = splitted.shift();
    const inputName = splitted.pop();
    const updatedFormData = formData[group];

    if (inputName === 'gdpr') {
      setGdprChoice(checked);
    }

    // Manipulate form data and update state
    updatedFormData.forEach((field, key) =>
      field.name === inputName
        ? (updatedFormData[key].value = inputName === 'gdpr' ? checked : value)
        : null,
    );

    setContactData({[group]: updatedFormData});
  };

  const closePopups = () => {
    setPopupStatus(!popupStatus);
    handleStatus();
  };

  return (
    <>
      <Popup
        status={popupStatus}
        title={successTitle}
        handleClose={closePopups}
        maxWidth='xs'
        centeredText
        withCloseButton={false}
      >
        <div className={classes.centeredText}>
          <Box mb={1}>{successMessage}</Box>
          <div className={classes.buttons}>
            <Button variant='contained' color='primary' onClick={closePopups}>
              {successBtnText}
            </Button>
          </div>
        </div>
      </Popup>

      <Popup
        status={status}
        title={title}
        handleClose={handleStatus}
        centeredText
        maxWidth='sm'
      >
        {validationPresets && (
          <FormValidation
            presets={validationPresets}
            tenant={tenant}
            formData={validationData}
            submit={handleSubmit}
            change={handleChange}
            classesField={classes}
            WrapperField={WrapperBlank}
            translationIdentifierPrefix='global.contact-us'
            centeredForm
            resetFormAfterSubmit={gdprChoice}
          >
            <FormFields
              formData={{rootCollection: contactData.rootCollection}}
              translationPrefix='global.contact-us'
            />

            <div className={[classes.fieldWrapper, classes.checkboxWrapper].join(' ')}>
              <FormCheckbox
                checked={gdprChoice}
                formData={contactData}
                name='rootCollection.gdpr'
                label={
                  <FormattedMessage
                    id='global.contact-us_checkbox_gdpr'
                    values={{
                      anchor: anchorText => (
                        <ButtonAnchorWithUrl url={urlPdfGdpr} target='_blank'>
                          {anchorText}
                        </ButtonAnchorWithUrl>
                      ),
                    }}
                  />
                }
              />
            </div>

            <div className={classes.buttons}>
              {loading ? (
                <Loader />
              ) : (
                <Button variant='contained' color='primary' type='submit'>
                  {submitBtnText}
                </Button>
              )}
            </div>
            {success && <p className={classes.status}>{successMessage}</p>}
          </FormValidation>
        )}
      </Popup>
    </>
  );
};

const mapStateToProps = state => {
  // Selectors
  const {selectUrlGdpr} = rootSelectors;

  return {
    urlPdfGdpr: selectUrlGdpr(state),
    validationPresets: commonStateToProps(state).validationsRegex,
    tenant: state.root.settings.tenant,
    acceptedGdpr: state.consents.acceptedGdpr,
  };
};

const mapDispatchToProps = dispatch => {
  const {errorShow} = uiActions;
  const {contactUsMessage, setContactUsPrefillData} = contactUsActions;

  return {
    errorShow: () => dispatch(errorShow()),
    contactUsMessage: payload => dispatch(contactUsMessage(payload)),
    setContactUsPrefillData: contactData =>
      dispatch(setContactUsPrefillData(contactData)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(useStyles),
)(ContactUsModal);
