import React from 'react';
import config from 'config';
import {regexCollection} from 'utils';
import rangeSettings from 'validations/rangeSettings';
import NumberFormat from 'react-number-format';
import maskRangeIsAllowed from '../maskRangeIsAllowed';

export default props => {
  const {
    inputRef,
    settings,
    fieldCurrencyMaskSettings,
    maskErrorHighlightForRange = false,
    maskRange,
    maskRangeId,
    errorHighlightCallback,
    ...other
  } = props;
  const {tenant, currency} = settings;
  const {currencyFormat, tenantCurrencyMaskSettings} = config[tenant];

  const validationRanges = rangeSettings(settings);

  // Don't need to spread unuseful NumberFormat component props
  delete other.dispatch;
  delete other.fieldCurrencyMaskSettings;
  delete other.maskRangeId;
  delete other.errorHighlightCallback;

  // If decimalSeparator isn't present, remove decimals
  const decimalSeparator =
    tenantCurrencyMaskSettings.decimalSeparator ||
    fieldCurrencyMaskSettings.decimalSeparator;

  // Currency format (position: suffix or prefix)
  const currencyPrefix =
    currencyFormat.match(regexCollection.curlyBracesString)[0] === 'symbol';
  const currencyString = currencyFormat
    .replace('{value}', '')
    .replace('{symbol}', currency);
  const currencyPosition = currencyPrefix
    ? {prefix: currencyString}
    : {suffix: currencyString};

  return (
    <>
      <NumberFormat
        {...other}
        {...tenantCurrencyMaskSettings}
        {...currencyPosition}
        {...fieldCurrencyMaskSettings}
        decimalSeparator={decimalSeparator}
        displayType='number'
        getInputRef={inputRef}
        isAllowed={({floatValue}) =>
          maskRangeIsAllowed(
            floatValue,
            maskRange,
            maskRangeId,
            validationRanges,
            errorHighlightCallback,
            maskErrorHighlightForRange,
          )
        }
      />
    </>
  );
};
