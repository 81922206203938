import merge from 'deepmerge';
// Create custom Mui themes from base theme
import {createTheme as createMuiTheme} from '@material-ui/core';

const defaultOptions = {
  opacity: {
    small: '.9',
    medium: '.75',
    big: '.5',
  },
  zIndex: {
    helpButton: 2000,
    progressBar: 2400,
    loaderOverlay: 2500,
    snackbar: 2600,
  },
  typography: {
    h1: {
      fontSize: '2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.17rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '.83rem',
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '.75rem',
      fontWeight: 'bold',
    },
  },
  palette: {
    tonalOffset: 0.1,
    white: '#fff',
    green: '#4caf50',
    red: '#dd2c00',
  },
  borderRadius: '1.25rem',
};

const createTheme = options => {
  const theme = createMuiTheme(merge(defaultOptions, options));

  return {
    ...theme,
    elevatedElement: {
      '&': {
        boxShadow: theme.shadows[5],
        transition: `margin-right linear ${theme.transitions.duration.shorter}ms, box-shadow linear ${theme.transitions.duration.shorter}ms`,
      },
      '&:hover, &:active': {
        boxShadow: theme.shadows[7],
      },
    },
    formField: {
      wrapper: {
        width: '100%',
      },
    },
  };
};
export default createTheme;
