import React from 'react';
// Mui components
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconDone from '@material-ui/icons/Done';
// React Intl
import {FormattedMessage, injectIntl} from 'react-intl';

// Styles
const useStyles = makeStyles(theme => {
  const colorGreen = theme.palette.green;

  return {
    button: {
      color: colorGreen,
      borderColor: colorGreen,
    },
  };
});

export default injectIntl(({intl, translationIdentifierPrefix}) => {
  const intlMessage = msg =>
    intl.formatMessage({
      id: `${translationIdentifierPrefix}_${msg}`,
    });

  return (
    <Button
      variant='outlined'
      className={useStyles().button}
      endIcon={<IconDone />}
      onClick={() => (window.location.href = intlMessage('cross-sell_button-url_order'))}
    >
      <FormattedMessage id={`${translationIdentifierPrefix}_cross-sell_button_order`} />
    </Button>
  );
});
