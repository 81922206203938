import React from 'react';

export default ({width, image, alt, additionalClasses}) => (
  <div
    style={{
      display: 'flex',
      width,
    }}
    className={additionalClasses}
  >
    <img
      src={image}
      alt={alt}
      style={{width: '100%', height: 'auto', objectFit: 'contain'}}
    />
  </div>
);
