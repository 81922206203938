import merge from 'deepmerge';
import createTheme from '../createTheme';

const colors = {
  white: '#fff',
  green: '#C4D600',
  blue: '#00859b',
  blueHot: '#162056',
  blueLight: '#0085ca',
  blueDark: '#00537e',
  red: '#ed1c24',
};

const spacing = {
  formField: 10,
};

const options = {
  palette: {
    themeColors: colors,
  },
};

const currentTheme = createTheme(options);

const theme = merge(currentTheme, {
  formField: {
    wrapper: {
      maxWidth: '20rem',
    },
    field: {
      margin: `${spacing.formField}px 0`,
    },
  },
  // Overwrite currentTheme properties e.g.
  // breakpoints: currentTheme.breakpoints,
});

export default theme;
